import {Link} from "react-router-dom";
import React from "react";

const RestaurantHeader = ({showAddRestaurant, links}) => {
    return (
        <div className="employee-section">
           <div>
               <Link to={showAddRestaurant ? "/admin/restaurant-list" : "/admin/restaurant"}>
                   <span>Restaurants</span>
               </Link>
               {links?.map(item => (
                   <Link to={item.link}>
                       >  <span>{item.text}</span>
                   </Link>
               ))}
           </div>
            {showAddRestaurant ?
                <Link to="/admin/restaurant/new-restaurant">
                    <button className="add-employee-btn">Add Restaurant</button>
                </Link>
                :
                null
            }
        </div>
    )
}

export default RestaurantHeader;