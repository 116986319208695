import {Modal} from "react-bootstrap";
import {useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {errorMsg, successMsg} from "../../../util/toast";

import DefaultModal from "../../../hoc/DefaultModal/DefaultModal"
import Delete from "../../../assets/delete.svg";
import DeleteImgModal from "../../../assets/delete-img.png";
import Form from 'react-bootstrap/Form';
import Pencil from "../../../assets/pencil.svg";
import TableFooter from "../../../hoc/TableFooter";
import WhiteTick from "../../../assets/white-tick.svg";
import callApi from "../../../util/apiCaller";
import options from "../../../content/ScreenPosition"

const StarterTable = ({
                          pageSize,
                          pageNum,
                          dish_count,
                          pages,
                          handlePageSizeChange,
                          handlePageNumChange,
                          dishlist,
                          setDish,
                          category_menu_id,
                          searchForCategoryDishes,
                          menuCategory,
                          groupMenu
                      }) => {

    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [menuCategoryID, setMenuCategoryID] = useState("");
    const [deleteDishName, setDeleteDishName] = useState("");
    const [dishEditID, setDishEditID] = useState("");
    const [selectedDish, setSelectedDish] = useState("");
    const [categoryMenu, setCategoryMenu] = useState([]);
    const [selectedTags, setSelectedTags] = useState([""]);
    const [successModalForDelete, setSuccessModalForDelete] = useState(false);
    const [screenPosition, setScreenPosition] = useState([])
    const [menuListPosition, setMenuListPosition] = useState([])

    useEffect(() => {
        setCategoryMenu(dishlist)
    }, [dishlist]);

    const fetchContentByCode = (code, next) => {
        callApi(`content/code/${code}`, 'get').then(res => {
            if (res.status === "Success") {
                return next(null, res.data.content);
            }
        }).catch(err => {
            console.log(err)
            return next(err);
        })
    }


    useEffect(() => {
        fetchContentByCode('menu-screen-position', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                setScreenPosition(content?.params)
            }
        });

        fetchContentByCode('menu-list-position', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                setMenuListPosition(content?.params)
            }
        });
    }, [])

    let optionsList = options.length > 0
        && options.map((item, i) => {
            return (
                <option key={i} value={item.value}>{item.label}</option>
            )
        });
    const editDishMenu = (dish) => {
        setIsShow(true);
        if (dish && dish.dish_id && dish.dish_id._id) {
            setSelectedDish(dish);
            setSelectedTags(dish?.tags ? dish.tags : []);
            setDishEditID(dish.dish_id._id);
        }
    }

    const getDishIdForDelete = (id, dish_name) => {
        setDeleteDishName(dish_name);
        setMenuCategoryID(id);
        openModal()
    }

    const handleStatus = (id) => {
        callApi(`dish/${id}/change_status`, 'get').then(res => {
            if (res.status === "Success") {
                let newDishList = [...dishlist];
                newDishList = newDishList.map(item => {
                    if (item?.dish_id?._id === id) {
                        item.dish_id.is_active = !item?.dish_id?.is_active;
                    }
                    return item;
                });
                setDish(newDishList);
                successMsg("Status Updated Successfully...!");
            } else {
                errorMsg("something went wrong");
            }
        })
    }
    const handleDeleteCategoryMenuById = (id) => {
        console.log("dist id delete", id, category_menu_id)
        callApi(`selected_dish/menu_category/${category_menu_id}/dish/${id}/remove-dish`, 'post', {
            // selected_dish: {
            //     dish_name: deleteDishName
            // }
        }).then(res => {
            if (res.status === "Success") {
                console.log("menu deleted", res);
                closeModal();
                openSuccessModalForDelete();
            } else {
                alert("something went wrong")
            }
        });
    }

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);
    const openSuccessModalForDelete = () => setSuccessModalForDelete(true);

    const closeSuccessModalForDelete = () => {
        setSuccessModalForDelete(false)
        window.location.reload();
    };

    const handleRedirectAndEditDish = () => {
        navigate(`/admin/dishes?showEdit=${dishEditID}`);
    }

    const handleSelectedDishChange = (keyName, keyValue) => {
        let newSelectedDish = {...selectedDish};
        newSelectedDish[keyName] = keyValue;
        setSelectedDish(newSelectedDish);
    }

    const handleChangeDishPosition = (dish_id, keyName, keyValue) => {
        let update = {};
        update[keyName] = keyValue;
        // screen_position: update.screen_position ? parseInt(update.screen_position) : null,
        // tile_position: update.tile_position ? parseInt(update.tile_position) : null,
        callApi(`selected_dish/${dish_id}/update`, 'post', {
            selected_dish: {
                ...update
            }
        }).then(res => {
            if (res.status === "Success") {
                searchForCategoryDishes("")
                successMsg("Update successful!")
            } else {
                errorMsg("Something went wrong!")

            }
        }).catch(err => {
            errorMsg("Something went wrong!")
        })
    };

    const saveSelectedDish = () => {
        let newSelectedDish = {...selectedDish};
        newSelectedDish.tags = selectedTags;
        callApi(`selected_dish/${newSelectedDish._id}/update`, 'post', {
            selected_dish: {
                screen_position: newSelectedDish.screen_position ? parseInt(newSelectedDish.screen_position) : null,
                tile_position: newSelectedDish.tile_position ? parseInt(newSelectedDish.tile_position) : null,
                tags: newSelectedDish.tags ? newSelectedDish.tags: []
            }
        }).then(res => {
            if (res.status === "Success") {
                searchForCategoryDishes("")
                successMsg("Update successful!")
            } else {
                errorMsg("Something went wrong!")

            }
        }).catch(err => {
            errorMsg("Something went wrong!")
        })
    }

    const getCheckedValue = (item) => {
        return !!selectedTags.includes(item);
    };

    const handleTagChange = (item, val) => {
        let update = [...selectedTags];
        if (item && update.includes(item)) {
            update = update.filter(i => i !== item);
        } else {
            update.push(item);
        }
        setSelectedTags(update);
    };

    return (
        <>
            <div className="">
                <TableFooter
                    isPageVisible={false}
                    pages={pages}
                    pageSize={pageSize}
                    count={dish_count}
                    pageNum={pageNum}
                    handlePageNumChange={handlePageNumChange}
                    handlePageSizeChange={handlePageSizeChange}
                >
                </TableFooter>
                <div className="menu-body">
                    <table class="table">
                        <thead class="thead-light">
                        <tr>
                            <th scope="col">Dish</th>
                            <th scope="col">Screen Position</th>
                            <th scope="col">Tile Position</th>
                            <th scope="col">Edit</th>
                            <th scope="col">Action</th>
                            <th scope="col">Delete</th>
                        </tr>
                        </thead>
                        <tbody>
                        {categoryMenu && categoryMenu.map((item, index) => (
                            <tr>
                                <td>{item.dish_name}</td>
                                <td>
                                    <Form.Select
                                        value={item.screen_position ? item.screen_position : 'N/A'}
                                        onChange={(e) => handleChangeDishPosition(item._id, 'screen_position', e.target.value)}
                                        aria-label="Super Admin" className="select-event menu-select" width="100%"
                                        defaultValue="select screen position"
                                    >
                                        <option value="">{'N/A'}</option>
                                        {
                                            menuListPosition?.map(item => {
                                                return (
                                                    <option key={item.value} value={item.value}>{item.label}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </td>
                                <td>
                                    <Form.Select
                                        value={item.tile_position ? item.tile_position : 'N/A'}
                                        onChange={(e) => handleChangeDishPosition(item._id, 'tile_position', e.target.value)}
                                        aria-label="Super Admin" className="select-event menu-select" width="100%"
                                        defaultValue="select screen position"
                                    >
                                        <option value="">{'N/A'}</option>
                                        {
                                            screenPosition?.map(item => {
                                                return (
                                                    <option key={item.value} value={item.value}>{item.label}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </td>
                                <td><img src={Pencil} onClick={() => editDishMenu(item)} style={{cursor: "pointer"}}/>
                                </td>
                                <td data-tip="Inactive" data-class="upload-data-tip">
                                    <label class="switch">
                                        <input type="checkbox" checked={item?.dish_id?.is_active}
                                               onClick={() => handleStatus(item.dish_id?._id)}/>
                                        <span class="slider round"></span>
                                    </label>
                                </td>
                                <td><img src={Delete}
                                         onClick={() => getDishIdForDelete(item.dish_id?._id, item.dish_name)}
                                         style={{cursor: "pointer"}}/></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <TableFooter
                    isCounterVisible={false}
                    pages={pages}
                    pageSize={pageSize}
                    count={dish_count}
                    pageNum={pageNum}
                    handlePageNumChange={handlePageNumChange}
                    handlePageSizeChange={handlePageSizeChange}
                >
                </TableFooter>
            </div>

            {/* Delete Modal */}
            <div className="delete-modal">
                <Modal show={isOpen} onHide={closeModal}>
                    <Modal.Header closeButton className="border-none">

                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center delete-modal">
                            <img src={DeleteImgModal}/>
                            <h3>Are you sure you want to delete this item?</h3>

                        </div>
                        <div className="d-flex justify-content-center">
                            <div>
                                <button className="delete-modal-btn"
                                        onClick={() => handleDeleteCategoryMenuById(menuCategoryID)}>Delete
                                </button>
                            </div>
                            <div>
                                <button className="keep-modal-btn" onClick={closeModal}>Keep</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            {/* Edit Dish Modal */}
            <div className="edit-dish-modal">
                <DefaultModal
                    show={isShow}
                    onHide={() => setIsShow(false)}
                    centered={false}
                    size=""
                    showFooter={false}
                    className="info-modal"
                    title={`Information`}
                >
                    <div className="row" style={{marginTop: "30px"}}>
                        <div className="col-md-6">
                            <Form>
                                <Form.Label className="events-mt">Screen Position</Form.Label>
                                <Form.Select
                                    value={selectedDish?.screen_position}
                                    onChange={(e) => handleSelectedDishChange('screen_position', e.target.value)}
                                    aria-label="Super Admin" className="select-event menu-select" width="100%"
                                    defaultValue="select screen position"
                                >
                                    <option value="">Not Selected</option>
                                    {
                                        menuListPosition?.map(item => {
                                            return (
                                                <option key={item.value} value={item.value}>{item.label}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Form>
                        </div>
                        <div className="col-md-6">

                            <Form>
                                <Form.Label className="events-mt">Tile Position</Form.Label>
                                <Form.Select
                                    value={selectedDish?.tile_position}
                                    onChange={(e) => handleSelectedDishChange('tile_position', e.target.value)}
                                    aria-label="Super Admin" className="select-event menu-select" width="100%"
                                    defaultValue="select screen position"
                                >
                                    <option value="">Not Selected</option>
                                    {
                                        screenPosition?.map(item => {
                                            return (
                                                <option key={item.value} value={item.value}>{item.label}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Form>
                        </div>
                        <div className="col-md-6">
                            <Form>
                                <Form.Label className="events-mt">Tags (Select Whatever Applies)</Form.Label>
                                <Form.Label>{'Group Level Tags'}</Form.Label>
                                {groupMenu?.tags?.map(item => (
                                    <Form.Check
                                        type={"checkbox"}
                                        id={""}
                                        label={item}
                                        onClick={(e) => handleTagChange(item, e.target.value)}
                                        checked={getCheckedValue(item)}
                                    />
                                ))}
                                {'Menu Level Tags'}
                                {menuCategory?.tags?.map(item => (
                                    <Form.Check
                                        type={"checkbox"}
                                        id={""}
                                        label={item}
                                        onClick={(e) => handleTagChange(item, e.target.value)}
                                        checked={getCheckedValue(item)}
                                    />
                                ))}
                            </Form>
                        </div>
                        <div className="dish-details">
                            <h6 onClick={handleRedirectAndEditDish}>Click here to see complete dish details</h6>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <button className='information-btn-cancel' onClick={() => setIsShow(false)}>Cancel</button>
                            <button className='information-btn-save' onClick={() => saveSelectedDish(false)}>Save
                            </button>
                        </div>
                    </div>


                </DefaultModal>
                {/* Success Modal for delete */}
                <div className="">
                    <Modal show={successModalForDelete} onHide={closeSuccessModalForDelete} className="success-modal">
                        <Modal.Header closeButton className="border-none">

                        </Modal.Header>
                        <Modal.Body>
                            <div className="text-center">
                                <img src={WhiteTick}/>
                                <p>Dish deleted successfully </p>
                            </div>
                            <div className="text-center">
                                <button onClick={() => closeSuccessModalForDelete()} className="success-modal-btn">Go to
                                    Menu
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </>
    )
}
export default StarterTable;
