import "./changePassword.css"

import {USER_ID} from "../../constants/enums/appEnums";
import React from "react";
import {errorMsg, successMsg} from "../../util/toast";

import Form from 'react-bootstrap/Form';
import callApi from "../../util/apiCaller";
import {toast} from 'react-toastify';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

const USER_INITIAL_STATE = {
    local_password: "",
    repassword: "",
}
export default class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {...USER_INITIAL_STATE},
            errors: {}
        }
    }

    handleChange = (keyName, keyValue) => {
        let user = this.state.user;
        user[keyName] = keyValue;
        this.setState({
            user: user
        });
    }

    handleValidation = (user) => {
        let isValid = true;
        let newErrors = {};
        if (!user.local_password) {
            isValid = false;
            newErrors['local_password'] = 'Please enter new password';
        }
        if (!user.repassword) {
            isValid = false;
            newErrors['repassword'] = 'Please re-enter password';
        }
        if (!(user.repassword === user.local_password)) {
            isValid = false;
            newErrors['repassword'] = 'Password does not match';
        }
        return {
            isValid,
            newErrors,
        }
    };
    notify = (data) => {
        toast(data)
    }

    handleSaveClick = () => {
        let check = this.handleValidation(this.state.user);
        let id = localStorage.getItem(USER_ID);
        if (check.isValid) {
            callApi(`auth/agent/${id}/change_password`, "post", {
                "newPassword": this.state.user.local_password,
            }).then(res => {
                if (res.status === 'Success') {
                    successMsg(res.data.message);
                    setTimeout(() => {
                        window.location = "/login";
                    }, 1000);
                } else {
                    errorMsg(res.data.message);
                }
            })
        } else {
            this.setState({
                errors: check.newErrors,
            })
        }
    };

    render() {
        const {errors} = this.state;
        return (
            <>
                <div className="employee-part add-events-section">
                    <Helmet>
                        <title>Change Password | Windmills</title>
                    </Helmet>
                    <div className="employee-section">
                        <p>Change Password</p>
                    </div>
                    <div className="add-event-form">
                        <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                value={this.state.user.local_password}
                                onChange={(e) => this.handleChange("local_password", e.target.value)}
                                type="text"
                                placeholder="Enter New Password"/>
                        </Form.Group>
                        {errors && errors.local_password ? <label className="error_msg" style={{
                            fontSize: "12px",
                            color: "#ff0000bd"
                        }}>{errors.local_password}</label> : null}
                        <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                value={this.state.user.repassword}
                                onChange={(e) => this.handleChange("repassword", e.target.value)}
                                type="text"
                                placeholder="Re-Enter New Password"/>
                        </Form.Group>
                        {errors && errors.repassword ? <label className="error_msg" style={{
                            fontSize: "12px",
                            color: "#ff0000bd"
                        }}>{errors.repassword}</label> : null}
                        <div className="event-buttons">
                            <div className="cancel-btn">
                                <Link to={'/admin/menu'}>
                                    <button>Cancel</button>
                                </Link>
                            </div>
                            <div className="save-btn">
                                <button onClick={() => this.handleSaveClick()}>Update Password</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}