import "./menu.css";
import Preview from "./Components/Previews";
import { AGENT, USER_ID } from "../../constants/enums/appEnums";
import { Button, Modal, ModalBody } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import React, { useEffect, useState } from "react";
import { errorMsg, successMsg } from "../../util/toast";
import {
  getAgent,
  getSelectedRestaurant,
} from "../../redux/reducers/AppReducer";
import { FilePicker } from "react-file-picker";
import AddMenu from "./Components/AddMenu";
import Breadcrumbs from "../../hoc/Breadcrumbs";
import DefaultModal from "../../hoc/DefaultModal/DefaultModal";
import Delete from "../../assets/delete.svg";
import Papa from "papaparse";
import DeleteImgModal from "../../assets/delete-img.png";
import Download from "../../assets/download-icon.svg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import MenuLogo from "../../assets/menu-logo.png";
import Pencil from "../../assets/pencil.svg";
import ReactTooltip from "react-tooltip";
import TableFooter from "../../hoc/TableFooter";
import Upload from "../../assets/upload-icon.svg";
import WhiteTick from "../../assets/white-tick.svg";
import callApi from "../../util/apiCaller";
import { useSelector } from "react-redux";
import Dropdown from "../../common/Dropdown/dropdown";
import AddCollection from "./Components/addCollectionComponent";
import { fetchContentByCode } from "../../util/Utility";
import EditCollection from "./Components/editCollection";
import { toast } from "react-toastify";
import _ from "lodash";

const Menu = () => {
  const restaurant = useSelector((state) => getSelectedRestaurant(state));
  const agent = useSelector((state) => getAgent(state));
  const [menuList, setMenuList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
  const [menuInformationModal, setMenuInformationModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [successModalForDelete, setSuccessModalForDelete] = useState(false);
  const [startersInformationModal, setStartersInformationModal] =
    useState(false);
  const [menuId, setMenuId] = useState("");
  const [total_menu_count, setmenuCount] = useState(0);
  const [addCollectionModal, setAddCollectionModal] = useState(false);
  const [editCollectionModal, setEditCollectionModal] = useState(false);
  const [menuCollection, setMenuCollection] = useState(null);
  const [collectionOptions, setCollectionOptions] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [editCollection, setEditCollection] = useState(null);
  const [filterData, setFilterData] = useState({
    qtext: "",
    pageNum: 1,
    pageSize: 50,
  });
  const [menuTilePosition, setMenuTilePosition] = useState(null);
  const [availabilityRules, setAvailabilityRules] = useState(null);
  const [preview, setPreview] = useState(false);
  useEffect(() => {
    fetchContentByCode("menu-screen-position", (err, content) => {
      if (err) {
        console.log(err);
      } else {
        setCollectionScreenPosition(content?.params);
      }
    });
    fetchContentByCode("menu-list-position", (err, content) => {
      if (err) {
        console.log(err);
      } else {
        setMenuTilePosition(content?.params);
      }
    });
    fetchContentByCode("availability-rule", (err, content) => {
      if (err) {
        console.log(err);
      } else {
        setAvailabilityRules(content?.params);
      }
    });
  }, []);

  const [collectionScreenPosition, setCollectionScreenPosition] =
    useState(null);

  useEffect(() => {
    setFilterData({
      qtext: "",
      pageNum: 1,
      pageSize: 10,
    });
    fetchMenuList(restaurant._id);
    fetchMenuCollection();
  }, [restaurant]);

  useEffect(() => {
    console.log("selected Collection", selectedCollection);
    if (selectedCollection) {
      if (selectedCollection?.value === "Default") {
        fetchMenuList(restaurant._id, filterData);
        return;
      }
      let inputFilters = {};
      inputFilters.group_menus = selectedCollection.group_menus;
      inputFilters.is_default = false;
      fetchMenuList(restaurant._id, inputFilters);
    }
  }, [selectedCollection]);

  useEffect(() => {
    if (menuCollection?.length > 0) {
      let defaultOption = { label: "Default", value: "Default" };
      let options = menuCollection.map((item) => {
        return {
          label: item.name,
          value: item._id,
        };
      });
      options.unshift(defaultOption);
      setCollectionOptions(options);
      setSelectedCollection(defaultOption);
    }
  }, [menuCollection]);

  const fetchMenuList = (restaurant_id, inputFilters) => {
    console.log("input filters", inputFilters);
    let filters = {};
    filters.restaurant_id = restaurant_id;
    if (inputFilters && inputFilters.qtext) {
      filters.qtext = inputFilters.qtext;
    }
    if (inputFilters && inputFilters.group_menus) {
      filters.group_menus = inputFilters.group_menus;
    }
    if (
      inputFilters &&
      (inputFilters.is_default === true || inputFilters.is_default === false)
    ) {
      filters.is_default = inputFilters.is_default;
    }
    callApi(`group_menu/list`, "post", {
      filters,
      pageNum: inputFilters?.pageNum,
      pageSize: inputFilters?.pageSize,
    }).then((res) => {
      if (res.status === "Success") {
        setMenuList(res?.data?.list);
        setmenuCount(res?.data?.count);
      } else {
        console.log("err", res?.data?.message);
      }
    });
  };

  const fetchMenuCollection = () => {
    let filters = {};
    filters.restaurant_id = restaurant._id;
    callApi(`menu_collection/list`, "post", {
      filters,
      // pageNum: inputFilters?.pageNum,
      // pageSize: inputFilters?.pageSize,
    }).then((res) => {
      if (res.status === "Success") {
        setMenuCollection(res?.data?.list);
      }
    });
  };

  const handleCollectionChange = (selectedItem) => {
    if (selectedItem.value === "Default") {
      setSelectedCollection(collectionOptions[0]);
      return;
    }
    const selected = menuCollection.filter(
      (item) => item._id === selectedItem.value
    )[0];
    setSelectedCollection(selected);
    setEditCollection(selected);
  };
  const closePreview = () => setPreview(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const openBulkUploadModal = () => setBulkUploadModalOpen(true);
  const closeBulkUploadModal = () => setBulkUploadModalOpen(false);
  const openUploadModal = () => setIsModalOpen(true);
  const closeUploadModal = () => setIsModalOpen(false);
  const openMenuInformationModal = () => setMenuInformationModal(true);
  const closeMenuInformationModal = () => {
    setMenuInformationModal(false);
    setMenuId(null);
  };
  const openStartersInformationModal = () => setStartersInformationModal(true);
  const closeStartersInformationModal = () =>
    setStartersInformationModal(false);
  const openSuccessModal = () => setSuccessModal(true);
  const closeSuccessModal = () => setSuccessModal(false);
  const openSuccessModalForDelete = () => setSuccessModalForDelete(true);

  const closeSuccessModalForDelete = () => {
    setSuccessModalForDelete(false);
    window.location.reload();
  };

  const handleOpenModelWithMenuId = (id) => {
    setMenuInformationModal(true);
    setMenuId(id);
  };

  const getGroupMenuIdForDelete = (id) => {
    openModal();
    setMenuId(id);
  };

  const handlePageSize = (pageSize) => {
    let searchFilters = { ...filterData };
    searchFilters.pageSize = parseInt(pageSize);
    searchFilters.pageNum = 1;
    setFilterData(searchFilters);
    fetchMenuList(restaurant._id, searchFilters);
  };

  const handlePageNum = (pageNum) => {
    let searchFilters = { ...filterData };
    searchFilters.pageNum = parseInt(pageNum);
    setFilterData(searchFilters);
    fetchMenuList(restaurant._id, searchFilters);
  };

  const handleDeleteMenuById = (menuId) => {
    callApi(`group_menu/${menuId}/remove`, "post", {}).then((res) => {
      if (res.status === "Success") {
        closeModal();
        openSuccessModalForDelete();
      } else {
        alert("something went wrong");
      }
    });
  };
  const handleStatus = (id) => {
    callApi(`group_menu/${id}/change_status`, "get").then((res) => {
      if (res.status === "Success") {
        let newList = menuList.map((item) => {
          if (item?._id === res?.data?.detail?._id) {
            return res.data.detail;
          } else {
            return item;
          }
        });
        setMenuList(newList);
        successMsg("Status Updated Successfully...!");
      } else {
        errorMsg("something went wrong");
      }
    });
  };

  const handleMenuUpdate = (menu, key, value) => {
    let update = { ...menu };
    update[key] = value;

    callApi(`group_menu/${menu?._id}/update`, "post", {
      group_menu: update,
    }).then((res) => {
      if (res.status === "Success") {
        successMsg("Group menu details updated Successfully...!");
        // updateMenu(menuId, res.data.group_menu)
        if (!selectedCollection?.name) {
          fetchMenuList(restaurant._id);
        } else {
          let inputFilters = {};
          inputFilters.group_menus = selectedCollection.group_menus;
          inputFilters.is_default = false;
          fetchMenuList(restaurant._id, inputFilters);
        }
      } else {
        errorMsg("something went wrong");
      }
    });
  };

  console.log("edit collection", editCollection);

  const handleAddCollectionModal = () => {
    setAddCollectionModal(true);
  };

  const handleEditCollectionModal = () => {
    setEditCollectionModal(true);
  };

  const handleEditCollection = (collection, key, val) => {
    let update = { ...collection };
    console.log("edit ahndle", update);
    update[key] = val;
    setEditCollection(update);
  };

  const uploadMenuData = async (data) => {
    try {
      console.log(data);
      const chunkedData = _.chunk(data, 30);
      for (let j = 0; j < chunkedData.length; j++) {
        const e = chunkedData[j];
        await Promise.allSettled(
          e.map((r) =>
            callApi(`group_menu/new`, "post", {
              group_menu: r,
            })
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleBulkUpload = (file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        uploadMenuData(results.data);
      },
    });
  };

  return (
    <>
      <div className="menu-part">
        <Helmet>
          <title>Windmills | Menu</title>
        </Helmet>
        <ReactTooltip />
        <div className="menu-header">
          <div>
            <img src={MenuLogo} />
          </div>
          <div>
            <button className="add-menu-btn" onClick={() => setPreview(true)}>
              {" "}
              Preview
            </button>
            <button className="add-menu-btn" onClick={openBulkUploadModal}>
              Upload Menu
            </button>
            <button className="add-menu-btn" onClick={openMenuInformationModal}>
              Add Menu
            </button>
          </div>
        </div>
        <div className="download-upload-section">
          <div className="subtitle-box">
            <p>Menu</p>
            {collectionOptions && (
              <Dropdown
                dropdownLabel={
                  selectedCollection?.name
                    ? selectedCollection?.name
                    : "Default"
                }
                options={collectionOptions}
                optionClickHandler={(selectedItem) =>
                  handleCollectionChange(selectedItem)
                }
              />
            )}
            <div className="add-collection" onClick={handleAddCollectionModal}>
              Add Collection
            </div>
            {selectedCollection?.name && (
              <div
                className="add-collection"
                onClick={handleEditCollectionModal}
              >
                Edit Collection
              </div>
            )}
          </div>
        </div>
        <DefaultModal
          show={addCollectionModal}
          onHide={() => setAddCollectionModal(false)}
          className="add_modal"
          style={{ paddingRight: "0px" }}
          centered={false}
          size=""
          showFooter={false}
        >
          <div className="add-header" style={{ width: "100%" }}>
            <p>Add Collection</p>
            {restaurant && (
              <AddCollection
                screenPosition={collectionScreenPosition}
                restaurantId={restaurant._id}
                fetchMenuCollection={fetchMenuCollection}
                closeModal={() => setAddCollectionModal(false)}
              />
            )}
          </div>
        </DefaultModal>
        {/* Preview Modal */}
        <DefaultModal
          show={preview}
          onHide={() => {
            closePreview(false);
          }}
          className="add_modal"
          style={{ paddingRight: "0px" }}
          centered={false}
          size=""
          showFooter={false}
        >
          <div
            className="add-header"
            style={{
              width: "97%",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "-5px",
            }}
          >
            <p style={{ marginBottom: "-3px" }}>Menu Preview</p>
            <div
              style={{
                background: "#f5f1e8",
                fontSize: "16px",
                paddingTop: "0.2rem",
                paddingRight: "0.6rem",
                borderRadius: "3px",
                border: "none",

                marginTop: "0px",
                cursor: "pointer",
              }}
              onClick={() => {
                setPreview(false);
              }}
            >
              close
            </div>
          </div>
          <Preview
            closeModal={() => {
              setPreview(false);
            }}
            restaurantId={restaurant?._id}
          />
        </DefaultModal>
        {selectedCollection?.name && (
          <DefaultModal
            show={editCollectionModal}
            onHide={() => setEditCollectionModal(false)}
            className="add_modal"
            style={{ paddingRight: "0px" }}
            centered={false}
            size=""
            showFooter={false}
          >
            <div className="add-header" style={{ width: "100%" }}>
              <p>Edit Collection</p>
              {restaurant && (
                <EditCollection
                  screenPosition={collectionScreenPosition}
                  restaurantId={restaurant._id}
                  fetchMenuCollection={fetchMenuCollection}
                  closeModal={() => setEditCollectionModal(false)}
                  handleEditCollection={handleEditCollection}
                  collection={editCollection}
                  setSelectedCollection={setSelectedCollection}
                />
              )}
            </div>
          </DefaultModal>
        )}

        <TableFooter
          isPageVisible={false}
          count={total_menu_count}
          handlePageSizeChange={handlePageSize}
          handlePageNumChange={handlePageNum}
          pageSize={filterData.pageSize}
          pageNum={filterData.pageNum}
          pages={[10, 20, 50, 100]}
        />
        <div
          className="menu-body list-card-bg-color p-0"
          style={{ background: "#fff" }}
        >
          <table class="table">
            <thead class="thead-light">
              <tr>
                <th scope="col">Menu Name</th>
                <th scope="col">Screen Position</th>
                <th scope="col">Tile Position</th>
                <th scope="col">Action</th>
                {/* <th scope="col">Availability</th> */}
                <th scope="col">Items</th>
                <th scope="col">Delete</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody>
              {menuList &&
                menuList.map((item, index) => {
                  return (
                    <tr>
                      <Link
                        to={{ pathname: `/admin/menu/${item._id}` }}
                        params={{ itemName: item.name }}
                      >
                        {item.name}{" "}
                      </Link>
                      <td>
                        <Form.Select
                          aria-label="Super Admin"
                          className="select-event menu-select input_bottom"
                          value={
                            item.screen_position.toString() !== ""
                              ? item.screen_position
                              : "N/A"
                          }
                          onChange={(e) =>
                            handleMenuUpdate(
                              item,
                              "screen_position",
                              e.target.value
                            )
                          }
                          width="100%"
                        >
                          {collectionScreenPosition?.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </Form.Select>
                      </td>
                      <td>
                        <Form.Select
                          aria-label="Super Admin"
                          className="select-event menu-select input_bottom"
                          value={
                            item.tile_position.toString() !== ""
                              ? item.tile_position
                              : "N/A"
                          }
                          onChange={(e) =>
                            handleMenuUpdate(
                              item,
                              "tile_position",
                              e.target.value
                            )
                          }
                          width="100%"
                        >
                          {menuTilePosition?.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </Form.Select>
                      </td>
                      <td data-tip="Inactive" data-class="upload-data-tip">
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={item.is_active}
                            onClick={() => handleStatus(item._id)}
                          />
                          <span class="slider round"></span>
                        </label>
                      </td>
                      {/* <td>
                                    <Form.Select
                                        aria-label="Super Admin"
                                        className="select-event menu-select input_bottom"
                                        value={item?.availability_rule !== "" ? item?.availability_rule : "NA"}
                                        onChange={(e) => handleMenuUpdate(item, "availability_rule", e.target.value)}
                                        width="100%"
                                    >
                                        {availabilityRules?.map(item => (
                                            <option key={item.value} value={item.value}>{item.label}</option>
                                        ))}
                                    </Form.Select>
                                    </td> */}
                      <td>{item?.category_menu_count}</td>
                      <td>
                        <img
                          src={Delete}
                          onClick={() => getGroupMenuIdForDelete(item._id)}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                      <td>
                        <img
                          src={Pencil}
                          onClick={() => handleOpenModelWithMenuId(item._id)}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <TableFooter
          isCounterVisible={false}
          count={total_menu_count}
          handlePageSizeChange={handlePageSize}
          handlePageNumChange={handlePageNum}
          pageSize={filterData.pageSize}
          pageNum={filterData.pageNum}
          pages={[10, 20, 50, 100]}
        />
      </div>
      {/* Delete Modal */}
      <div className="delete-modal">
        <Modal show={isOpen} onHide={closeModal}>
          <Modal.Header closeButton className="border-none"></Modal.Header>
          <Modal.Body>
            <div className="text-center delete-modal">
              <img src={DeleteImgModal} />
              <h3>Are you sure you want to delete this item?</h3>
              {/* <p>Lorem ipsum, or lipsum as it is sometimes known,
                                is dummy text used in laying out print,</p> */}
            </div>
            <div className="d-flex justify-content-center">
              <div>
                <button
                  onClick={() => handleDeleteMenuById(menuId)}
                  className="delete-modal-btn"
                >
                  Delete
                </button>
              </div>
              <div>
                <button className="keep-modal-btn" onClick={closeModal}>
                  Keep
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {/* File upload Modal */}
      <div className="upload-modal">
        {/* Bulk-uplad-modal */}
        <Modal show={bulkUploadModalOpen} onHide={closeBulkUploadModal}>
          <Modal.Header closeButton className="border-none"></Modal.Header>
          <Modal.Body>
            <div className="text-center delete-modal">
              <h6 className="upload-modal-heading">Bulk Upload</h6>
            </div>
            <h5>Upload a .CSV file to bulk upload</h5>
            <ul className="bulk_modal_modal">
              <li> Max 50 MB file allowed. (upto 500000 entries)</li>
              <li> CSV should mandatorily contain menu</li>
              <li>
                {" "}
                If 2 (or more) items in CSV have the same name, they might not
                be added.
              </li>
              <li>
                If a menu in the CSV already exists in your account, the
                existing menu's traits will be updated as per the traits given
                in the CSV. (matching of items is done based on menu)
              </li>
            </ul>
            <div className="upload-section">
              <h3>Drag and drop your files here</h3>
              <p>- OR -</p>
              <div>
                <FilePicker
                  extensions={["csv"]}
                  onChange={handleBulkUpload}
                  onError={(errMsg) =>
                    toast(errMsg, { type: "error", hideProgressBar: true })
                  }
                >
                  <button>Upload File</button>
                </FilePicker>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* modal end */}
        <Modal show={isModalOpen} onHide={closeUploadModal}>
          <Modal.Header closeButton className="border-none"></Modal.Header>
          <Modal.Body>
            <div className="text-center delete-modal">
              <h6 className="upload-modal-heading">Menu File Upload</h6>
            </div>
            <div className="upload-section">
              <h3>Drag and drop your files here</h3>
              <p>- OR -</p>
              <div>
                <button onClick={openSuccessModal}>Upload File</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Menu Information Modal */}
        <DefaultModal
          show={menuInformationModal}
          onHide={() => closeMenuInformationModal(false)}
          className="add_modal"
          style={{ paddingRight: "0px" }}
          centered={false}
          size=""
          showFooter={false}
        >
          <div className="add-header">
            <h5>{menuId ? "Edit Menu" : "Add Menu"}</h5>
          </div>

          <AddMenu
            onHide={closeMenuInformationModal}
            menuId={menuId}
            menuList={menuList}
            fetchMenuList={fetchMenuList}
            restaurant={restaurant}
            filterData={filterData}
            selectedCollection={selectedCollection}
            setSelectedCollection={setSelectedCollection}
            setMenuList={setMenuList}
            collectionOptions={collectionOptions}
            screenPosition={collectionScreenPosition}
            tilePosition={menuTilePosition}
          />
        </DefaultModal>
        {/* Menu Starters Information Modal */}
        <DefaultModal
          show={startersInformationModal}
          onHide={() => closeStartersInformationModal(false)}
          className="add_modal"
          style={{ paddingRight: "0px" }}
          centered={false}
          size=""
          showFooter={false}
        >
          <div className="add-header">
            <p>Starters</p>
          </div>

          {/* <AddStarters /> */}
        </DefaultModal>

        {/* Success Modal */}
        <div className="">
          <Modal
            show={successModal}
            onHide={closeSuccessModal}
            className="success-modal"
          >
            <Modal.Header closeButton className="border-none"></Modal.Header>
            <Modal.Body>
              <div className="text-center">
                <img src={WhiteTick} />
                <p>Menu File uploaded successfully </p>
              </div>
              <div className="text-center">
                <button className="success-modal-btn">Go to Menu</button>
              </div>
            </Modal.Body>
          </Modal>
        </div>

        {/* Success Modal for delete */}
        <div className="">
          <Modal
            show={successModalForDelete}
            onHide={closeSuccessModalForDelete}
            className="success-modal"
          >
            <Modal.Header closeButton className="border-none"></Modal.Header>
            <Modal.Body>
              <div className="text-center">
                <img src={WhiteTick} />
                <p>Menu deleted successfully </p>
              </div>
              <div className="text-center">
                <Link to="/menu">
                  <button
                    onClick={() => closeSuccessModalForDelete()}
                    className="success-modal-btn"
                  >
                    Go to Menu
                  </button>
                </Link>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Menu;
