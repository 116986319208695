import React, {useEffect, useState} from 'react';
import Form from "react-bootstrap/Form";

const DishInformation = ({
                             dishInfo,
                             closeDishInformationModal,
                             foodTypeOptions,
                             stationOptions,
                             handleSaveMenu,
                             dishId,
                             handleChangedish
                         }) => {

    const [errors, setError] = useState({});

    const handleValidation = (dish) => {
        let isValid = true;
        let newErrors = {};
        if (!dish.name) {
            isValid = false;
            newErrors['name'] = 'Please enter name of Group Menu';
        }
        if (!dish.actual_price) {
            isValid = false;
            newErrors["actual_price"] = 'please enter default price'
        }
        return {
            isValid,
            errors: newErrors
        }

    }

    const handleNextInfo = () => {
        const check = handleValidation(dishInfo);
        if (check.isValid) {
            handleSaveMenu(dishId, dishInfo)
        } else {
            setError(check.errors)
        }
    }

    return (
        <>
            <div className={'row'}>
                <div className="col-md-6">
                    <Form className="menu-information">
                        <Form.Group className="input_bottom" controlId="formBasicEmail">
                            <Form.Label>Name <i className="fa fa-asterisk" aria-hidden="true"></i></Form.Label>
                            <Form.Control
                                type="text"

                                value={dishInfo.name}
                                onChange={(e) => handleChangedish("name", e.target.value)}
                                placeholder="Name"/>
                        </Form.Group>
                        {!dishInfo.name ? errors.name ?
                            <p className="add-employee-validation">{errors.name}</p> : null : null}
                    </Form>
                </div>
                <div className="col-md-6">
                    <Form className="menu-information dis">
                        <Form.Group className="input_bottom position-relative" controlId="formBasicEmail">
                            <div className="dish_switch">
                                <label className="switch">
                                    <input type="checkbox" checked={dishInfo.is_available}
                                           value={dishInfo.is_available}
                                           onChange={(e) => handleChangedish("is_available", !dishInfo.is_available)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <Form.Control
                                type="text"
                                placeholder="Dish Availability"
                                value={""}
                                onChange={(e) => handleChangedish("dish_availability", e.target.value)}
                            />
                        </Form.Group>
                        {!dishInfo.dish_availability ? errors.dish_availability ?
                            <p className="add-employee-validation">{errors.dish_availability}</p> : null : null}
                    </Form>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <Form className="menu-information">
                        <Form.Group className="input_bottom" controlId="formBasicEmail">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                style={{minHeight: '120px'}}
                                type="text"
                                height="120px"
                                as="textarea" rows={6}
                                placeholder="Description"
                                value={dishInfo.description}
                                onChange={(e) => handleChangedish("description", e.target.value)}
                            />
                        </Form.Group>
                        {!dishInfo.description ? errors.description ?
                            <p className="add-employee-validation">{errors.description}</p> : null : null}
                        <Form.Group className="input_bottom" controlId="formBasicEmail">
                            <Form.Label>Short Description </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Short Description"
                                value={dishInfo.short_description}
                                onChange={(e) => handleChangedish("short_description", e.target.value)}
                            />
                        </Form.Group>
                        {!dishInfo.short_description ? errors.short_description ?
                            <p className="add-employee-validation">{errors.short_description}</p> : null : null}
                    </Form>
                </div>
            </div>
            <div className={'row'}>
                <div className="col-md-6">
                    <Form className="menu-information">
                        <Form.Group className="input_bottom" controlId="formBasicEmail">
                            <Form.Label>Price Label In App </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Price Label In App"
                                value={dishInfo.app_price_label}
                                onChange={(e) => handleChangedish("app_price_label", e.target.value)}
                            />
                        </Form.Group>
                        {!dishInfo.app_price_label ? errors.app_price_label ?
                            <p className="add-employee-validation">{errors.app_price_label}</p> : null : null}

                        <Form.Group className="input_bottom" controlId="formBasicEmail">
                            <Form.Label>Default Price <i className="fa fa-asterisk" aria-hidden="true"></i></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Default Price*"
                                value={dishInfo.actual_price}
                                onChange={(e) => handleChangedish("actual_price", e.target.value)}
                            />
                        </Form.Group>
                        {!dishInfo.show_price ? errors.show_price ?
                            <p className="add-employee-validation">{errors.show_price}</p> : null : null}
                        <Form.Group className="input_bottom" controlId="formBasicEmail">
                            <Form.Label>Small Price Label in App </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Small Price Label in App"
                                value={dishInfo.small_app_price_label}
                                onChange={(e) => handleChangedish("small_app_price_label", e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="input_bottom" controlId="formBasicEmail">
                            <Form.Label>Small POS Id </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Small POS Id"
                                value={dishInfo.small_pos_id}
                                onChange={(e) => handleChangedish("small_pos_id", e.target.value)}
                            />
                        </Form.Group>
                        {!dishInfo.small_pos_id ? errors.small_pos_id ?
                            <p className="add-employee-validation">{errors.small_pos_id}</p> : null : null}
                        <Form.Group className="input_bottom" controlId="formBasicEmail">
                            <Form.Label>Digitory POS Id </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Digitory POS Id"
                                value={dishInfo.digitory_pos_id}
                                onChange={(e) => handleChangedish("digitory_pos_id", e.target.value)}
                            />
                        </Form.Group>
                        {!dishInfo.digitory_pos_id ? errors.digitory_pos_id ?
                            <p className="add-employee-validation">{errors.digitory_pos_id}</p> : null : null}
                        <Form.Group className="input_bottom" controlId="formBasicEmail">
                            <Form.Label>Digitory Normal Serving Size Id </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Digitory Small Serving Size Id"
                                value={dishInfo.digitory_normal_serving_size_id}
                                onChange={(e) => handleChangedish("digitory_normal_serving_size_id", e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </div>
                <div className="col-md-6">
                    <Form className="menu-information">
                        <Form.Group className="input_bottom" controlId="formBasicEmail">
                            <Form.Label>POS Id </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter POS Id"
                                value={dishInfo.pos_id}
                                onChange={(e) => handleChangedish("pos_id", e.target.value)}
                            />
                        </Form.Group>
                        {!dishInfo.pos_id ? errors.pos_id ?
                            <p className="add-employee-validation">{errors.pos_id}</p> : null : null}
                        <br/>
                        <Form.Label className="">Food Type</Form.Label>
                        <Form.Select
                            aria-label="Super Admin"
                            className="select-event menu-select input_bottom"
                            value={dishInfo.food_type}
                            onChange={(e) => handleChangedish("food_type", e.target.value)}
                            width="100%"
                        >
                            {foodTypeOptions?.map(item => (
                                <option key={item.value} value={item.value}>{item.label}</option>
                            ))}
                        </Form.Select>
                        {!dishInfo.food_type ? errors.food_type ?
                            <p className="add-employee-validation">{errors.food_type}</p> : null : null}

                        <Form.Group className="input_bottom" controlId="formBasicEmail">
                            <Form.Label>Default Small Price </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Default Small Price"
                                value={dishInfo.small_price}
                                onChange={(e) => handleChangedish("small_price", e.target.value)}
                            />
                        </Form.Group>
                        {!dishInfo.small_price ? errors.small_price ?
                            <p className="add-employee-validation">{errors.small_price}</p> : null : null}
                        <br/>
                        <Form.Group>
                            <Form.Label>Station</Form.Label>
                            <Form.Select
                                aria-label="Super Admin"
                                className="select-event menu-select input_bottom"
                                width="100%"
                                onChange={(e) => handleChangedish("station", e.target.value)}
                                value={dishInfo.station}
                            >
                                {stationOptions?.map(item => (
                                    <option key={item.value} value={item.value}>{item.label}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="input_bottom" controlId="formBasicEmail">
                            <Form.Label>Digitory Department Id </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Digitory Department Id"
                                value={dishInfo.digitory_department_id}
                                onChange={(e) => handleChangedish("digitory_department_id", e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="input_bottom" controlId="formBasicEmail">
                            <Form.Label>Digitory Small Serving Size Id </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Digitory Small Serving Size Id"
                                value={dishInfo.digitory_small_serving_size_id}
                                onChange={(e) => handleChangedish("digitory_small_serving_size_id", e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Form.Group className="input_bottom" controlId="formBasicEmail">
                        <Form.Label>Foot Note </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Foot Note"
                            value={dishInfo.foot_note_text}
                            onChange={(e) => handleChangedish("foot_note_text", e.target.value)}
                        />
                    </Form.Group>
                    {!dishInfo.foot_note_text ? errors.foot_note_text ?
                        <p className="add-employee-validation">{errors.foot_note_text}</p> : null : null}
                </div>
            </div>
            <div className="row">
                <div className="d-flex justify-content-between">
                    <button className="cancel_but" onClick={() => closeDishInformationModal()}>Cancel</button>
                    <button className="save_but" onClick={() => handleNextInfo()}>Save</button>
                </div>
            </div>
        </>
    );
}
export default DishInformation;
