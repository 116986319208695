import React from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import MenuLogo from "../../../assets/menu-logo.png";

export default class DishHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    //   menuInformationModal: false,
    // bulkUploadModal:false,
    };
  }
  render() {
    return (
      <div>
        <div className="menu-header">
            <div>
                <img src={MenuLogo} />
            </div>
        </div>

       
      </div>
    );
  }
}
