import React, {useEffect, useState} from "react";

import DefaultModal from "../../../hoc/DefaultModal/DefaultModal";
import DishTags from "./DishTags";
import Form from "react-bootstrap/Form";
import {option} from "../../../content/DishOptionjson";

// import Form from 'react-bootstrap/Form';

const DishOption = ({handleChangedish, dishInfo, dishId, setSegment, handleSaveMenu}) => {
    return (
        <>
            <div className="option_checkbox">
                <div className="col-lg-6">
                    <Form.Label className="menu-information-image-head">Visible In </Form.Label>
                    <Form.Select
                        aria-label="Super Admin"
                        className="select-event menu-select input_bottom"
                        width="100%"
                    >
                        <option>No</option>
                        <option value="SUPER ANALYST">Yes</option>
                    </Form.Select>
                </div>
                <DishTags
                    handleChangedish={handleChangedish}
                    dishInfo={dishInfo}
                />
                <div className="row">
                    <div className="col-lg-12">
                        <Form.Label className="menu-information-image-head">Display Tags</Form.Label>
                        <Form.Control
                            value={dishInfo?.display_tags}
                            onChange={(e) => handleChangedish('display_tags', e.target.value)}
                            aria-label="Display tags"
                            className="select-event menu-select input_bottom"
                            width="100%"
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <button className='cancel_but' onClick={() => setSegment("Screen details")}>Back</button>
                    <button className='save_but'
                            onClick={() => handleSaveMenu(dishId, dishInfo)}>Save
                    </button>
                </div>
            </div>
        </>
    );
};

export default DishOption;
