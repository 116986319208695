import {Link} from "react-router-dom";
import MenuLogo from "../../../assets/menu-logo.png";
import React from "react";

const StarterHeader = ({group_menu_id, category_menu_id}) => {
    return (
        <div>
            <div className="menu-header">
                <div>
                    <img src={MenuLogo}/>
                </div>
                <div>
                    <Link to={`/admin/menu/${group_menu_id}/category/${category_menu_id}/new-dish`}>
                        <button className="bluk-item-btn">Add Dish</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default StarterHeader;
