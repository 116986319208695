export const convertSecondsToHHMM = (seconds) => {
    let commonBase = 3600;
    let h = parseInt(seconds/commonBase);
    let m = parseInt((seconds - h*commonBase)/60);
    if (m < 10) {
        m = `0${m}`;
    }
    if (h < 10) {
        h = `0${h}`;
    }
    console.log("h:m", h, m);
    return `${h}:${m}`;
}

export const convertHHMMToSeconds = (timeStr) => {
    timeStr = timeStr.toString()
    let seconds = Number(timeStr.substring(0,2))*3600 + Number(timeStr.substring(3))*60
    return seconds
}

export const showDayObject = {
    0: "Monday",
    1: "Tuesday",
    2: "Wednesday",
    3: "Thursday",
    4: "Friday",
    5: "Saturday",
    6: "Sunday"
};

export const daysInMonth = [
    {
        "day": 1
    },
    {
        "day": 2
    },
    {
        "day": 3
    },
    {
        "day": 4
    },
    {
        "day": 5
    },
    {
        "day": 6
    },
    {
        "day": 7
    },
    {
        "day": 8
    },
    {
        "day": 9
    },
    {
        "day": 10
    },
    {
        "day": 11
    },
    {
        "day": 12
    },
    {
        "day": 13
    },
    {
        "day": 14
    },
    {
        "day": 15
    },
    {
        "day": 16
    },
    {
        "day": 17
    },
    {
        "day": 18
    },
    {
        "day": 19
    },
    {
        "day": 20
    },
    {
        "day": 21
    },
    {
        "day": 22
    },
    {
        "day": 23
    },
    {
        "day": 24
    },
    {
        "day": 25
    },
    {
        "day": 26
    },
    {
        "day": 27
    },
    {
        "day": 28
    },
    {
        "day": 29
    },
    {
        "day": 30
    },
    {
        "day": 31
    }
];
