import {Link} from "react-router-dom";
import React from "react";

import MenuLogo from "../../assets/menu-logo.png";
import RestaurantHeader from "../RestaurantListPage/Components/RestaurantHeader";

const RestaurantSettings = () => {

    const settings = [
        {
            image: "https://d3eavn3p0apacm.cloudfront.net/windmill-images/address_icon.svg",
            name: "Name and Address",
            link: "/admin/information"
        },
        {
            image: "https://d3eavn3p0apacm.cloudfront.net/windmill-images/Configuration.svg",
            name: "Configuration",
            link: "/admin/configuration"
        },
        {
            image: "https://d3eavn3p0apacm.cloudfront.net/windmill-images/Setup.svg",
            name: "Digitory Store Menu",
            link: "/admin/digitory-menu-list"
        },
        {
            image: "https://d3eavn3p0apacm.cloudfront.net/windmill-images/Section.svg",
            name: "Sections / Tables",
            link: "/admin/sections"
        },
        // {
        //     image: "https://d3eavn3p0apacm.cloudfront.net/windmill-images/Services.svg",
        //     name: "Services",
        // },
        // {
        //     image: "https://d3eavn3p0apacm.cloudfront.net/windmill-images/OperationTime.svg",
        //     name: "Operation Time",
        //     link: ""
        // }
    ]


    return (
        <>
            <div className="employee-header">
                <div><img src={MenuLogo}/></div>
            </div>
            <div className="right_side_section container-fluid">

                <RestaurantHeader
                    showAddRestaurant={true}
                />

                <div className="grid-container">
                    {settings.map((item, index) => (

                        <div className="munch_box">
                            <img src={item.image}/>
                            <Link to={item.link}><h5>{item.name}</h5></Link>
                        </div>

                    ))}
                </div>

            </div>

        </>
    )

}

export default RestaurantSettings;
