import React, {useState} from "react"
import styled from "styled-components";

const StyledDropdown = styled.div`
  margin-left : 25px;
  position : relative;
  width : 200px;
  
  .down_icon_sidebar img {
    transform: rotate(${(props) => props.rotate});
    transition: transform 0.5s;
  }

  .type-dropdown {
    background-color: white;
    font-size : 16px;
    padding : 5px;
    border-radius : 3px;
    border : 1px solid #0001;
  }

  .type-dropdown div{
    font-size : 16px;
    margin-right : 5px;
  }

  .dropdown-list {
    max-height: ${(props) => props.height};
    overflow: hidden;
    transition: max-height 0.3s;
    text-align: center;
    background-color : white;
    border-radius : 3px;
    border : 1px solid #0001;
    position: absolute;
    width: 100%;
    z-index: 999;
    cursor : pointer;
  }

  .dropdown-list p {
    font-size : 12px;
    font-weight : 300;
    margin-bottom : 0;
  }

  .dropdown-list p:hover {
    background-color : #0001;
  }

  .sidebar-link-icon {
    width : 15px;
  }
`;

const Dropdown = ({options, dropdownLabel, optionClickHandler}) => {
    
    const [dropHeight, setDropHeight] = useState(0);
    const [arrowRotate, setArrowRotate] = useState("0deg");

    const handleDropClick = () => {
      setDropHeight(dropHeight == 0 ? "300px" : 0);
      setArrowRotate(arrowRotate == "0deg" ? "180deg" : "0deg");
    };

    const handleOptionClick = (item) => {
      handleDropClick()
      optionClickHandler(item)
    }

    return <StyledDropdown height={dropHeight} rotate={arrowRotate}>
                  <div className="type-dropdown d-flex justify-content-between" onClick={handleDropClick}>
                    <div>
                      {/* <span>
                        <img
                          src="https://ik.imagekit.io/rzlzf40okya/smarthelio/grid_big.svg"
                          className="sidebar-link-icon"

                        />
                      </span> */}
                      <div className="mb-0 mr-2">{dropdownLabel}</div>
                    </div>
    
                    <div className="down_icon_sidebar" >
                      <img
                        src="https://ik.imagekit.io/rzlzf40okya/smarthelio/chevron_big_down.svg"
                        className="sidebar-link-icon"
                      />
                    </div>
                  </div>
                  <div className="dropdown-list">
                    {options?.map((item) => {
                      return (
                        <p key={item.value} onClick={() => handleOptionClick(item)}>
                          <p>{item.label}</p>
                        </p>
                      );
                    })}
                  </div>
                </StyledDropdown>
}

export default Dropdown