import React, {Component, useEffect, useState} from "react";
import Form from 'react-bootstrap/Form';
import callApi from "../../util/apiCaller";
import {USER_ID} from "../../constants/enums/appEnums";
import {errorMsg, successMsg} from "../../util/toast";
import {Helmet} from "react-helmet";
import isNumeric from 'validator/lib/isNumeric';

const ChangeProfile = () => {
    const [agent, setAgent] = useState({});
    const [agentId, setAgentId] = useState("");
    const [errors, setError] = useState({});

    const handleSetAgent = (id) => {
        callApi(`agent/${id}`, 'get').then(res => {
            console.log("response", res);
            if (res.status === "Success") {
                setAgent(res.data.agent)
            }
        })
    }
    useEffect(() => {
        if (localStorage) {
            let id = localStorage.getItem(USER_ID);
            setAgentId(id);
            handleSetAgent(id);
        }
    }, []);

    const handleChangeUser = (keyName, keyValue) => {
        console.log("called")
        let update = {...agent};
        update[keyName] = keyValue;
        setAgent(update);
    }

    const handleValidation = (newAgent) => {
        let isValid = true;
        let newErrors = {};
        if (!newAgent.contact_name) {
            isValid = false;
            newErrors["contact_name"] = 'please enter contact name of Agent'
        }
        if (!newAgent.username) {
            isValid = false;
            newErrors["username"] = 'please enter username of Agent'
        }
        if (!newAgent.passcode) {
            isValid = false;
            newErrors["passcode"] = 'please enter passcode of Agent'
        }
        if (newAgent.passcode && !isNumeric(newAgent.passcode)) {
            isValid = false;
            newErrors["passcode"] = 'Agent passcode must be numeric'
        }
        if (newAgent.passcode && newAgent.passcode.toString().length > 6) {
            isValid = false;
            newErrors["passcode"] = 'Agent passcode must be less than length 6'
        }
        if (!newAgent.contact_email) {
            isValid = false;
            newErrors['contact_email'] = 'Please enter email of Agent';
        }
        if (!newAgent.phone_number) {
            isValid = false;
            newErrors["phone_number"] = 'please enter phone number of Agent'
        }
        return {
            isValid,
            errors: newErrors
        }

    }


    const handleUpdateUser = () => {
        const check = handleValidation(agent);
        if (check.isValid) {
            let newAgent = {...agent}
            callApi(`auth/agent/${agentId}/update_profile`, 'post', {
                agent: newAgent
            }).then(res => {
                console.log("response", res);
                if (res.status === "Success") {
                    setAgent(res.data);
                    successMsg("Agent profile update successfully..!");
                    window.location.reload();
                } else {
                    errorMsg("Something went wrong...!");
                }
            })
        } else {
            setError(check.errors)
        }
    }

    console.log("errors", errors);

    return (
        <>
            <div className="employee-part add-events-section">
                <Helmet>
                    <title>Change Profile | Windmills</title>
                </Helmet>
                <div className="employee-section">
                    <p>Update Profile</p>
                </div>
                <div className="add-event-form">
                    <Form.Group className="mb-3 events-mt" controlId="formBasicName">
                        <Form.Label>Name<i class="fa fa-asterisk" aria-hidden="true"></i></Form.Label>
                        <Form.Control type="text"
                                      placeholder="Enter Event Name"
                                      className=""
                                      value={agent.contact_name}
                                      onChange={(e) => handleChangeUser("contact_name", e.target.value)}

                        />
                    </Form.Group>
                    {!agent.contact_name ? errors.contact_name ?
                        <p className="add-employee-validation">{errors.contact_name}</p> : null : null}
                    <Form.Group className="mb-3 events-mt" controlId="formBasicUsername">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text"
                                      placeholder="Enter Username"
                                      className=""
                                      value={agent.username}
                                      onChange={(e) => handleChangeUser("username", e.target.value)}
                        />
                    </Form.Group>
                    {!agent.username ? errors.username ?
                        <p className="add-employee-validation">{errors.username}</p> : null : null}
                    <Form.Group className="mb-3 events-mt" controlId="formBasicPhone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control type="text"
                                      placeholder="Enter Username"
                                      className=""
                                      value={agent.phone_number}
                                      onChange={(e) => handleChangeUser("phone_number", e.target.value)}
                        />
                    </Form.Group>
                    {!agent.phone_number ? errors.phone_number ?
                        <p className="add-employee-validation">{errors.phone_number}</p> : null : null}
                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email"
                                      placeholder="email@email.com"
                                      value={agent.contact_email}
                                      onChange={(e) => handleChangeUser("contact_email", e.target.value)}

                        />
                    </Form.Group>
                    {!agent.contact_email ? errors.contact_email ?
                        <p className="add-employee-validation">{errors.contact_email}</p> : null : null}
                    <Form.Group className="mb-3 events-mt" controlId="formBasicPassword">
                        <Form.Label>Passcode</Form.Label>
                        <Form.Control type="text"
                                      placeholder="Passcode"
                                      onChange={(e) => handleChangeUser("passcode", e.target.value)}
                                      value={agent.passcode}
                        />
                    </Form.Group>
                    {errors.passcode ?
                        <p className="add-employee-validation">{errors.passcode}</p> : null}
                    <Form.Group className="mb-3 events-mt" controlId="formBasicRestaurant">
                        <Form.Label>Restaurant</Form.Label>
                        <Form.Control type="text" placeholder="Enter Restaurant Name"/>
                    </Form.Group>
                    <Form.Group className="mb-3 events-mt" controlId="formBasicLocation">
                        <Form.Label>Location</Form.Label>
                        <Form.Control type="text" placeholder="Enter Location"/>
                    </Form.Group>
                    <div className="event-buttons">
                        <div className="cancel-btn">
                            <button>Cancel</button>
                        </div>
                        <div className="save-btn">
                            <button onClick={() => handleUpdateUser()}>Update Profile</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default ChangeProfile;
