
import React, {useEffect, useState} from 'react';
import { Helmet } from "react-helmet";
import DefaultModal from '../../hoc/DefaultModal/DefaultModal';
import "../../common/common.css"
import callApi from '../../util/apiCaller';
import { errorMsg } from '../../util/toast';
import styled from "styled-components";
import TableFooter from '../../hoc/TableFooter';
import { handlePageNum, handlePageSize } from '../../util/Utility';
import moment from 'moment/moment';
const GrayBoxContainer = styled.div`
    display : flex;
    justify-content : space-between;
    margin-bottom : 2%;

    .box {
        flex : 0 0 49.8%;
        background-color : #d9d9d9;
        height : 15vh;
        color : rgba(50, 50, 50, 0.67);
    }

    div p:nth-child(2){
        color : black;
        font-weight : 700;
    }
`

const StyledTdTag = styled.td`
    height : 15vh;

    :hover {
        background-color :#d9d9d9;
    }
`

function Logs() {
  const [isOpen, setIsOpen] = useState(false);
  const [logs, setLogs] = useState(null)
  const [logsCount, setLogsCount] = useState(null)
  const [modalData, setModalData] = useState(null)
  const [filterData, setFilterData] = useState({
    qtext: '',
    pageNum: 1,
    pageSize: 10,
})

  useEffect(() => {
    callApi("logs/list", "post", {
        "pageSize": filterData.pageSize,
        "pageNum" : filterData.pageNum
   })
   .then(res => {
    setLogs(res?.data?.list)
    setLogsCount(res?.data?.count)
   })
   .catch(err => {
    errorMsg("Logs not Fetched, Something Went Wrong!")
    console.log(err)
    })
  },[filterData])

  const handleClick = (item) => {
    setIsOpen(true)
    setModalData(item)
  }

  return (
    <div className="employee-part add-events-section">
        <Helmet>
            <title>Logs | Windmills</title>
        </Helmet>
        <div className="heading-css">
            <p className="remove-bottom-margin">Logs</p>
            <button className='dark-button'>Download</button>
        </div>

        <div style={{backgroundColor:"rgba(229, 209, 191, 0.1)", paddingTop : ".8%", borderRadius : "10px"}}>
            <TableFooter
                isPageVisible={false}
                pages={[10, 20, 50, 100]}
                count={logsCount}
                pageSize={filterData.pageSize}
                pageNum={filterData.pageNum}
                handlePageNumChange={(newPageNum) => handlePageNum(filterData, setFilterData, newPageNum)}
                handlePageSizeChange={(newPageSize) => handlePageSize(filterData, setFilterData, newPageSize)}
            ></TableFooter>
            <table class="table-css table table-border-unset">
                <thead style={{position : "initial"}} class="thead-light sticky-top">

                    <th className="cell-vertical-center" scope="col">Date & Time</th>
                    <th style={{textAlign : "center"}} className="cell-vertical-center"  scope="col">Action</th>

                </thead>

                <tbody>
                    {logs?.map((item, index) => (
                        <tr className="cursor-pointer" onClick={() => handleClick(item)}>
                            <td style={{whiteSpace : "nowrap"}} className="cell-vertical-center"scope="row">{item?.created_at ? String(moment(new Date(item?.created_at), 'lll')._d).slice(0, 25) : "NA"}</td>
                            <td style={{paddingLeft:"2.5%"}} className="cell-vertical-center">{item?.text ? item?.text : "NA"}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <TableFooter
                isCounterVisible={false}
                pages={[10, 20, 50, 100]}
                count={logsCount}
                pageSize={filterData.pageSize}
                pageNum={filterData.pageNum}
                handlePageNumChange={(newPageNum) => handlePageNum(filterData, setFilterData, newPageNum)}
                handlePageSizeChange={(newPageSize) => handlePageSize(filterData, setFilterData, newPageSize)}
            ></TableFooter>
        </div>
        
        <DefaultModal
                show={isOpen}
                onHide={() => setIsOpen(false)}
                centered={true}
                size=""
                showFooter={false}
                className="info-modal"
                title={`Logs`}
            >
            <div style={{margin:"2%",}}>
                {/* <div style={{ marginBottom : "40px"}}>
                    <GrayBoxContainer>
                        <div className="box div-center-vertical">
                           <p className='remove-bottom-margin'>CAPTAIN NAME</p> 
                        </div>
                        <div className="box div-center-vertical" style={{color : "black", fontWeight : "700"}}>{modalData?.name ? modalData?.name : "NA"}</div>
                    </GrayBoxContainer>
                    <GrayBoxContainer>
                        <div className="box div-center-vertical">
                           <p>TABLE No</p>
                           <p>{modalData?.table_num ? modalData?.table_num : "NA"}</p>
                        </div>
                        <div className="box div-center-vertical">
                            <p>DATE</p>
                           <p>{modalData?.created_at ? modalData?.created_at : "NA"}</p>
                        </div>
                    </GrayBoxContainer>
                </div> */}
                <table class="table table-border-unset">
                    <thead class="thead-light sticky-top">

                        <th  style={{textAlign : "center"}}  className="cell-vertical-center" scope="col">Time</th>
                        <th  style={{textAlign : "center"}} className="cell-vertical-center"  scope="col">Action</th>

                    </thead>

                    <tbody>
                        <tr>
                            <StyledTdTag className="cell-vertical-horizontal-center">{modalData?.created_at ? String(moment(new Date(modalData?.created_at), 'lll')._d).slice(0, 25) : "NA"}</StyledTdTag>
                            <StyledTdTag className="cell-vertical-horizontal-center">{modalData?.text ? modalData?.text : "NA"}</StyledTdTag>
                        </tr>
                    </tbody>
                </table>
            </div>
        </DefaultModal>
    </div>
  )
}

export default Logs