import React, {useState, useEffect} from "react";
import DeleteIcon from "../../../assets/delete-icon.svg"
import PlusIcon from "../../../assets/plus-icon.svg"
import {errorMsg, successMsg} from "../../../util/toast";

const TimeSlot = ({initialTime, getTimeValue, deleteItem, label}) => {

    // console.log("initialTime", initialTime);

    const [startTime, setStartTime] = useState("00:00");
    const [endTime, setEndTime] = useState("00:00");

    useEffect(() => {
        setStartTime(initialTime?.start_time);
        setEndTime(initialTime?.end_time);
    }, [initialTime])


    const setTime = (timeType, value) => {
        if (timeType === "start") {
            getTimeValue(value, endTime);
            setStartTime(value);
        } else if (timeType === "end") {
            getTimeValue(startTime, value);
            setEndTime(value);
        }
    }


    return (
        <>
            <div className='row'>
                <div>
                    {label}
                    <div className="time-picker-bg">
                        <p>
                            From
                            <span className="date-input">
                                <input
                                    onChange={(e) => setTime("start", e.target.value)}
                                    type="time"
                                    value={startTime}
                                />
                            </span>
                            TO
                            <span className="date-input">
                                <input
                                    onChange={(e) => setTime("end", e.target.value)}
                                    type="time"
                                    value={endTime}
                                />
                            </span>
                            <span
                                onClick={() => deleteItem(initialTime)}
                            >
                                <img src={DeleteIcon}/>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TimeSlot;
