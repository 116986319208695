import React,{useEffect, useState} from 'react'

import TableRecord from "./TableComponents/TableRecord"
import shortid from 'shortid';
import callApi from '../../util/apiCaller';
import { errorMsg, successMsg } from '../../util/toast';
import {getSelectedRestaurant} from "../../redux/reducers/AppReducer.js"
import { useSelector } from 'react-redux';
import { fetchContentByCode } from '../../util/Utility';
import RestaurantHeader from "../RestaurantListPage/Components/RestaurantHeader";

function RestaurantTableTracker() {

  const [tableRecords, setTableRecords] = useState([]) 
  const [originalTableList, setOriginalTableList] = useState([])
  const selected_restaurant = useSelector(state => getSelectedRestaurant(state))
  const [groupNames, setGroupNames] = useState(null)

  const handleRecordChange = (recordToUpdate) => {
    let newRecords = [...tableRecords]
    newRecords = newRecords.map(record => {
        if(record._id == recordToUpdate._id){
            record = recordToUpdate
        }

        return record
    })

    setTableRecords(newRecords)
  }

  useEffect(() => {
    fetchContentByCode('table-group', (err, content) => {
        if (err) {
            console.log(err);
        } else {
            setGroupNames(content?.params)
        }
    });
  },[])

  const fetchContents = () => {
    callApi(`seating_table/list`, "post", {
      filters : {
        restaurant_id : selected_restaurant._id
      }
    })
    .then(res => {
      let tableList = res?.data?.list
      tableList.sort((a,b) => a.table_rank - b.table_rank)
      setTableRecords(tableList)
      setOriginalTableList(tableList)
    }).catch(err => {
      // console.log(err)
      errorMsg("Something went wrong!")
    })
  } 

  useEffect(() => {
    fetchContents()
  },[selected_restaurant])

  const addRecord = () => {
    let newRecords = []
    if(tableRecords?.length > 0){
        newRecords = [...tableRecords , {
          _id : shortid.generate(),
          restaurant_id : selected_restaurant._id,
          table_name : "",
          table_num : "",
          table_seating_count : "",
          digitory_table_id : "",
          is_active : false,
          new : 1
        }]
    } else {
        newRecords = [{
          _id : shortid.generate(),
          restaurant_id : selected_restaurant._id,
          table_name : "",
          table_num : "",
          table_seating_count : "",
          digitory_table_id : "",
          is_active : false,
          new : 1
        }]
    }
    
    console.log("new records", newRecords)
    setTableRecords(newRecords)
  }

  const removeRecord = (id) => {

    let item = originalTableList.filter(record => record._id === id)
    console.log(item, "checking")
    //If original List (backend list) does not contain record, then directly remove from table records.
    if(!item.length){
      let newRecords = tableRecords.filter(record => record._id !== id)
      setTableRecords(newRecords)
      return 
    }

    callApi(`seating_table/${id}/remove`, "post", {})
    .then(res => {
      if(res.status === "Success"){
        successMsg(res.data)
      }
      fetchContents()
    })
    .catch(err => {
      // console.log(err)
      errorMsg("Something went wrong!")
    })
  }

  const validate = (table) => {
    let isValid = true
    let errors = {}

    if(!table.table_name){
      isValid = false
      errors.name = "Enter table name"
    }   
    if(!table.table_num){
      isValid = false
      errors.table_num = "Enter table number"
    }  
    if(!table.table_seating_count){
      isValid = false
      errors.table_seating_count = "Enter table seating count"
    }
    
    return {
      isValid,
      errors
    }
    
  }

  const handleAddOrUpdate = (id, isNew) => {

    let updateItem = tableRecords.filter(record => record._id === id)

    let item = {
      restaurant_id : updateItem[0].restaurant_id,
      table_name : updateItem[0].table_name,
      table_num : updateItem[0].table_num,
      table_rank : updateItem[0].table_rank,
      table_seating_count : updateItem[0].table_seating_count,
      group_name : updateItem[0].group_name,
      group_rank : updateItem[0].group_rank,
      digitory_table_id : updateItem[0].digitory_table_id,
    }
    //if update item add active parameter else not
    if(!isNew){
      item.is_active = updateItem[0].is_active
    }

    //validation for entries
    let check = validate(item)
    if(!check.isValid){
      for(let error in check.errors){
        errorMsg(check.errors[error])
      }
      return
    }

    //check if table already exists - for new table entry
    if(isNew){
      let existingTable = tableRecords.filter(record => record.table_name === updateItem[0].table_name && record._id !== updateItem[0]._id)

      if(existingTable.length !== 0){
        // console.log("existing", existingTable)
        errorMsg("Table Name already exists")
        return 
      }
    }

    
    if(isNew){
      callApi("seating_table/new", "post", {
        seating_table : item
      })
      .then(res => {
        if(res.status === "Success"){
          successMsg("Table added")
          fetchContents()
        } else {
          errorMsg("Something went wrong")
        }
      })
      .catch(err => {
        // console.log(err)
        errorMsg("Something went wrong")
      })
    } else {
      callApi(`seating_table/${id}/update`, "post", {
        seating_table : item
      })
      .then(res => {
        if(res.status === "Success"){
          successMsg("Table updated")
          fetchContents()
        } else {
          errorMsg("Something went wrong")
        }
      })
      .catch(err => {
        // console.log(err)
        errorMsg("Something went wrong")
      })
    }

  }

  const rowHeaders = [
                      "Table Name",
                      "Table Number", 
                      "Digitory Table Id",
                      "Table Rank",
                      "Group Name",
                      "Table Seating Count", 
                      "Active", 
                      "Help Requested",
                      "remove",
                      "Action"
                    ]

  return (
    <div style={{padding:"10px", width:"75%", float:"right"}}>
      <RestaurantHeader
        links={[{
          text: 'Sections / Tables',
          link: ''
        }]}
      />

        <h3>Tables</h3>
        <br />
        <table style={{width:'100%'}}>
          <thead>
            <tr>
              {rowHeaders?.map(header => <th style={{textAlign : "center"}}>{header}</th>)}
              </tr>
          </thead>

          {/* Slot container */}
        <tbody>
             {tableRecords?.length > 0 ?
              tableRecords.map(record => {
                return  <>
                        <TableRecord 
                          record = {record}
                          getRecordValue={(data) => handleRecordChange(data)}
                          removeParam={() => removeRecord(record._id)}
                          handleAddOrUpdate={handleAddOrUpdate}
                          groupNames = {groupNames}
                        />
                      </>

              })
              :
              null 
            }
        </tbody>
        </table>
        <div style={{textAlign:"center"}}><button onClick={() => addRecord()} className="add_but">+ Add Field</button></div>

    </div>
  )
}

export default RestaurantTableTracker