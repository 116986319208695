// import "../ChangePassword/changePassword.css"

import React, {useState} from "react";
import {errorMsg, successMsg} from "../../util/toast";

import Form from 'react-bootstrap/Form';
import callApi from "../../util/apiCaller";
import {toast} from 'react-toastify';
import {getBusiness} from "../../redux/reducers/AppReducer";
import {useSelector, useDispatch} from "react-redux";
import {setBusiness} from "../../redux/actions/AppActions";
import { Helmet } from "react-helmet";

const ChangeAppPassword = () => {

    const dispatch = useDispatch();

    const business = useSelector(state => getBusiness(state));
    const [new_password, setNewPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [isPasswordVisible, setPasswordVisibility] = useState(false);

    const handleValidation = () => {
        let isValid = true;
        let newErrors = {};
        if (!new_password) {
            isValid = false;
            newErrors['new_password'] = 'Please enter new password';
        }
        if (!confirm_password) {
            isValid = false;
            newErrors['confirm_password'] = 'Please re-enter password';
        }
        if (!(confirm_password === new_password)) {
            isValid = false;
            newErrors['confirm_password'] = 'Password does not match';
        }
        return {
            isValid,
            newErrors,
        }
    };

    const notify = (data) => {
        toast(data)
    }

    const handleSaveClick = () => {
        let check = handleValidation();
        let business_id = business._id;
        console.log("check", check);
        if (check.isValid) {
            callApi(`business/${business_id}/update-app-password`, "post", {
                app_password: new_password,
            }).then(res => {
                console.log("res", res);
                if (res.status === 'Success') {
                    dispatch(setBusiness(res?.data?.business));
                    successMsg(res.data.message);
                } else {
                    errorMsg(res.data.message);
                }
            })
        } else {
            setErrors(check.newErrors)
        }
    };

    const saveBusinessData = (update) => {
        callApi(`business/${business?._id}/update`, "post", {
            business: update,
        }).then(res => {
            console.log("res", res);
            if (res.status === 'Success') {
                dispatch(setBusiness(res?.data?.business));
                successMsg(res.data.message);
            } else {
                errorMsg(res.data.message);
            }
        })
    };

    const changePasswordVisibility = () => {
        setPasswordVisibility(!isPasswordVisible);
    }

    return (
        <>
            <div className="employee-part add-events-section">
            <Helmet>
                <title> Change App Password | Windmills</title>
            </Helmet>
                <div className="employee-section">
                    <p>Change App Password</p>
                </div>
                <div className="add-event-form app_pass">
                    <h6>Code &nbsp;<img
                        src="https://d3eavn3p0apacm.cloudfront.net/windmill-images/info.png"/></h6>
                    <div className="code_box">
                        <input type="number"
                               disabled={true}
                               maxLength={1}
                               className="input_code"
                               value={business?.app_password?.toString().substring(0, 1)}
                        />
                        <input type="number"
                               disabled={true}
                               maxLength={1}
                               className="input_code"
                               value={business?.app_password?.toString().substring(1, 2)}
                        />
                        <input type="number"
                               disabled={true}
                               maxLength={1}
                               className="input_code"
                               value={business?.app_password?.toString().substring(2, 3)}
                        />
                        <input type="number"
                               disabled={true}
                               maxLength={1}
                               className="input_code"
                               value={business?.app_password?.toString().substring(3, 4)}
                        />
                        <Form.Select
                            onChange={(e) => saveBusinessData({password_change_frequency: e.target.value})}
                            value={business?.password_change_frequency}
                            aria-label="Default select example">
                            <option>Reset Password Weekly</option>
                            <option value="weekly">Reset Password Weekly</option>
                            <option value="monthly">Reset Password Monthly</option>
                            <option value="daily">Reset Password Everyday</option>
                        </Form.Select>
                    </div>
                    <Form.Group className="mb-3 events-mt position-relative" controlId="formBasicEmail">
                        <Form.Label>New Password</Form.Label>
                        <i onClick={() => changePasswordVisibility()}
                           className={`fa ${isPasswordVisible ? "fa-eye-slash" : "fa-eye"}`}
                           aria-hidden="true"></i>
                        <Form.Control
                            value={new_password}
                            onChange={(e) => setNewPassword(e.target.value)}
                            type={isPasswordVisible ? "text" : "password"}
                            placeholder="Enter New Password"/>
                    </Form.Group>
                    {errors && errors.new_password ? <label className="error_msg" style={{
                        fontSize: "12px",
                        color: "#ff0000bd"
                    }}>{errors.new_password}</label> : null}
                    <Form.Group className="mb-3 events-mt position-relative" controlId="formBasicEmail">
                        <Form.Label>Confirm Password</Form.Label>
                        <i onClick={() => changePasswordVisibility()}
                           className={`fa ${isPasswordVisible ? "fa-eye-slash" : "fa-eye"}`}
                           aria-hidden="true"></i>
                        <Form.Control
                            value={confirm_password}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            type={isPasswordVisible ? "text" : "password"}
                            placeholder="Re-Enter New Password"/>
                    </Form.Group>
                    {errors && errors.confirm_password ? <label className="error_msg" style={{
                        fontSize: "12px",
                        color: "#ff0000bd"
                    }}>{errors.confirm_password}</label> : null}
                    <div className="event-buttons">
                        <div className="cancel-btn">
                            <button>Cancel</button>
                        </div>
                        <div className="save-btn">
                            <button onClick={() => handleSaveClick()}>Update Password</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangeAppPassword;
