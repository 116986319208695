import { Button, Modal } from "react-bootstrap";
import React, { Component } from "react";
import { successMsg, errorMsg } from "../../../util/toast";
import Form from 'react-bootstrap/Form';
import MenuInformation from './MenuInformation';
import OperationTime from "./OperationTime";
import ScreenDetails from "./ScreenDetails";
import callApi from "../../../util/apiCaller";
import {fetchContentByCode} from "../../../util/Utility"
export default class AddMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSegment: "Information",
            menuInfo: {
                is_default : true
            },
            robotStations : [],
            stations : [],
            listPosition : [],
            screenPosition : [],
            menuRowSpan : [],
            menuColumnSpan : [],
            menuTagText: "",
        };
    }

    

    fetchContentByCode = (code, next) => {
        callApi(`content/code/${code}`, 'get').then(res => {
            if (res.status === "Success") {
               return next(null, res.data.content);
            }
        }).catch(err => {
            console.log(err)
            return next(err);
        })
    }

    handleSaveMenu = (menuId, _menuInfo) => {
        const menuInfo = _menuInfo
        if(!menuInfo.tile_position &&  !menuInfo.image_url) {
            menuInfo.image_url = 'NOT_SET' // hardcoded, needs to be integrated
            menuInfo.tile_position = '1'
        }
        console.log(this.state.menuTagText, "this.state.menuTagText");
        menuInfo.tags = this.state.menuTagText ? this.state.menuTagText.split(",") : [];
        if (menuId) {
            callApi(`group_menu/${menuId}/update`, 'post', {
                group_menu: menuInfo
            }).then(res => {
                if (res.status === "Success") {
                    successMsg("Group menu details updated Successfully...!");
                    // updateMenu(menuId, res.data.group_menu)
                    this.setState({menuInfo : res.data.group_menu})
                    if(!this.props.selectedCollection?.name){
                        this.props.fetchMenuList(this.props.restaurant._id)
                    } else {
                        let inputFilters = {}
                        inputFilters.group_menus = this.props.selectedCollection.group_menus
                        inputFilters.is_default = false
                        this.props.fetchMenuList(this.props.restaurant._id, inputFilters)
                    }
                    
                } else {
                    errorMsg("something went wrong");
                }
            });
        } else {
            // ------ This Logic adds menu to global menu list ------- //
            menuInfo["restaurant_id"] = this.props.restaurant._id;
            callApi(`group_menu/new`, 'post', {
                group_menu: menuInfo
            }).then(res => {
                if (res.status === "Success") {
                    successMsg("New group menu added Successfully...!");
                    this.setState({menuInfo : res.data.group_menu})
                    // if(res.data.group_menu.is_default){
                    //     this.props.setSelectedCollection(this.props.collectionOptions[0]);
                    // }

                    // -------- This Logic add menu to the selected menu collection -------- //
                    if(this.props.selectedCollection?.name && !res.data.group_menu.is_default){
                        let update = {name : this.props.selectedCollection.name, group_menus : this.props.selectedCollection.group_menus}
                        update.group_menus.push(res?.data?.group_menu._id)
                        console.log("added collection", this.props.selectedCollection)
                        callApi(`menu_collection/${this.props.selectedCollection._id}/update`, "post", {
                            menu_collection : update
                        })
                        .then(res => {
                            if(res.status === "Success"){
                                successMsg(`Collection ${this.props.selectedCollection.name} updated!`)
                                this.props.setSelectedCollection(res?.data?.menu_collection)
                            }
                            if(res.status === "Error"){
                                errorMsg(res?.data)
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    }
                    
                } else {
                    errorMsg(res.data.message);
                }
            });

            
            
        }
    }

    componentDidMount() {
        if (this.props.menuId) {
            callApi(`group_menu/${this.props.menuId}`, 'get').then(res => {
                if (res.status === "Success") {
                    this.setState({ menuInfo: { ...res.data.group_menu } }, () => {
                        this.setState({
                            menuTagText : this.state.menuInfo.tags.join(",")
                        })
                    });
                } else {
                    alert("something went wrong")
                }
            });
        }
        fetchContentByCode('robot-stations', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                this.setState({
                    robotStations : content?.params
                })
            }
        });
        fetchContentByCode('station-options', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                this.setState({
                    stations : content?.params
                })
            }
        });
       
        fetchContentByCode('menu-row-span', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                this.setState({
                    menuRowSpan : content?.params
                })
            }
        });
        fetchContentByCode('menu-column-span', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                this.setState({
                    menuColumnSpan : content?.params
                })
            }
        });
    }

    handleChangeGroupMenu = (keyName, keyValue) => {
        if (keyName === "menuTagText") {
            this.setState({
                menuTagText: keyValue
            });
        } else {
            let update = { ...this.state.menuInfo }
            update[keyName] = keyValue;
            this.setState({ menuInfo: update });
        }
    }

    setSegment = (selectedSegment = "") => {
        this.setState({
            selectedSegment: selectedSegment
        })
    };
    render() {
        return (
            <>
                <div style={{ padding: "10px 60px 10px 60px" }}>
                    <ul className="d-flex information-modal">
                        <li className="information col-4"
                            style={{ fontWeight: this.state.selectedSegment === "Information" ? '600' : '400' }}
                            onClick={() => this.setSegment("Information")}>Information</li>
                        <li className="screen-details col-4"
                            style={{ fontWeight: this.state.selectedSegment === "Screen details" ? '600' : '400' }}
                            onClick={() => this.setSegment("Screen details")}>Screen details</li>
                        <li className="operation-time col-4"
                            style={{ fontWeight: this.state.selectedSegment === "Operation Time" ? '600' : '400' }}
                            onClick={() => this.setSegment("Operation Time")}>Operation Time</li>
                    </ul>
                </div>
                <div className="row">
                    {this.state.selectedSegment === "Information" ?
                        <MenuInformation
                            setSegment={this.setSegment}
                            onHide={this.props.onHide}
                            menuId={this.props.menuId}
                            menuInfo={this.state.menuInfo}
                            handleChangeGroupMenu={this.handleChangeGroupMenu}
                            stations={this.state.stations}
                            robotStations={this.state.robotStations}
                            selectedCollection = {this.props.selectedCollection}
                        />
                        :
                        null
                    }
                    {this.state.selectedSegment === "Screen details" ?
                        <ScreenDetails
                            setSegment={this.setSegment}
                            menuId={this.props.menuId}
                            menuInfo={this.state.menuInfo}
                            handleChangeGroupMenu={this.handleChangeGroupMenu}
                            menuColumnSpan={this.state.menuColumnSpan}
                            menuRowSpan={this.state.menuRowSpan}
                            menuListPosition={this.props.tilePosition}
                            menuScreenPosition={this.props.screenPosition}
                            menuTagText={this.state.menuTagText}
                        />
                        :
                        null
                    }
                    {this.state.selectedSegment === "Operation Time" ?
                        <OperationTime
                            setSegment={this.setSegment}
                            menuId={this.props.menuId}
                            menuInfo={this.state.menuInfo}
                            availability_rule={this.state.menuInfo.availability_rule}
                            availability_daily={this.state.menuInfo.availability_daily}
                            availability_weekly={this.state.menuInfo.availability_weekly}
                            availability_monthly={this.state.menuInfo.availability_monthly}
                            handleSaveMenu={this.handleSaveMenu}
                            handleChangeGroupMenu={this.handleChangeGroupMenu}
                            onHide={this.props.onHide}
                        />
                        :
                        null
                    }
                </div>
            </>
        )
    }
}
