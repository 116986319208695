import React from "react";
import {Link} from "react-router-dom";
import Breadcrumbs from "../../../hoc/Breadcrumbs";

const DishPageBreadCrumbs = ({groupMenu, menuCategory}) => {

    const links = [
        {
            name: 'Menus',
            url: '/admin/menu'
        },
        {
            name: `${groupMenu?.name}`,
            url: `/admin/menu/${groupMenu?._id}`
        },
        {
            name: `${menuCategory?.name}`,
            url: `/admin/menu/${groupMenu?._id}/category/${menuCategory?._id}`
        },
        {
            name: "Add New Dish",
            url: ""
        }
    ];
    if (groupMenu?._id && menuCategory?._id) {
        return (
            <div className="breadcrumb_bg">
                <Breadcrumbs
                    links={links}
                />
            </div>

        );
    }
    return null;
}

export default DishPageBreadCrumbs;
