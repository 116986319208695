import { toast } from "react-toastify";


export const successMsg = (msg) => {
    console.log("vinmksf");
    toast.success(msg);
}

export const errorMsg = (msg) => {
    toast.error(msg);
}
