import { Button, Modal, ModalBody } from "react-bootstrap";
import React, { Component, useEffect, useState } from "react";
import { errorMsg, successMsg } from "../../../util/toast";
import Form from 'react-bootstrap/Form'
import AddStarters from "./AddStarters";
import Breadcrumbs from "../../../hoc/Breadcrumbs";
import DefaultModal from "../../../hoc/DefaultModal/DefaultModal";
import Delete from "../../../assets/delete.svg";
import DeleteImgModal from "../../../assets/delete-img.png";
import { Link } from "react-router-dom";
import Pencil from "../../../assets/pencil.svg";
import TableFooter from "../../../hoc/TableFooter";
import WhiteTick from "../../../assets/white-tick.svg";
import callApi from "../../../util/apiCaller";
import { PuffLoader } from "react-spinners";


const CategoryTable = ({menuList, pageSize, pageNum,setMenuCategroy, menu_category_count, pages, handlePageSizeChange, handlePageNumChange, fetchCategoryList, ...props}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [starterModalOpen, setIsStarterModalOpen] = useState(false)
    const [categoryMenu, setCategoryMenu] = useState([]);
    const [categoryId, setCategoryId] = useState("");
    const [menuId, setMenuId] = useState(""); 
    const [successModalForDelete, setSuccessModalForDelete] = useState(false);
    
    useEffect(() => {
        setCategoryMenu(menuList)
        setMenuId(props.menuId);
    }, [menuList]);
    const getGroupMenuIdForDelete = (id) => {
        openModal()
        setCategoryId(id);
    }
    const editCategoryMenu = (id) => {
        openStarterModal()
        setCategoryId(id);
    }
    const handleStatus = (id, index) => {
        callApi(`menu_category/${id}/change_status`, 'get').then(res => {
            if (res.status === "Success") {
                //setMenuList(res.data.detail);
                successMsg("Status Updated Successfully...!");
                let prevcategoryMenu = categoryMenu;
                prevcategoryMenu[index].is_active = !prevcategoryMenu[index].is_active;
                setMenuCategroy([...prevcategoryMenu]);
            } else {
                errorMsg("something went wrong");
            }
        });
    }
    const handleDeleteCategoryMenuById = (categoryId) => {
        callApi(`menu_category/${categoryId}/remove`, 'post', {}).then(res => {
            if (res.status === "Success") {
                console.log("menu deleted", res);
                closeModal();
                openSuccessModalForDelete();
                successMsg("Category Menu Deleted Successfully...!");
            }
             else {
                errorMsg("something went wrong");
            }
        });
    }

    const handleUpdateCategoryMenu = (menu, key, value) => {
        let update = {...menu}
        update[key] = value;
        callApi(`menu_category/${menu._id}/update`, 'post', {
            menu_category: update
        }).then(res => {
            if (res.status === "Success") {
                successMsg("Category menu details updated Successfully...!");
                fetchCategoryList()
            } else {
                errorMsg("something went wrong");
            }
        });
    }

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);
    const openStarterModal = () => setIsStarterModalOpen(true);
    const closeStarterModal = () => setIsStarterModalOpen(false);
    const openSuccessModalForDelete = () => setSuccessModalForDelete(true);

    const closeSuccessModalForDelete = () => {
        setSuccessModalForDelete(false)
        window.location.reload();
    };

    console.log("list", menuList);
    return (
        <>
            <div className="">
                <Breadcrumbs />
                <TableFooter
                    isPageVisible={false}
                    pages={pages}
                    pageSize={pageSize}
                    count={menu_category_count}
                    pageNum={pageNum}
                    handlePageNumChange={handlePageNumChange}
                    handlePageSizeChange={handlePageSizeChange}
                >
                </TableFooter>
                <div className="menu-body">
                    <table class="table">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">Category</th>
                                <th scope="col">Items</th>
                                <th scope="col">Position</th>
                                {/* <th scope="col">Availability Rule</th> */}
                                <th scope="col">Edit</th>
                                <th scope="col">Action</th>
                                <th scope="col">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categoryMenu && categoryMenu.map((item, index) => (
                                <tr>
                                    <Link
                                        to={{
                                            pathname: `/admin/menu/${item.group_menu_id}/category/${item._id}`,
                                            state: { group_menu_id: item.group_menu_id }
                                        }}
                                    > {item.name}</Link>
                                    <td>{item.dish_count}</td>
                                    <td>
                                    <Form.Select
                                        aria-label="Super Admin"
                                        className="select-event menu-select input_bottom"
                                        value={item?.position?.toString() !== "" ? item.position : 'N/A'}
                                        onChange={(e) => handleUpdateCategoryMenu(item, "position", e.target.value)}
                                        width="100%"
                                    >
                                        {props.listPosition?.map(item => (
                                            <option key={item.value} value={item.value}>{item.label}</option>
                                        ))}
                                    </Form.Select>
                                    </td>
                                    {/* <td>
                                    <Form.Select
                                        aria-label="Super Admin"
                                        className="select-event menu-select input_bottom"
                                        value={item?.availability_rule !== "" ? item?.availability_rule : "NA"}
                                        onChange={(e) => handleUpdateCategoryMenu(item, "availability_rule", e.target.value)}
                                        width="100%"
                                    >
                                        {props.availabilityRules?.map(item => (
                                            <option key={item.value} value={item.value}>{item.label}</option>
                                        ))}
                                    </Form.Select>
                                    </td> */}
                                    <td><img src={Pencil} onClick={() => editCategoryMenu(item._id)} style={{ cursor: "pointer" }}/></td>
                                    <td data-tip="Inactive" data-class="upload-data-tip">
                                        <label class="switch">
                                            <input type="checkbox" checked={item.is_active} onClick={() => handleStatus(item._id, index)} />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                    <td><img src={Delete} onClick={() => getGroupMenuIdForDelete(item._id)} style={{ cursor: "pointer" }}/></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <TableFooter
                    isCounterVisible={false}
                    pages={pages}
                    pageSize={pageSize}
                    count={menu_category_count}
                    pageNum={pageNum}
                    handlePageNumChange={handlePageNumChange}
                    handlePageSizeChange={handlePageSizeChange}
                >
                </TableFooter>
            </div>

            {/* Delete Modal */}
            <div className="delete-modal">
                <Modal show={isOpen} onHide={closeModal}>
                    <Modal.Header closeButton className="border-none">

                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center delete-modal">
                            <img src={DeleteImgModal} />
                            <h3>Are you sure you want to delete this item?</h3>
                        </div>
                        <div className="d-flex justify-content-center">
                            <div><button className="delete-modal-btn" onClick={() => handleDeleteCategoryMenuById(categoryId)}>Delete</button></div>
                            <div ><button className="keep-modal-btn" onClick={closeModal}>Keep</button></div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            {/* Edit starter modal */}
            <div className="">
                <DefaultModal
                    show={starterModalOpen}
                    onHide={() => closeStarterModal(false)}
                    className="add_modal"
                    style={{ paddingRight: "0px" }}
                    centered={false}
                    size=""
                    showFooter={false}

                >
                    <div className="add-header">
                        <h5>Edit Starters</h5>
                    </div>
                    <AddStarters
                        type="edit"
                        menuId={menuId}
                        categoryId={categoryId}
                        fetchCategoryList={fetchCategoryList}
                        listPosition={props.listPosition}
                    />
                </DefaultModal>
            </div>
             {/* Success Modal for delete */}
             <div className="">
                    <Modal show={successModalForDelete} onHide={closeSuccessModalForDelete} className="success-modal">
                        <Modal.Header closeButton className="border-none">

                        </Modal.Header>
                        <Modal.Body>
                            <div className="text-center">
                                <img src={WhiteTick} />
                                <p>Category Menu deleted successfully </p>
                            </div>
                            <div className="text-center">
                                <Link to="/menu" ><button onClick={() => closeSuccessModalForDelete()} className="success-modal-btn">Go to Menu</button></Link>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            

        </>
    )

}

export default CategoryTable;
