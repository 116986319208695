import React from 'react';
import TimeSlot from "./TimeSlot";
import AddTimeSlot from './AddTimeSlot';

const TimePicker = ({
                        timeSlots,
                        getTimeValue,
                        deleteTimeSlot,
                        addTimeSlot,
                        day = null,
                    }) => {
    return (
        <div className='row'>
            <div className='col-md-12' >
                {timeSlots && timeSlots.map((item, index) => (
                    <TimeSlot
                        initialTime={item}
                        label={item?.label ? item.label : index + 1}
                        getTimeValue={(start, end) => getTimeValue(item, start, end, day)}
                        deleteItem={deleteTimeSlot}
                    />
                ))}
                <AddTimeSlot
                    addItem={addTimeSlot}
                />
            </div>
        </div>
    );
}

export default TimePicker;
