import React, { useEffect, useState } from "react";
import "./Preview.css";

const Preview = ({ closeModal, restaurantId }) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (restaurantId) {
      setUrl(`https://wm-menu.antcreatives.com/menu/${restaurantId}`);
    }
  }, [restaurantId]);

  return (
    <div style={{ height: "100shv" }}>
      {/* <h1>Preview</h1> */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        {/* <button className="btn" onClick={urlHandler}>
          Menu Preview
        </button> */}
      </div>
      <iframe src={url} width="98%" height="100%" />
    </div>
  );
};

export default Preview;
