import './globalSidebar.css'

import {AGENT, APP_TOKEN, SET_RESTAURANT_ID, USER_ID} from "../constants/enums/appEnums";
import {Link, NavLink} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import {getRestaurantList, getSelectedRestaurant} from "../redux/reducers/AppReducer";
import {useDispatch, useSelector} from 'react-redux';

import Dropdown from 'react-bootstrap/Dropdown';
import meta from '../meta.json';
import {setSelectedRestaurant} from "../redux/actions/AppActions";
import userIcon from '../assets/user-icon-1.svg'

const dynamicLinks = [
    {
        url: `/admin/dishes`,
        name: 'Dishes'
    },
    {
        url: `/admin/menu`,
        name: 'Menu'
    },
    {
        url: `/admin/events`,
        name: 'Events'
    },
    {
        url: `/admin/employees`,
        name: 'Employees'
    },
    {
        url: `/admin/documents`,
        name: 'Documents'
    },
    {
        url: `/admin/contents`,
        name: 'Contents'
    },
    {
        url: `/admin/kds`,
        name: 'KDS'
    },
    {
        url: `/admin/logs`,
        name: 'Logs'
    },
];

const GlobalSidebar = () => {

    const dispatch = useDispatch();
    const [user, setUser] = useState({})
    const restaurant_list = useSelector(state => getRestaurantList(state));
    const selectedRestaurant = useSelector(state => getSelectedRestaurant(state));
    // const [isOpen, setisOpen] = useState(false);
    const [isHamburgerOpened, setIsHamburgerOpened] = useState(false);

    useEffect(() => {
        if (localStorage) {
            let agent = localStorage.getItem(AGENT)
            agent = agent ? JSON.parse(agent) : null;
            setUser(agent);
        }
    }, []);

    const openHamburgerMenu = () => {
        isHamburgerOpened === false ? setIsHamburgerOpened(true) : setIsHamburgerOpened(false)
    };

    const selectRestaurant = (item) => {
        window.localStorage.setItem(SET_RESTAURANT_ID, item._id.toString())
        dispatch(setSelectedRestaurant(item));
    };

    const handleLogOut = () => {
        window.localStorage.removeItem(APP_TOKEN);
        window.localStorage.removeItem(USER_ID);
        window.localStorage.removeItem(AGENT);
        window.location = "/login";
    };

    console.log("selectedRestaurant", selectedRestaurant);

    return (
        <>
            <div className='side_bar'>
                <div className='side-bar-bg' id="dashboard-side-bar">
                    <div className="user-icon-img">
                        <div className=''>


                            {isHamburgerOpened === true ? (
                                    <div className='sidebar_toggle'>
                                        <i className="fa fa-times" aria-hidden="true" onClick={() => openHamburgerMenu()}/>
                                        <ul>
                                            <li><Link to="/admin/change-profile">Change Profile</Link></li>
                                            <li><Link to="/admin/change-password">Change Password</Link></li>
                                             <li><Link to="/admin/restaurant-list">Restaurant List</Link></li>
                                             <li><Link to="/admin/restaurant">Restaurant Details</Link></li>
                                            {/* <li><Link to="/employee">Payment Config</Link></li>
                                            <li><Link to="/pos-configration">POS Config</Link></li>
                                            <li><Link to="/employee">Reservation Schedule</Link></li>
                                            <li><Link to="/employee">Additional Charge</Link></li>
                                            <li><Link to="/employee">User Custom Field</Link></li> */}
                                            <li><Link to="/admin/change-app-password">Change App Password</Link></li>
                                            <li><a onClick={() => handleLogOut()} style={{cursor: "pointer"}}>Logout</a>
                                            </li>
                                        </ul>
                                    </div>
                                )
                                :
                                (
                                    <>
                                        <div className="bars-open"
                                             onClick={() => openHamburgerMenu()}
                                        >
                                            <div className='d-flex align-items-baseline' style={{cursor: "pointer"}}>
                                                <p> {user?.contact_name}</p>&nbsp;
                                                <i className="fa fa-caret-down" aria-hidden="true"/></div>
                                        </div>

                                        <Dropdown
                                            autoClose={true}
                                        >
                                            <Dropdown.Toggle id="dropdown-basic">
                                                {selectedRestaurant?.name}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {restaurant_list.map(item =>
                                                    <Dropdown.Item
                                                        onClick={() => selectRestaurant(item)}
                                                    >
                                                        <div
                                                            className='d-flex align-items-baseline selected_item'>
                                                            <p>{item?.name}</p>
                                                        </div>
                                                    </Dropdown.Item>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </>
                                )}
                        </div>
                        <div><img src={userIcon}/></div>
                    </div>
                    <div className="sidebar-links">
                        <ul>
                            {dynamicLinks && dynamicLinks.map((item, index) => (
                                <li key={index} className="nav-item position-relative">
                                    <NavLink to={item.url} activeClassName="sideBarActive">
                                        <div className='border_left'></div>
                                        {item.name}
                                    </NavLink>
                                </li>
                            ))}
                            {/* <li className="nav-item position-relative">
                                <NavLink to="/kds" activeClassName="sideBarActive">
                                    <div className='border_left'></div>
                                    KDS</NavLink>
                            </li>
                            <li className="nav-item position-relative">
                                <NavLink to="/feedback" activeClassName="sideBarActive">
                                    <div className='border_left'></div>
                                    Feedback</NavLink>
                            </li>
                            <li className="nav-item position-relative">
                                <NavLink to="/reports" activeClassName="sideBarActive">
                                    <div className='border_left'></div>
                                    Reports</NavLink>
                            </li> */}
                        </ul>
                    </div>
                    <div className="windmill-logo">
                        <h4>Windmills <span style={{color: 'white', fontSize: 12}}>
                            {`v${meta.version}`}
                        </span>
                        </h4>
                    </div>
                </div>
            </div>
        </>

    )
}

// NavbarSidebarGlobal.propTypes = {
//     children: PropTypes.element.isRequired,
// };

export default GlobalSidebar;
