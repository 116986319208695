import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import _ from "lodash";
import { fetchContentByCode } from '../../../util/Utility';


export const StartersInformation = (props) => {

    const [errors, setError] = useState({});
    const [stationOptions, setStationOptions] = useState(null)
    const [robotStations, setRobotStations] = useState(null)

    useEffect(() => {
    }, [props.menuInfo]);

    useEffect(() => {

        fetchContentByCode('station-options', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                setStationOptions(content?.params)
            }
        });
        fetchContentByCode('robot-stations', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                setRobotStations(content?.params)
            }
        });
    }, [])

    const handleValidation = (groupMenu) => {
        let isValid = true;
        let newErrors = {};
        if (!groupMenu.name) {
            isValid = false;
            newErrors['name'] = 'Please enter category name';
        } if (!groupMenu.position) {
            isValid = false;
            newErrors["position"] = 'please enter position'
        } if (!groupMenu.pos_id) {
            isValid = false;
            newErrors["pos_id"] = 'please enter pos id of group menu'
        } if (!groupMenu.is_order_delivery_shown) {
            isValid = false;
            newErrors["is_order_delivery_shown"] = 'please select delivery option of group menu'
        }
        if (!groupMenu.station_id) {
            isValid = false;
            newErrors["station_id"] = 'please select station id of group menu'
        } if (!groupMenu.robot_station_id) {
            isValid = false;
            newErrors["robot_station_id"] = 'please select robot station id of group menu'
        }
        // if (!groupMenu.description) {
        //     isValid = false;
        //     newErrors["description"] = 'please enter description of group menu'
        // }

        return {
            isValid,
            errors: newErrors
        }

    }

    const handleNextInfo = () => {
        const check = handleValidation(props.menuInfo);
        let newCategoryMenuInfo = { ...props.menuInfo }
        if (check.isValid) {
            let UpdateGroupMenu = {
                menuInfo: newCategoryMenuInfo
            }
            props.setSegment("Screen details");

        } else {
            setError(check.errors)
        }
    }

    return (
        <>
            <div className="col-md-6">
                <Form className="menu-information">
                    <Form.Group className="input_bottom" controlId="formBasicEmail">
                        <Form.Label>Tile Name <i class="fa fa-asterisk" aria-hidden="true"></i></Form.Label>
                        <Form.Control type="text" placeholder="Name"
                            value={props.menuInfo?.name}
                            onChange={(e) => props.handleChangeCategoryMenu("name", e.target.value)}
                        />
                    </Form.Group>
                    {!props.menuInfo.name ? errors.name ? <p className="add-employee-validation">{errors.name}</p> : null : null}
                    <Form.Group className="events-mt" controlId="formBasicEmail">
                        <Form.Label>Position in list <i class="fa fa-asterisk" aria-hidden="true"></i></Form.Label>
                        <Form.Select className="select-event menu-select" width="100%"
                                 value={props.menuInfo?.position}
                                 onChange={(e) => props.handleChangeCategoryMenu("position", e.target.value)}
                        >
                        {props.listPosition?.map(item => (
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))}
                        </Form.Select>
                    
                    </Form.Group>
                    {!props.menuInfo.position ? errors.position ? <p className="add-employee-validation">{errors.position}</p> : null : null}
                    <Form.Group className="events-mt" controlId="formBasicEmail">
                        <Form.Label>POS Id <i class="fa fa-asterisk" aria-hidden="true"></i></Form.Label>
                        <Form.Control type="text" placeholder="Small Price POS Id"
                            value={props.menuInfo?.pos_id}
                            onChange={(e) => props.handleChangeCategoryMenu("pos_id", e.target.value)}
                        />
                    </Form.Group>
                    {!props.menuInfo.pos_id ? errors.pos_id ? <p className="add-employee-validation">{errors.pos_id}</p> : null : null}
                    <Form.Label className='events-mt'>Station <i class="fa fa-asterisk" aria-hidden="true"></i></Form.Label>
                    <Form.Select aria-label="Super Admin" className="select-event menu-select input_bottom" width="100%"
                        value={props.menuInfo?.station_id}
                        onChange={(e) => props.handleChangeCategoryMenu("station_id", e.target.value)}
                    >
                        {stationOptions?.map(item => (
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))}
                    </Form.Select>
                    {!props.menuInfo.station_id ? errors.station_id ? <p className="add-employee-validation">{errors.station_id}</p> : null : null}
                    <Form.Label className='events-mt'>Robot Station <i class="fa fa-asterisk" aria-hidden="true"></i></Form.Label>
                    <Form.Select aria-label="Super Admin" className="select-event menu-select input_bottom" width="100%"
                        value={props.menuInfo?.robot_station_id}
                        onChange={(e) => props.handleChangeCategoryMenu("robot_station_id", e.target.value)}
                    >
                        {robotStations?.map(item => (
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))}
                    </Form.Select>
                    {!props.menuInfo.robot_station_id ? errors.robot_station_id ? <p className="add-employee-validation">{errors.robot_station_id}</p> : null : null}
                </Form>
            </div>
            <div className="col-md-6">
                <Form className="menu-information">
                    <Form.Group className="input_bottom" controlId="formBasicEmail">
                        <Form.Label>Header Name <i class="fa fa-asterisk" aria-hidden="true"></i></Form.Label>
                        <Form.Control type="text" placeholder="Label Name"
                                      value={props.menuInfo?.label_name}
                                      onChange={(e) => props.handleChangeCategoryMenu("label_name", e.target.value)}
                        />
                    </Form.Group>
                    {!props.menuInfo.label_name ? errors.label_name ? <p className="add-employee-validation">{errors.label_name}</p> : null : null}
                    <Form.Group className="input_bottom" controlId="formBasicEmail">
                        <Form.Label>Description </Form.Label>
                        <Form.Control type="text" placeholder="Description"
                            value={props.menuInfo?.description}
                            onChange={(e) => props.handleChangeCategoryMenu("description", e.target.value)}
                        />
                    </Form.Group>
                    {!props.menuInfo.description ? errors.description ? <p className="add-employee-validation">{errors.description}</p> : null : null}
                    <Form.Label className='events-mt'>Show order delivery option <i class="fa fa-asterisk" aria-hidden="true"></i></Form.Label>
                    <Form.Select aria-label="Super Admin" className="select-event menu-select input_bottom" width="100%"
                        value={props.menuInfo?.is_order_delivery_shown}
                        onChange={(e) => props.handleChangeCategoryMenu("is_order_delivery_shown", e.target.value)}
                    >
                        <option>None</option>
                        <option value={true}>True</option>
                        <option value={false}>False</option>
                    </Form.Select>
                    {!props.menuInfo.is_order_delivery_shown ? errors.is_order_delivery_shown ? <p className="add-employee-validation">{errors.is_order_delivery_shown}</p> : null : null}
                </Form>

            </div>
            <div className="d-flex justify-content-between events-mt">
                <button className='cancel_but' onClick={() => props.closeMenuInformationModal()}>Cancel</button>
                <button className='save_but' onClick={() => handleNextInfo()}>Next</button>
            </div>
        </>
    )
}
