import React from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Pencil from "../../../assets/pencil.svg";
import TableFooter from "../../../hoc/TableFooter";
import callApi from "../../../util/apiCaller";
import {errorMsg, successMsg} from "../../../util/toast";

const DocumentTable = ({
                       pageSize,
                       pageNum,
                       event_count,
                       pages,
                       handlePageSizeChange,
                       handlePageNumChange,
                       eventList,
                       fetchDocuments
                   }) => {

    const handleDelete = (id) => {
        callApi(`document/${id}/remove`, "post")
        .then(res => {
            if(res.status === "Success"){
                successMsg("Document successfully deleted...!!!")
                fetchDocuments()
            }
            else {
                errorMsg("Something went wrong...!!!")
            }
        })
        .catch(err => console.log(err))
    }

    return (
        <>
            <div className="">
            <TableFooter
                   isPageVisible={false}
                    pages={pages}
                    pageSize={pageSize}
                    count={event_count}
                    pageNum={pageNum}
                    handlePageNumChange={handlePageNumChange}
                    handlePageSizeChange={handlePageSizeChange}
                >
                </TableFooter>
                <div style={{ marginRight: "14px" }}>
                            <table class="table table-borderless employee-table">
                                <thead>
                                    <tr className="table-header">
                                        <th scope="col">Short Id</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Link</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {eventList.map((item, index) => (
                                        <tr className="table-border-bottom table-body">
                                            <td style={{ width: "26%" }}>{item.short_id}</td>
                                            <th scope="row">{item.name.toString().substring(0, 20)}{'...'}</th>
                                            <td>
                                                <a target={"_blank"} href={item.url}>{item.url.toString().substring(0, 20)}{'...'}</a>
                                            </td>
                                            <td>
                                                <CopyToClipboard text={item.url}
                                                                 onCopy={() => successMsg("Copied")}>
                                                    <span style={{cursor : "pointer"}}>Copy Link</span>
                                                </CopyToClipboard>
                                                <br />
                                                <button className="delete-doc" onClick={() => handleDelete(item._id)}>
                                                    Delete Document
                                                </button>
                                            </td>
                                            
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                 <TableFooter
                    isCounterVisible={false}
                    pages={pages}
                    pageSize={pageSize}
                    count={event_count}
                    pageNum={pageNum}
                    handlePageNumChange={handlePageNumChange}
                    handlePageSizeChange={handlePageSizeChange}
                >
                </TableFooter>
            </div>

        </>
    )
}

export default DocumentTable;
