import {Button, Modal, ModalBody} from "react-bootstrap";
import React, {Component, useEffect, useState} from 'react';

import DefaultModal from "../../../hoc/DefaultModal/DefaultModal"
import Form from 'react-bootstrap/Form';
import LeftIcon from "../../../assets/left-arrow.svg";
import {Link, useParams} from "react-router-dom";
import RightIcon from "../../../assets/right-arrow.svg";
import callApi from "../../../util/apiCaller";
import {getSelectedRestaurant} from "../../../redux/reducers/AppReducer";
import options from "../../../content/ScreenPosition"
import {useSelector} from 'react-redux';

// import DeleteImgModal from "../../../assets/delete-img.png";
// import Pencil from "../../../assets/pencil.svg";
// import callApi from "../../../util/apiCaller";


const DishMenu = ({completeDishList, selectedDishList, handleSelection, handleUnSelection, selectedDishObjectMap}) => {

    const restaurant = useSelector(state => getSelectedRestaurant(state));
    const {group_menu_id, category_menu_id} = useParams();
    const [filterData, setFilterData] = useState({
        qtext: '',
        pageNum: 1,
        pageSize: 50,
    });

    const [queryText, setQueryText] = useState("");

    const [dish_list, setDish] = useState([]);

    const fetchDishList = (restaurant_id, inputFilters) => {
        let filters = {};
        filters.restaurant_id = restaurant_id;
        if (inputFilters && inputFilters.qtext) {
            filters.qtext = inputFilters.qtext;
        }
        callApi(`dish/list`, 'post', {
            filters,

        }).then(res => {
            if (res?.status === "Success") {
                console.log("dish menu search")
                setDish(res?.data?.list);
            } else {
                console.log('err', res?.data?.message);
            }
        });
    };

    const searchForDish = (searchText) => {
        let searchFilters = {...filterData};
        searchFilters.qtext = searchText;
        setFilterData(searchFilters);
        fetchDishList(restaurant?._id, searchFilters);
    };

    const checkIfMatches = (query, code) => {
        if (query !== '') {
            let regExp = new RegExp(query, 'i');
            let ans = regExp.test(code);
            console.log(ans);
            return ans;
        } else {
            return true;
        }
    };

    return (
        <div className="container-fluid">
            <div className="d-flex">
                <div className="col-md-6">
                    <div className="dish-section">
                        <div className="dish-list-bg">
                            <p>Dish list</p>
                        </div>
                        <div className="list-card-bg-color">
                            <div className="position-relative">
                                <i className="fa fa-search fa_search" aria-hidden="true"></i>
                                <input
                                    // value={undefined}
                                    // onChange={(e) => this.handleSearch(e)}
                                    className="form-control dish-search-box me-2"
                                    placeholder="Search by Dish"
                                    onChange={(e) => searchForDish(e.target.value)}
                                    aria-label="Search"
                                />

                            </div>
                            {dish_list.length > 0 ?

                                dish_list.filter(item => !selectedDishObjectMap[item._id]).map(item => (
                                    <div
                                        onClick={() => handleSelection(item)}
                                        className="dish-list-item-bg">
                                        <p>{item.name}</p>
                                        <img src={RightIcon} alt="Left-Icon"/>
                                    </div>
                                ))
                                :
                                completeDishList && completeDishList.filter(item => !selectedDishObjectMap[item._id]).map(item => (
                                    <div
                                        onClick={() => handleSelection(item)}
                                        className="dish-list-item-bg">
                                        <p>{item.name}</p>
                                        <img src={RightIcon} alt="Left-Icon"/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="dish-list-bg">
                        <p>Selected Dish</p>
                    </div>
                    <div className="list-card-bg-color">
                        <div className="position-relative">
                            <i className="fa fa-search fa_search" aria-hidden="true"></i>
                            <input
                                value={queryText}
                                onChange={(e) => setQueryText(e.target.value)}
                                className="form-control dish-search-box me-2"
                                placeholder="Search by Dish"
                                aria-label="Search"
                            />
                        </div>
                        {selectedDishList && selectedDishList.filter(item => checkIfMatches(queryText, item.name)).map(item => (
                            <div
                                onClick={() => handleUnSelection(item)}
                                className="dish-list-item-bg">
                                <p>{item.name}</p>
                                <img src={LeftIcon} alt="Left-Icon"/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="text-center done-btn">
                <Link className="save_but" to={`/admin/menu/${group_menu_id}/category/${category_menu_id}`}>Done</Link>
                {/* <Link className="cancel_but"
                      to={`/admin/menu/${group_menu_id}/category/${category_menu_id}`}>Cancel</Link>
                <Link  to={`/admin/menu/${group_menu_id}/category/${category_menu_id}`}>Save</Link> */}
            </div>

        </div>
    )

}
export default DishMenu;
