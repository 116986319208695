// import MenuLogo from "../../assets/menu-logo.png";

import {Button, Modal, ModalBody} from "react-bootstrap";

import Breadcrumbs from "../../../hoc/Breadcrumbs";
import {Link} from "react-router-dom";
import React from "react";

const StarterBreadCrumbs = ({query, searchForCategoryDishes, menuCategory, groupMenu}, props) => {

    const links = [
        {
            name: 'Menus',
            url: '/admin/menu'
        },
        {
            name: `${groupMenu?.name}`,
            url: `/admin/menu/${groupMenu?._id}`
        },
        {
            name: `${menuCategory?.name}`,
            url: `/admin/menu/${groupMenu?._id}/category/${menuCategory?._id}`
        }
    ]
    return (
        <div className="breadcrumb_bg">
            <Breadcrumbs
                links={links}
            />
            {/* <div className="position-relative col-md-6">
                <i className="fa fa-search fa_search" aria-hidden="true"></i>
                <input
                    value={query}
                    onChange={(e) => searchForCategoryDishes(e.target.value)}
                    className="form-control input-search-box me-2"
                    placeholder="Search by Dishes, Rasoi name"
                    aria-label="Search"
                />
            </div> */}
        </div>
    )
}

export default StarterBreadCrumbs;