import React, {useEffect, useState} from "react";
import UserImg from "../../../assets/employee-img.png";
import SuperAdminImg from "../../../assets/super-admin.svg";
import callApi from "../../../util/apiCaller";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {getRestaurantList, getSelectedRestaurantName} from "../../../redux/reducers/AppReducer";
import Breadcrumbs from '../../../hoc/Breadcrumbs';
import {Button, Modal, ListGroup} from "react-bootstrap";
import styled from "styled-components"
import { errorMsg, successMsg } from "../../../util/toast";

const StyledDropdown = styled.div`
  .down_icon_sidebar img {
    transform: rotate(${(props) => props.rotate});
    transition: transform 0.5s;
  }

  .type-dropdown {
    background-color: white;
    justify-content:space-between;
    border : 1px solid rgba(196, 107, 72, 0.2);
    padding: 0.375rem 0.75rem;
    border-radius : 8px;
  }

  .dropdown-list {
    max-height: ${(props) => props.height};
    margin-top : 10px;
    overflow: scroll;
    transition: max-height 0.5s;
    text-align: center;
  }

  .dropdown-list::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

const ViewEmployee = () => {

    const [employeeProfile, setEmployeeProfile] = useState({});
    const [business, setEmployeeBusiness] = useState({});
    const {employee_id} = useParams();
    const [dropHeight, setDropHeight] = useState(0);
    const [arrowRotate, setArrowRotate] = useState("0deg");
    const restuarant_list = useSelector(state => getRestaurantList(state));

    useEffect(() => {
        callApi(`auth/agent/${employee_id}/profile`, 'get').then(res => {
            if (res.status === "Success") {
                setEmployeeProfile(res?.data?.detail);
                setEmployeeBusiness(res?.data?.business);
            }
        })
    }, [employee_id]);

    const handleDropClick = () => {
        setDropHeight(dropHeight == 0 ? "300px" : 0);
        setArrowRotate(arrowRotate == "0deg" ? "180deg" : "0deg");
    };

    const addDeleteRestaurant = (resIds) => {
        callApi(`agent/${employeeProfile?._id}/update`, 'post', {
            agent : {
                agent_restaurant_list : resIds
            }
        }).then(res => {
            if(res?.status === "Success"){
                successMsg("Restaurants updated!")
                setEmployeeProfile(res?.data?.agent)
            }
        }).catch(err => {
            errorMsg("Something went wrong!")
            console.log(err)
        })
    }

    const addResId = (id) => {
        let resIds = [...employeeProfile?.agent_restaurant_list]
        resIds.push(id)
        addDeleteRestaurant(resIds)
    }

    const removeResId = (id) => {
        let resIds = employeeProfile?.agent_restaurant_list.filter(resId => resId !== id)
        addDeleteRestaurant(resIds)
    }

    console.log("profile", employeeProfile);

    return (
        <>
            <div className="employee-part">
                <Breadcrumbs
                    links={[
                        {
                            url : `/admin/employees`,
                            name: 'Employees'
                        },
                        {
                            url : `/admin/employees/${employee_id}/view`,
                            name: `${employeeProfile?.contact_name}`
                        }
                    ]}
                />
                <div className="view-employee">
                    <p className="employee-head">Employee Details</p>
                    <div className="row">
                        <div className="col-md-10">
                            <div className="row employee-card">
                                <div className="col-md-4 right-border">
                                    <div className="" style={{marginLeft: "25px"}}>
                                        <img src={UserImg} className="employee-img"/>
                                        <div>
                                            <button className="employee-btn"><img src={SuperAdminImg}
                                                                                  className="super-admin-icon"/>
                                                {employeeProfile?.role}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-8 user-information">
                                    <p>Name<span
                                        style={{marginLeft: "54px"}}>: {employeeProfile.contact_name}</span>
                                    </p>
                                    <p>Restaurant<span style={{marginLeft: "10px"}}>: {getSelectedRestaurantName(restuarant_list, employeeProfile.preferred_restaurant_id)} </span></p>
                                    <p>Location<span style={{marginLeft: "33px"}}>: {business?.city}</span></p>
                                    <p>Email<span
                                        style={{marginLeft: "52px"}}>: {employeeProfile.contact_email}</span>
                                    </p>
                                    <p>Phone No<span
                                        style={{marginLeft: "23px"}}>: {employeeProfile.phone_number}</span>
                                    </p>
                                    <p>Restaurants : </p>
                                    <StyledDropdown height={dropHeight} rotate={arrowRotate}>
                                        <div onClick={handleDropClick} className="type-dropdown d-flex">
                                            <div>
                                        
                                            <p style={{fontSize : "17px", fontWeight : "normal"}} className="mb-0">Select Restaurant</p>
                                            </div>

                                            <div className="down_icon_sidebar">
                                            <img
                                                src="https://ik.imagekit.io/rzlzf40okya/smarthelio/chevron_big_down.svg"
                                                className="sidebar-link-icon"
                                            />
                                            </div>
                                        </div>
                                        <ListGroup className="dropdown-list" style={{width: '100%'}}>
                                            {restuarant_list?.map(res => {
                                            return <ListGroup.Item style={{display:"flex", justifyContent : "space-between"}}
                                                onClick={()=>{}}>
                                                {res?.name}
                                                {employeeProfile?.agent_restaurant_list?.includes(res?._id) ? 
                                                <Button onClick={() => removeResId(res?._id)}>-</Button>
                                                :
                                                <Button onClick={() => addResId(res?._id)}>+</Button>
                                                }
                                                </ListGroup.Item>
                                            })}
                                        </ListGroup>
                                    </StyledDropdown>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                    <div className="">

                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewEmployee;
