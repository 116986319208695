import React, { Component } from "react";
import Form from 'react-bootstrap/Form';

export default class PosConfigration extends React.Component {
    render() {
        return (
            <>
                <div className="employee-part add-events-section">
                    <div className="employee-section">
                        <p>POS Configration</p>
                    </div>
                    <div className="add-event-form">
                        <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                            <Form.Label>Name of the Restaurant</Form.Label>
                            <Form.Control type="text" placeholder="Enter Name of the Restaurant" className="" />
                        </Form.Group>
                        <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                            <Form.Label>Order Redirect URL</Form.Label>
                            <Form.Control type="text" placeholder="Enter Order Redirect URL" />
                        </Form.Group>
                        <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                            <Form.Label>Menu Update URL</Form.Label>
                            <Form.Control type="text" placeholder="Enter Menu Update URL" />
                        </Form.Group>
                        <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                            <Form.Label>Table Prefix</Form.Label>
                            <Form.Control type="text" placeholder="Enter Table Prefix" />
                        </Form.Group>
                        <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                            <Form.Label>Menu Group URL</Form.Label>
                            <Form.Control type="text" placeholder="Enter Menu Group URL" />
                        </Form.Group>
                        <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                            <Form.Label>Menu Items URL </Form.Label>
                            <Form.Control type="text" placeholder="Enter Menu Items URL" />
                        </Form.Group>
                        <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                            <Form.Label>Modifier Details URL</Form.Label>
                            <Form.Control type="text" placeholder="Enter Modifier Details URL" />
                        </Form.Group>
                        <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                            <Form.Label>Saving KOT URL</Form.Label>
                            <Form.Control type="text" placeholder="Enter Saving KOT URL" />
                        </Form.Group>
                        <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                            <Form.Label>Steward Details URL</Form.Label>
                            <Form.Control type="text" placeholder="Enter Steward Details URL" />
                        </Form.Group>
                        <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                            <Form.Label>Table Details URL</Form.Label>
                            <Form.Control type="text" placeholder="Enter Table Details URL" />
                        </Form.Group>
                        <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                            <Form.Label>User Id</Form.Label>
                            <Form.Control type="text" placeholder="Enter User Id" />
                        </Form.Group>
                        <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                            <Form.Label>Table Status URL</Form.Label>
                            <Form.Control type="text" placeholder="Enter Table Status URL" />
                        </Form.Group>
                        <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                            <Form.Label>Dummy Item POS Id</Form.Label>
                            <Form.Control type="text" placeholder="Enter Dummy Item POS Id" />
                        </Form.Group>
                        <div className="event-buttons">
                            <div className="cancel-btn">
                                <button>Cancel</button>
                            </div>
                            <div className="save-btn">
                                <button>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}