import React from 'react'

function EnumContainer({ record }) {
    return (
        <tr className="table-border-bottom table-body" style={{}}>
            <td>
                {record?.name}
            </td>
            <td>
                {record?.id}
            </td>
            <td>
                {record?.prices[0]?.price}
            </td>
            <td>
                {record?.prices[0]?.servingName}
            </td>
            <td>
                {record?.prices[0]?.servingSizeId}
            </td>
            <td>
                {record?.prices[1]?.price}
            </td>
            <td>
                {record?.prices[1]?.servingName}
            </td>
            <td>
                {record?.prices[1]?.servingSizeId}
            </td>
        </tr>

    )
}

export default EnumContainer