import React, {useEffect, useRef, useState} from 'react';
import Form from 'react-bootstrap/Form';
import callApi, {callUploadApi} from "../../../util/apiCaller";
import styled from "styled-components";
import FormData from 'form-data';
import {convertImageBaseUrl} from "../../../util/imageUpload";

const ImageUpload = styled.div`
  border: 1px solid #dbd9d9;
  width: auto;
  height: 168px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
  display: block;

  h6 {
    padding: 60px;
  }

  h5 {
    margin-top: 50px;
  }

  span {
    color: #606734;
  }

  img {
    border: 1px solid #dbd9d9;
    width: auto;
    height: 168px;
    border-radius: 8px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const StartersScreenDetails = (props) => {
    const [categoryMenuInfo, setcategoryMenuInfo] = useState({});
    const [imageUploaded, setImageUploaded] = useState([]);
    const [columnSpan, setColumnSpan] = useState([])
    const [screenPosition, setScreenPosition] = useState([])
    const [rowSpan, setRowSpan] = useState([])
    const [menuListPosition, setMenuListPosition] = useState([])
    const [errors, setError] = useState({});
    var imageId = useRef(0)

    const deleteFileUploadFileInput = () => {
        // native JS
        let fileInput = document.getElementById("PageUpload");
        document.getElementById("root").removeChild(fileInput);
    };

    const onFileUpload = (file) => {
        console.log("file", file);
        const formdata = new FormData();
        formdata.append("file", file);
        callUploadApi(`media-upload`, "post", formdata)
            .then(res => {
                console.log("resp", res);
                let updateEvent = {};
                let keyName =
                    document.getElementById("PageUpload").dataset.keyName;
                updateEvent[keyName] = res.data.link;
                props.handleChangeCategoryMenu("image_url", res.data.link)
                deleteFileUploadFileInput();
            });

    };
    const onFileChange = (event) => {
        // Update the state
        onFileUpload(event.target.files[0]);
    };

    const handleFormUpload = (val, id) => {
        imageId.current = id
        let input = document.createElement('input');
        input.type = "file";
        input.id = "PageUpload";
        input.style.display = 'none';
        input.dataset.keyName = val;
        input.onchange = onFileChange;
        document.getElementById('root').appendChild(input);
        setTimeout(function () {
            document.getElementById("PageUpload").click();
        }, 200);
    };

    const fetchContentByCode = (code, next) => {
        callApi(`content/code/${code}`, 'get').then(res => {
            if (res.status === "Success") {
                return next(null, res.data.content);
            }
        }).catch(err => {
            console.log(err)
            return next(err);
        })
    }


    useEffect(() => {
        fetchContentByCode('menu-row-span', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                setRowSpan(content?.params)
            }
        });

        fetchContentByCode('menu-column-span', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                setColumnSpan(content?.params)
            }
        });
        fetchContentByCode('menu-screen-position', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                setScreenPosition(content?.params)
            }
        });
        fetchContentByCode('menu-list-position', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                setMenuListPosition(content?.params)
            }
        });
    }, [])

    useEffect(() => {
        setcategoryMenuInfo(props.menuInfo)
    }, [props.menuInfo]);

    const removeImage = () => {
        props.handleChangeCategoryMenu("image_url", "")
    };

    return (
        <>
            <div className="col-md-6">
                <Form>
                    <Form.Label className="">Tile Position</Form.Label>
                    <Form.Select
                        aria-label="Super Admin"
                        className="select-event menu-select input_bottom"
                        width="100%"
                        value={categoryMenuInfo.tile_position}
                        onChange={(e) => props.handleChangeCategoryMenu("tile_position", e.target.value)}
                    >
                        {
                            screenPosition?.map(item => {
                                return (
                                    <option key={item.value} value={item.value}>{item.label}</option>
                                )
                            })
                        }
                    </Form.Select>
                    <Form.Label className="events-mt">Row span of tile</Form.Label>
                    <Form.Select
                        aria-label="Super Admin"
                        className="select-event menu-select input_bottom"
                        width="100%"
                        value={categoryMenuInfo.row_span_tile}
                        onChange={(e) => props.handleChangeCategoryMenu("row_span_tile", e.target.value)}
                    >
                        {
                            rowSpan?.map(item => {
                                return (
                                    <option key={item.value} value={item.value}>{item.label}</option>
                                )
                            })
                        }
                    </Form.Select>
                    <h5 className="menu-information-image-head mt-4">
                        {props.dishId ? "Update Image" : "Tile Image Upload"}
                    </h5>
                    <div className="image_upload_box">
                        <div
                            className="d-flex justify-content-between align-items-center"
                            style={{padding: "20px"}}
                        >
                            {categoryMenuInfo?.image_url ? (
                                <div className="up_box">
                                    <img src={convertImageBaseUrl(categoryMenuInfo?.image_url)} id="output"/>
                                    <div onClick={() => removeImage()}>
                                        <img
                                            src="https://d3eavn3p0apacm.cloudfront.net/windmill-images/close_ic.png"
                                            className="img_position"
                                        />
                                    </div>
                                </div>
                            ) : null}

                            <div
                                style={{
                                    margin: "0 auto",
                                    border: "1px solid rgba(50, 50, 50, 0.2)",
                                    height: "116px",
                                    borderRadius: "15px",
                                    padding: "40px 20px",
                                }}
                            >
                                {categoryMenuInfo?.image_url ? (
                                    <h6 onClick={() => handleFormUpload("image_url", 1)}>
                                        change image
                                    </h6>
                                ) : (
                                    <h6 style={{color: "#d7d7d7"}}>
                                        Upload Group Menu Image{" "}
                                        <span
                                            onClick={() => handleFormUpload("image_url", 1)}
                                            style={{color: "#202738", cursor: "pointer"}}
                                        >
                                            Browse <i class="fa fa-asterisk" aria-hidden="true"></i>
                                        </span>
                                    </h6>
                                )}
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
            <div className="col-md-6">
                <Form>
                    <Form.Label className="">Screen Position</Form.Label>
                    <Form.Select
                        aria-label="Super Admin"
                        className="select-event menu-select input_bottom"
                        width="100%"
                        value={categoryMenuInfo.screen_position}
                        onChange={(e) => props.handleChangeCategoryMenu("screen_position", e.target.value)}
                    >
                        {/* <option value=""></option> */}
                        {
                            menuListPosition?.map(item => {
                                return (
                                    <option key={item.value} value={item.value}>{item.label}</option>
                                )
                            })
                        }
                    </Form.Select>

                    <Form.Label className="events-mt">Column span of tile</Form.Label>
                    <Form.Select
                        aria-label="Super Admin"
                        className="select-event menu-select input_bottom"
                        width="100%"
                        value={categoryMenuInfo.column_span_tile}
                        onChange={(e) => props.handleChangeCategoryMenu("column_span_tile", e.target.value)}
                    >
                        {
                            columnSpan?.map(item => {
                                return (
                                    <option key={item.value} value={item.value}>{item.label}</option>
                                )
                            })
                        }
                    </Form.Select>
                </Form>
            </div>
            <div className="col-md-6">
                <Form>
                    <Form.Label className="events-mt">Category Level Tags</Form.Label>
                    <Form.Control
                        className="select-event menu-select" width="100%"
                        value={props.menuTagText}
                        onChange={(e) => props.handleChangeCategoryMenu("menuTagText", e.target.value)}
                        placeholder="Please enter tags with comma separated"
                    >
                    </Form.Control>
                </Form>
            </div>
            <div className="d-flex justify-content-between">
                <button className='cancel_but' onClick={() => props.setSegment("Information")}>prev</button>
                <button className='save_but' onClick={() => props.setSegment("Operation Time")}>Next</button>
            </div>
        </>
    )
}

export default StartersScreenDetails;
