import React, {useEffect, useState} from 'react'

import TableRecord from "./TableComponents/TableRecord"
import callApi from '../../util/apiCaller';
import {errorMsg} from '../../util/toast';
import {getSelectedRestaurant} from "../../redux/reducers/AppReducer.js"
import {useSelector} from 'react-redux';
import RestaurantHeader from "../RestaurantListPage/Components/RestaurantHeader";

function DigitoryMenuList() {

    const [tableRecords, setTableRecords] = useState([])
    const [originalTable, setOriginalTableList] = useState([])
    const selected_restaurant = useSelector(state => getSelectedRestaurant(state))

    const fetchContents = () => {
        if (selected_restaurant && selected_restaurant.digitory_store_id) {
            callApi(`seating_table/list`, "post", {
                filters : {
                    restaurant_id : selected_restaurant._id
                }
            })
                .then(res => {
                    setOriginalTableList(res?.data?.list);
                    callApi(`customer/digitory/get-store-information?store_id=${selected_restaurant.digitory_store_id}&table_id=${res?.data?.list[0].digitory_table_id}`, "get")
                        .then(res => {
                            console.log("res_digi", res);
                            setTableRecords(res?.data?.menu_items);
                        }).catch(err => {
                        // console.log(err)
                        errorMsg("Something went wrong!")
                    })
                }).catch(err => {
                // console.log(err)
                errorMsg("Something went wrong!")
            })
        }
    }

    useEffect(() => {
        fetchContents()
    }, [selected_restaurant])

    const rowHeaders = [
        "Name",
        "Pos Id",
        "Price 1",
        "Price 1 Name",
        "Price 1 Size Id",
        "Price 2",
        "Price 2 Name",
        "Price 2 Size Id",
    ]

    return (
        <div style={{padding: "10px", width: "75%", float: "right"}}>
            <RestaurantHeader
                links={[{
                    text: 'Sections / Tables',
                    link: ''
                }]}
            />

            <h3>Tables</h3>
            <br/>
            <table style={{width: '100%'}}>
                <thead>
                <tr>
                    {rowHeaders?.map(header => <th style={{textAlign: "center"}}>{header}</th>)}
                </tr>
                </thead>

                {/* Slot container */}
                <tbody>
                {tableRecords?.length > 0 ?
                    tableRecords.map(record => {
                        return <>
                            <TableRecord
                                record={record}
                            />
                        </>
                    })
                    :
                    null
                }
                </tbody>
            </table>
        </div>
    )
}

export default DigitoryMenuList