import React, {Component} from "react";

import Configuration from "./Configuration";
import PosConfiguration from "./PosConfiguration";
import RestaurantHeader from "../../RestaurantListPage/Components/RestaurantHeader";

export default class RestaurantTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSegment: "Configuration",
        };
    }

  
    setSegment = (selectedSegment = "") => {
        this.setState({
            selectedSegment: selectedSegment
        });
    };


    render() {
        console.log("kn,nscijaskcna,knaslaskknd", this.state)

        return (
            <>
                <RestaurantHeader
                    links={[{
                        text: 'Configuration',
                        link: ''
                    }]}
                />
                <div style={{padding: "50px 60px 50px 60px"}}>
                    <ul className="d-flex tab-config">
                        <li className="information col-6"
                            style={{fontWeight: this.state.selectedSegment === "Configuration" ? '600' : '400'}}
                            onClick={() => this.setSegment("Configuration")}>Configuration
                        </li>
                        {/*<li className="pos-tab col-6"*/}
                        {/*    style={{fontWeight: this.state.selectedSegment === "PosConfiguration" ? '600' : '400',}}*/}
                        {/*    onClick={() => this.setSegment("PosConfiguration")}>POS Configuration*/}
                        {/*</li>*/}

                    </ul>
                </div>
                <div className="row">
                    {this.state.selectedSegment === "Configuration" ?
                        <Configuration
                           
                        />
                        :
                        null
                    }
                    {/*{this.state.selectedSegment === "PosConfiguration" ?*/}
                    {/*    <PosConfiguration*/}
                    {/*        */}

                    {/*    />*/}
                    {/*    :*/}
                    {/*    null*/}
                    {/*}*/}
                   
                    
                </div>
            </>
        )
    }
}
