import React, {Component, useEffect, useState} from 'react';

import Form from 'react-bootstrap/Form';
import options from "../../../content/ScreenPosition"

const OperationTime = (props) => {
    const [menuInfoNew, setmenuInfoNew] = useState({});



    useEffect(() => {
        setmenuInfoNew(props.menuInfo);
    }, [props.menuInfo]);

    return (
        <>
            <div className="col-md-6">
                <Form className="menu-information">
                    <Form.Label className="events-mt">Position in list </Form.Label>
                    <Form.Select className="select-event menu-select" width="100%"
                                 value={menuInfoNew.position}
                                 onChange={(e) => props.handleChangeGroupMenu("position", e.target.value)}
                    >
                        {props.menuListPosition?.map(item => (
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))}
                    </Form.Select>
                    <Form.Label className="events-mt">Row span of tile </Form.Label>
                    <Form.Select className="select-event menu-select" width="100%"
                                 value={menuInfoNew.row_span_tile}
                                 onChange={(e) => props.handleChangeGroupMenu("row_span_tile", e.target.value)}
                    >
                        {props.menuRowSpan?.map(item => (
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))}
                    </Form.Select>
                    <Form.Label className="events-mt">Column span of tile </Form.Label>
                    <Form.Select className="select-event menu-select" width="100%"
                                 value={menuInfoNew.column_span_tile}
                                 onChange={(e) => props.handleChangeGroupMenu("column_span_tile", e.target.value)}

                    >
                        {props.menuColumnSpan?.map(item => (
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))}
                    </Form.Select>
                </Form>
            </div>
            <div className="col-md-6">
                <Form>
                    <Form.Label className="events-mt">Screen Position</Form.Label>
                    <Form.Select
                        className="select-event menu-select" width="100%"
                        value={menuInfoNew.screen_position}
                        onChange={(e) => props.handleChangeGroupMenu("screen_position", e.target.value)}
                        placeholder="Please enter tile position"
                    >
                        <option value="">Not Selected</option>
                        {props.menuListPosition?.map(item => (
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))}
                    </Form.Select>
                    <Form.Label className="events-mt">Tile Position </Form.Label>
                    <Form.Select aria-label="Super Admin" className="select-event menu-select" width="100%"
                                 value={menuInfoNew.tile_position}
                                 onChange={(e) => props.handleChangeGroupMenu("tile_position", e.target.value)}
                                 defaultValue="select screen position"
                    >
                        <option value="">Not Selected</option>
                        {props.menuScreenPosition?.map(item => (
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))}
                    </Form.Select>
                </Form>
            </div>
            <div className="col-md-6">
                <Form>
                    <Form.Label className="events-mt">Menu Level Tags</Form.Label>
                    <Form.Control
                        className="select-event menu-select" width="100%"
                        value={props.menuTagText}
                        onChange={(e) => props.handleChangeGroupMenu("menuTagText", e.target.value)}
                        placeholder="Please enter tags with comma separated"
                    >
                    </Form.Control>
                </Form>
            </div>
            <div className='d-flex justify-content-between'>
                <button onClick={() => props.setSegment("Information")} className='information-btn-cancel'>Prev</button>
                <button onClick={() => props.setSegment("Operation Time")} className='information-btn-save'>Next
                </button>
            </div>
        </>
    )

}

export default OperationTime;