import {APP_TOKEN} from "../../constants/enums/appEnums";
import callApi from "../../util/apiCaller";

export const SET_KITCHEN_ORDERS = 'SET_KITCHEN_ORDERS';
export const SET_KITCHEN_TABLES = 'SET_KITCHEN_TABLES';
export const UPDATE_KITCHEN_ORDER = 'UPDATE_KITCHEN_ORDER';

export function fetchKitchenTables(restaurant_id) {
    return async dispatch => {
        try {
            const res = await callApi(`seating_table/list`, 'post', {
                filters: {
                    restaurant_id
                }
            });
            if (res && res.status === 'Success') {
                const { list } = res.data;
                dispatch({
                    type: SET_KITCHEN_TABLES,
                    tables: list
                })
            }
        } catch (err) {
            console.log(err)
        }
    }
}

export function fetchKitchenOrders(restaurant_id) {
    return async dispatch => {
        try {
            const res = await callApi(`captain/open-order-list?restaurant_id=${restaurant_id}`, 'post');
            if (res && res.status === 'Success') {
                const { list } = res.data;
                dispatch({
                    type: SET_KITCHEN_ORDERS,
                    orders: list
                })
            }
        } catch (err) {
            console.log(err)
        }
    }
}

export function changeOrderItemStatusChange(order_id, item_id, orderItem) {
    return async dispatch => {
        dispatch({
            type: UPDATE_KITCHEN_ORDER,
            is_updating_order: true
        })
        try {
            const res = await callApi(`captain/order/${order_id}/item/${item_id}/update-status`, 'post', {
                orderItem
            });
            dispatch({
                type: UPDATE_KITCHEN_ORDER,
                is_updating_order: false
            })
        } catch (err) {
            console.log(err)
            dispatch({
                type: UPDATE_KITCHEN_ORDER,
                is_updating_order: false
            })
        }
    }
}

export function markOrderComplete(order_id) {
    return async dispatch => {
        dispatch({
            type: UPDATE_KITCHEN_ORDER,
            is_updating_order: true
        })
        try {
            const res = await callApi(`captain/order/${order_id}/mark-complete`, 'post', {});
            dispatch({
                type: UPDATE_KITCHEN_ORDER,
                is_updating_order: false
            })
        } catch (err) {
            console.log(err)
            dispatch({
                type: UPDATE_KITCHEN_ORDER,
                is_updating_order: false
            })
        }
    }
}
