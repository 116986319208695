//import Action

import {
    SET_AGENT,
    SET_BUSINESS,
    SET_RESTAURANT,
    SET_RESTAURANT_LIST
} from '../actions/AppActions';

const initialState = {
    isAuthenticated: false,
    agent: {},
    isLoading: false,
    message: "",
    _refurl: "",
    messageType: "",
    business: {},
    restaurant_list: [],
    selected_restaurant: {}
}

const AppReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_RESTAURANT_LIST:
            return {
                ...state,
                restaurant_list: action.restaurant_list,
            }

        case SET_RESTAURANT:
            return {
                ...state,
                selected_restaurant: action.selected_restaurant,
            }

        case SET_AGENT:
            return {
                ...state,
                agent: action.agent,
            }

        case SET_BUSINESS:
            return {
                ...state,
                business: action.business,
            }

        default:
            return state;
    }
}


/* Selectors */
//login
export const getAuthenticatedState = state => state.app.isAuthenticated;
export const getPasswordProcessingState = state => state.app.isFetching;
export const getLoginMessage = state => state.app.message;
export const getLoginMessagetype = state => state.app.messagetype;
export const getRefUrl = state => state.app._refurl;
export const getAgent = state => state.app.agent;
export const getRestaurantList = state => state.app.restaurant_list;
export const getSelectedRestaurant = state => state.app.selected_restaurant;
export const getBusiness = state => state.app.business;
export const getSelectedRestaurantName = (restuarant_list, selectedRestaurantId) => {
    return restuarant_list?.filter(item => item._id === selectedRestaurantId)[0]?.name;
}


//Export Reducer
export default AppReducer;
