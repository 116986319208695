// import { Button, Modal, ModalBody } from "react-bootstrap";

import React, { Components, useEffect, useState } from "react";
import { getAgent, getSelectedRestaurant } from "../../redux/reducers/AppReducer";
import { useLocation, useParams } from "react-router-dom";

import CategoryBreadCrumbs from "./Components/CategoryBreadCrumbs";
import CategoryHeader from "./Components/CategoryHeader";
import CategoryTable from "./Components/CategoryTable";
import { Helmet } from "react-helmet";
import callApi from "../../util/apiCaller";
import { useSelector } from "react-redux";
import { fetchContentByCode } from "../../util/Utility";

const MenuCategory = (props) => {
    const restaurant = useSelector(state => getSelectedRestaurant(state));
    const agent = useSelector(state => getAgent(state));
    const { group_menu_id } = useParams();
    const { category_menu_id } = useParams();
    const [menuCategory, setMenuCategroy] = useState([]);
    const [groupMenu, setGroupMenu] = useState("");
    const [menu_category_count, setmenucategoryCount] = useState(0);
    const [listPosition, setListPosition] = useState(null)
    const [availabilityRules, setAvailabilityRules] = useState(null)

    useEffect(() => {
        fetchContentByCode('menu-list-position', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                setListPosition(content?.params)
            }
        });
        fetchContentByCode('availability-rule', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                setAvailabilityRules(content?.params)
            }
        });
    }, [])

    useEffect(() => {
        getGroupMenu(group_menu_id)
    }, [group_menu_id]);

    const getGroupMenu = (id) => {
        callApi(`group_menu/${id}`, 'get').then(res => {
            if (res?.status === "Success") {
                setGroupMenu(res.data.group_menu);
            }
        });
    };

    const fetchCategoryList = (restaurant_id, inputFilters) => {
        let filters = {};
        filters.group_menu_id = group_menu_id;
        if (inputFilters && inputFilters.qtext) {
            filters.qtext = inputFilters.qtext;
        }
        callApi(`menu_category/list`, 'post', {
            filters,
            pageNum: inputFilters?.pageNum,
            pageSize: inputFilters?.pageSize,
        }).then(res => {
            if (res?.status === "Success") {
                setMenuCategroy(res.data.list);
                setmenucategoryCount(res?.data?.count)
            } else {
                // console.log('err', res?.data?.message);
            }
        });
    };

    // Pagination code
    const [filterData, setFilterData] = useState({
        qtext: '',
        pageNum: 1,
        pageSize: 50,
    });
    useEffect(() => {
        setFilterData({
            qtext: '',
            pageNum: 1,
            pageSize: 50,
        })
        fetchCategoryList(group_menu_id.id, null);
    }, [group_menu_id]);

    const handlePageSize = (pageSize) => {
        let searchFilters = { ...filterData };
        searchFilters.pageSize = parseInt(pageSize);
        searchFilters.pageNum = 1;
        setFilterData(searchFilters);
        fetchCategoryList(restaurant?._id, searchFilters);

    };

    const handlePageNum = (pageNum) => {
        let searchFilters = { ...filterData };
        searchFilters.pageNum = parseInt(pageNum);
        setFilterData(searchFilters);
        fetchCategoryList(restaurant?._id, searchFilters);

    };
    const searchForCategory = (searchText) => {
        let searchFilters = { ...filterData };
        searchFilters.qtext = searchText;
        setFilterData(searchFilters);
        fetchCategoryList(restaurant?._id, searchFilters);
    };
    return (

        <div className="right_side_section">
            <Helmet>
                <title>Windmills | Menu | Category</title>
            </Helmet>
            <CategoryHeader
                menuId={group_menu_id}
                category_menu_id={category_menu_id}
                setMenuCategroy={setMenuCategroy}
                fetchCategoryList={fetchCategoryList}
                listPosition = {listPosition}
            />

            <CategoryBreadCrumbs
                query={filterData.qtext}
                groupMenu={groupMenu}
                groupMenuName={group_menu_id}
                searchForCategory={searchForCategory}
            />
            <CategoryTable
                menuId={group_menu_id}
                menuList={menuCategory}
                setMenuCategroy={setMenuCategroy}
                menu_category_count={menu_category_count}
                handlePageSizeChange={handlePageSize}
                handlePageNumChange={handlePageNum}
                pageSize={filterData.pageSize}
                pageNum={filterData.pageNum}
                pages={[10, 20, 50, 100]}
                fetchCategoryList={fetchCategoryList}
                listPosition = {listPosition}
                availabilityRules={availabilityRules}

            />



        </div>
    )

}

export default MenuCategory;

