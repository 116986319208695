import { Button, Modal, ModalBody } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { errorMsg, successMsg } from "../../../util/toast";

import AddDish from "./AddDish";
import Delete from "../../../assets/delete.svg";
import DeleteImgModal from "../../../assets/delete-img.png";
import { Link } from "react-router-dom"
import Pencil from "../../../assets/pencil.svg";
import TableFooter from "../../../hoc/TableFooter";
import WhiteTick from "../../../assets/white-tick.svg";
import callApi from "../../../util/apiCaller";

const DishTable = ({
    openEditDishModal,
    dish_list,
    pageSize,
    setDish,
    pageNum,
    total_dish_count,
    pages,
    handlePageSizeChange,
    handlePageNumChange
}) => {



    const [isOpen, setIsOpen] = useState(false);
    const [dishInformationModal, setDishInformationModal] = useState(false);
    const [successModalForDelete, setSuccessModalForDelete] = useState(false);
    const [dishes, setDishes] = useState([]);

    // const [totalCount, setTotalCount] = useState(100);
    // const [isLoadingItems, setItemLoader] = useState(false);
    const openDishInformationModal = () => setDishInformationModal(true);
    const closeDishInformationModal = () => {
        setDishInformationModal(false);
        setDishId(null);
    };
    const [dishId, setDishId] = useState([]);

    useEffect(() => {
        setDishes(dish_list)
    }, [dish_list]);


    const handleStatus = (id) => {
        callApi(`dish/${id}/change_status`, 'get').then(res => {
            if (res.status === "Success") {
                let newList = dishes.map(item => {
                    if (item?._id === res?.data?.detail?._id) {
                        return res.data.detail;
                    } else {
                        return item;
                    }
                })
                setDishes(newList);
                successMsg("Status Updated Successfully...!");
            } else {
                errorMsg("something went wrong");
            }
        });
    }

    const handleDeleteDishById = (dishId) => {
        callApi(`dish/${dishId}/remove`, 'post', {}).then(res => {
            if (res.status === "Success") {
                console.log("dish deleted", res);
                closeModal();
                openSuccessModalForDelete();
                successMsg("Dish Deleted Successfully...!");
            } else {
                // errorMsg("something went wrong");
            }
        });
    }

    const handleItemChange = (dishId, keyName, val) => {
        let update = {};
        update[keyName] = val;
        callApi(`dish/${dishId}/update`, 'post', {
            dish: update
        }).then(res => {
            let newList = dishes.map(item => {
                if (item?._id === res?.data?.dish?._id) {
                    return res.data.dish;
                } else {
                    return item;
                }
            })
            setDishes(newList);
            successMsg("Updated Successfully...!");
        });
    }

    const getGroupDishIdForDelete = (id) => {
        console.log("id", id);
        openModal()
        setDishId(id);
    }

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);
    const openSuccessModalForDelete = () => setSuccessModalForDelete(true);
    const closeSuccessModalForDelete = () => {
        setSuccessModalForDelete(false);
        const currentDishList = dishes;
        const deletingDishID = dishId;
        const newDishList = currentDishList.filter(dish => dish._id != deletingDishID)
        setDishes([...newDishList])
    };

    console.log("list", dishes);
    return (
        <>
            <div className="">

                <TableFooter
                    isPageVisible={false}
                    pages={pages}
                    pageSize={pageSize}
                    count={total_dish_count}
                    pageNum={pageNum}
                    handlePageNumChange={handlePageNumChange}
                    handlePageSizeChange={handlePageSizeChange}
                >
                </TableFooter>
                <div className="dish-body list-card-bg-color p-0" style={{background:"#fff"}}>
                    <table class="table">
                        <thead class="thead-light sticky-top">

                            <th scope="col">Dishes Name</th>
                            <th scope="col">Availability</th>
                            <th scope="col">Action</th>
                            <th scope="col">Edit</th>
                            <th scope="col">Delete</th>

                        </thead>

                        <tbody>
                            {dishes && dishes.map((item, index) => (
                                <tr>
                                    <td scope="row">{item.name}</td>
                                    <td data-tip="Inactive" data-class="upload-data-tip">
                                        <label class="switch">
                                            <input type="checkbox" checked={item.is_available} onClick={() => handleItemChange(item._id, 'is_available', !item.is_available)} />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                    <td data-tip="Inactive" data-class="upload-data-tip">
                                        <label class="switch">
                                            <input type="checkbox" checked={item.is_active} onClick={() => handleStatus(item._id)} />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                    <td><img src={Pencil} onClick={() => openEditDishModal(item._id)} style={{ cursor: "pointer" }} /></td>
                                    <td><img src={Delete} onClick={() => getGroupDishIdForDelete(item._id)} style={{ cursor: "pointer" }} /></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <TableFooter
                    isCounterVisible={false}
                    pages={pages}
                    pageSize={pageSize}
                    count={total_dish_count}
                    pageNum={pageNum}
                    handlePageNumChange={handlePageNumChange}
                    handlePageSizeChange={handlePageSizeChange}
                >
                </TableFooter>

            </div>
            {/* Delete Modal */}
            <div className="delete-modal">
                <Modal show={isOpen} onHide={closeModal}>
                    <Modal.Header closeButton className="border-none">

                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center delete-modal">
                            <img src={DeleteImgModal} style={{ width: "110px" }} />
                            <h3>Are you sure you want to delete this item?</h3>
                            {/* <p>Lorem ipsum, or lipsum as it is sometimes known,
                                is dummy text used in laying out print,</p> */}
                        </div>
                        <div className="d-flex justify-content-center">
                            <div>
                                <button className="delete-modal-btn"
                                    onClick={() => handleDeleteDishById(dishId)}>Delete
                                </button>
                            </div>
                            <div>
                                <button className="keep-modal-btn" onClick={closeModal}>Keep</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            {/* Success Modal for delete */}
            <div className="">
                <Modal show={successModalForDelete} onHide={closeSuccessModalForDelete} className="success-modal">
                    <Modal.Header closeButton className="border-none">

                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <img src={WhiteTick} />
                            <p>Dish deleted successfully </p>
                        </div>
                        <div className="text-center">
                            <Link to="/admin/dishes" ><button onClick={() => closeSuccessModalForDelete()} className="success-modal-btn">Go to Dishes</button></Link>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default DishTable;
