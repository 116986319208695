import React, { useEffect } from "react";

import {CopyToClipboard} from 'react-copy-to-clipboard';
import Pencil from "../../../assets/pencil.svg";
import TableFooter from "../../../hoc/TableFooter";
import callApi from "../../../util/apiCaller";
import {successMsg} from "../../../util/toast";

const DocumentTable = ({
                       pageSize,
                       pageNum,
                       event_count,
                       pages,
                       handlePageSizeChange,
                       handlePageNumChange,
                       eventList,
                       handleDelete,
                       fetchContents,
                       setModalVisiblity,
                       setSelectedItem
                   }) => {

            const handleEdit = (item) => {
                setSelectedItem(item)
                setModalVisiblity(true)
            }

    const afterDeleteMsg = () => {

        successMsg("Code deleted successfully...!!!")
        fetchContents()

    }

    return (
        <>
            <div className="">
            <TableFooter
                   isPageVisible={false}
                    pages={pages}
                    pageSize={pageSize}
                    count={event_count}
                    pageNum={pageNum}
                    handlePageNumChange={handlePageNumChange}
                    handlePageSizeChange={handlePageSizeChange}
                >
                </TableFooter>
                <div style={{ marginRight: "14px" }}>
                            <table class="table table-borderless employee-table">
                                <thead>
                                    <tr className="table-header">
                                        <th scope="col">Code</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {eventList.map((item, index) => (
                                        <tr className="table-border-bottom table-body">
                                            <td style={{ width: "26%" }}>{item.code}</td>
                                            <td>
                                                <button onClick={() => handleEdit(item)} className="edit">Edit</button>
                                                <button onClick={() => handleDelete(item._id, (res) => afterDeleteMsg())} className="delete">Delete</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                 <TableFooter
                    isCounterVisible={false}
                    pages={pages}
                    pageSize={pageSize}
                    count={event_count}
                    pageNum={pageNum}
                    handlePageNumChange={handlePageNumChange}
                    handlePageSizeChange={handlePageSizeChange}
                >
                </TableFooter>
            </div>

        </>
    )
}

export default DocumentTable;
