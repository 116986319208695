import { convertHHMMToSeconds } from "../../util/timeUtility";

const saveData = (listInfo, dailySlots, weeklySlots, monthlySlots, handleSave) => {
    let update = {...listInfo};
    if (dailySlots.length > 0) {
        let newDailySlots = dailySlots.map(item => {
            return {
                start_time: convertHHMMToSeconds(item.start_time),
                end_time: convertHHMMToSeconds(item.end_time)
            }
        })
        update.availability_daily = newDailySlots
    }

    if (weeklySlots.length > 0) {
        let newWeeklySlots = weeklySlots.map(item => {
            if (item.time_availability && item.time_availability.length > 0) {
                item.time_availability = item.time_availability.map(timeSlot => {
                    return {
                        start_time: convertHHMMToSeconds(timeSlot.start_time),
                        end_time: convertHHMMToSeconds(timeSlot.end_time),
                    }
                })
            }
            return item
        })
        update.availability_weekly = newWeeklySlots

    }

    if (monthlySlots.length > 0) {
        let newMonthlySlots = monthlySlots.map(item => {
            if (item.time_availability && item.time_availability.length > 0) {

                item.time_availability = item.time_availability.map(timeSlot => {
                    return {
                        start_time: convertHHMMToSeconds(timeSlot.start_time),
                        end_time: convertHHMMToSeconds(timeSlot.end_time),
                    }
                })
            }
            return item
        })
        update.availability_monthly = newMonthlySlots
    }

    handleSave(listInfo._id, update)
}

const handleDailySlotChange = (updateItem, start, end, day=null, dailySlots, setDailySlots) => {
    let newTimeSlots = [...dailySlots];
    newTimeSlots = newTimeSlots.map(item => {
        console.log("after update", item)
        if (updateItem._id.toString() === item._id.toString()) {
            return {
                ...item,
                start_time: start,
                end_time: end
            };
        }
        return item
    });
    setDailySlots(newTimeSlots)
}

const handleWeeklySlotChange = (updateItem, start, end, day = null, weeklySlots, setWeeklySlots) => {

    let newWeeklySlots = [...weeklySlots];
    newWeeklySlots = newWeeklySlots.map(item => {
        if (item.day === day) {
            item.time_availability = item.time_availability.map(timeSlot => {
                console.log("after update", timeSlot)
                if(timeSlot._id.toString() === updateItem._id.toString()){
                    timeSlot.start_time = start
                    timeSlot.end_time = end
                }
                return timeSlot
            })
        }
        return item;
    })
    setWeeklySlots(newWeeklySlots)
}

const handleMonthlySlotChange = (updateItem, start, end, date=null, monthlySlots, setMonthlySlots) => {
    let newMonthlySlots = [...monthlySlots];
    newMonthlySlots = newMonthlySlots.map(item => {
        if (item.day === date) {
            item.time_availability = item.time_availability.map(timeSlot => {
                if (updateItem._id.toString() === timeSlot._id.toString()) {
                    return {
                        ...timeSlot,
                        start_time: start,
                        end_time: end
                    };
                }
                return timeSlot
            })
        }
        return item;
    })
    setMonthlySlots(newMonthlySlots)
}

export {
    saveData,
    handleDailySlotChange,
    handleWeeklySlotChange,
    handleMonthlySlotChange
}