import React, { useState } from 'react'
import Brand from '../../assets/windmills-logo.svg'
import { Link } from 'react-router-dom'
import { APP_TOKEN, USER_ID } from "../../constants/enums/appEnums";
import callApi from "../../util/apiCaller";
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ResetPassword = () => {
    const query = useQuery();
    const resetToken = query.get('reset_token');

    const [user, setUserData] = useState({
        password: '',
        confirmPassword: ''
    });

    const handleChangeUser = (keyName, keyValue) => {
        let tempUser = { ...user };
        tempUser[keyName] = keyValue;
        setUserData(tempUser);
    }

    const notify = (data) => {
        toast(data)
    }

    const handleForgotPasswordAPI = () => {
        const { password } = user;
        if (!resetToken) {
            notify('No reset token is present!');
            return;
        }
        callApi(`auth/customer/reset/${resetToken}`, 'post', {
            customer: password.trim(),
        }).then(res => {
            console.log("anas", res,resetToken);
            if (res.status === 'Success') {
                notify(res.data.message);
                setTimeout(() => {
                    window.location = "/login";
                }, 1000);
            } else {
                notify(res.data.message);
            }
        }).catch(() => {
            notify('Something went wrong!');
        });
    };

    const validatePassword = () => {
        const res = {
            validated: true,
            text: '',
        }
        const { password, confirmPassword } = user;
        if (password.trim().length < 8) {
            res.validated = false;
            res.text = 'Password length must be greater than or equal to 8!';
        } else if (password.trim() !== confirmPassword.trim()) {
            res.validated = false;
            res.text = 'Password and Confirm Password do not match!';
        }
        return res;
    };

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        let validate = validatePassword();
        if (!validate.validated) {
            notify(validate.text);
            return;
        }
        handleForgotPasswordAPI();
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Reset Password | WindMills</title>
            </Helmet>
            <section className='login-section'>
                <div className="content-box">
                    <div className='login-card'>
                        <div className="form-box">
                            <div>
                                <div className='logo'>
                                    <img src={Brand} className='brand-img' alt="" />
                                </div>
                                <form className="form-pd" onSubmit={handleSubmit}>
                                    <div className="input-box">
                                        <span>New password</span>
                                        <input
                                            value={user.password}
                                            onChange={(e) => handleChangeUser("password", e.target.value)} type="password"
                                            placeholder='Enter New Password' />
                                    </div>
                                    <div className="input-box">
                                        <span>Re-enter password</span>
                                        <input
                                            value={user.confirmPassword}
                                            onChange={(e) => handleChangeUser("confirmPassword", e.target.value)} type="password"
                                            placeholder='Re-enter New password' />

                                    </div>


                                    <div className="">
                                        <input type="submit"
                                            disabled={!user.password.trim() || !user.confirmPassword.trim()} value="Reset Password" className='login-btn' />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ResetPassword;
