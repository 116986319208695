import React, {useEffect, useState} from "react";
import {getSelectedRestaurant} from "../../../redux/reducers/AppReducer";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedRestaurant} from "../../../redux/actions/AppActions";

import Form from "react-bootstrap/Form";
import callApi from "../../../util/apiCaller";
import {errorMsg, successMsg} from "../../../util/toast";
import {useNavigate} from "react-router-dom";

const Configuration = () => {

    const navigate = useNavigate()

    const selectedRestaurant = useSelector((state) => getSelectedRestaurant(state))

    const dispatch = useDispatch()
    const [restaurant, setRestaurant] = useState(null)

    useEffect(() => {
        setRestaurant(selectedRestaurant)
    }, [selectedRestaurant])

    const handleChange = (key, value) => {
        let update = {...restaurant}

        update[key] = value

        setRestaurant(update)
    }

    const handleSave = () => {
        callApi(`restaurant/${selectedRestaurant._id}/update`, 'post', {
            restaurant: restaurant
        })
            .then(res => {
                if (res?.status === 'Success') {
                    successMsg('Restaurant updated successfully!')
                    dispatch(setSelectedRestaurant(res?.data?.restaurant))
                } else if (res?.status === 'Error') {
                    errorMsg(res?.data)
                }
            })
            .catch((err) => console.log(err))
    }

    return (
        <>
            <div>
                <div className="col-lg-10" style={{margin: "0 auto"}}>
                    <Form className="menu-information">
                        <Form.Label>Organization Type</Form.Label>
                        <Form.Select
                            aria-label="Super Admin"
                            className="select-event_form menu-select input_bottom"
                            width="100%"
                            value={restaurant?.organization_type}
                            onChange={(e) => handleChange('organization_type', e.target.value)}
                        >
                            <option value='brewery'>brewery</option>
                            <option value='restaurant'>restaurant</option>
                        </Form.Select>
                        <Form.Label>Organization Type</Form.Label>
                        <Form.Select
                            aria-label="Super Admin"
                            className="select-event_form menu-select input_bottom"
                            width="100%"
                            value={restaurant?.pos_service}
                            onChange={(e) => handleChange('pos_service', e.target.value)}
                        >
                            <option value='digitory'>Digitory</option>
                            <option value='lucid'>Lucid</option>
                        </Form.Select>
                        <br/>
                        <Form.Group className="input_bottom position-relative" controlId="formBasicEmail">
                            <div className="dish_switch">
                                <label class="switch">
                                    <input type="checkbox"
                                           checked={restaurant?.hide_price_on_tile}
                                           onChange={(e) => handleChange('hide_price_on_tile', !restaurant?.hide_price_on_tile)}
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <Form.Control type="text" value="Hide Prices on tile" className="rest_input"
                                          disabled/>
                        </Form.Group>
                        <br/>
                        <Form.Group className="input_bottom position-relative" controlId="formBasicEmail">
                            <div className="dish_switch">
                                <label class="switch">
                                    <input type="checkbox"
                                           checked={restaurant?.is_menu_mode_active}
                                           onChange={(e) => handleChange('is_menu_mode_active', !restaurant?.is_menu_mode_active)}
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <Form.Control type="text" value="Menu Mode Status" className="rest_input"
                                          disabled/>
                        </Form.Group>
                        <br/>
                        <Form.Group className="input_bottom position-relative" controlId="formBasicEmail">
                            <div className="dish_switch">
                                <label class="switch">
                                    <input type="checkbox"
                                           checked={restaurant?.is_order_place_disabled}
                                           onChange={(e) => handleChange('is_order_place_disabled', !restaurant?.is_order_place_disabled)}
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <Form.Control type="text" value="Order Place Disabled" className="rest_input"
                                          disabled/>
                        </Form.Group>
                        <br/>
                        <Form.Group className="input_bottom position-relative" controlId="formBasicEmail">
                            <div className="dish_switch">
                                <label class="switch">
                                    <input type="checkbox"
                                           checked={restaurant?.is_order_flow_active}
                                           onChange={(e) => handleChange('is_order_flow_active', !restaurant?.is_order_flow_active)}
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <Form.Control type="text" value="Order Flow Active" className="rest_input"
                                          disabled/>
                        </Form.Group>
                        <br/>
                        <Form.Group className="input_bottom position-relative" controlId="formBasicEmail">
                            <div className="dish_switch">
                                <label class="switch">
                                    <input type="checkbox"
                                           checked={restaurant?.order_send_directly_to_kitchen}
                                           onChange={(e) => handleChange('order_send_directly_to_kitchen', !restaurant?.order_send_directly_to_kitchen)}
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <Form.Control
                                type="text"
                                value={"Order Send Directly to Kitchen"}
                                placeholder="Order Send Directly to Kitchen" className="rest_input"
                                disabled
                            />
                        </Form.Group>
                        <br />
                        <Form.Group className="input_bottom position-relative" controlId="formBasicEmail">
                            <div className="dish_switch">
                                <label class="switch">
                                    <input type="checkbox"
                                           checked={restaurant?.is_tile_grey_background_hidden}
                                           onChange={(e) => handleChange('is_tile_grey_background_hidden', !restaurant?.is_tile_grey_background_hidden)}
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <Form.Control
                                type="text"
                                value={"Is Menu Tile Grey Hidden"}
                                placeholder="Is Menu Tile Grey Hidden" className="rest_input"
                                disabled
                            />
                        </Form.Group>
                        <br />
                        <Form.Group className="input_bottom position-relative" controlId="formBasicEmail">
                            <div className="dish_switch">
                                <label class="switch">
                                    <input type="checkbox"
                                           checked={restaurant?.is_food_indicators_hidden}
                                           onChange={(e) => handleChange('is_food_indicators_hidden', !restaurant?.is_food_indicators_hidden)}
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <Form.Control
                                type="text"
                                value={"Is Food Indicators Hidden"}
                                placeholder="Is Food Indicators Hidden" className="rest_input"
                                disabled
                            />
                        </Form.Group>
                        <br />
                        <Form.Group className="input_bottom position-relative" controlId="formBasicEmail">
                            <div className="dish_switch">
                                <label class="switch">
                                    <input type="checkbox"
                                           checked={restaurant?.is_offline_image_sync_active}
                                           onChange={(e) => handleChange('is_offline_image_sync_active', !restaurant?.is_offline_image_sync_active)}
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <Form.Control
                                type="text"
                                value={"Is Offline Image Sync Active"}
                                placeholder="Is Offline Image Sync Active" className="rest_input"
                                disabled
                            />
                        </Form.Group>
                        <br />
                        <Form.Group className="input_bottom position-relative" controlId="formBasicEmail">
                            <div className="dish_switch">
                                <label class="switch">
                                    <input type="checkbox"
                                           checked={restaurant?.hide_help_button}
                                           onChange={(e) => handleChange('hide_help_button', !restaurant?.hide_help_button)}
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <Form.Control
                                type="text"
                                value={"Hide Help Icon"}
                                placeholder="Hide Help Icon" className="rest_input"
                                disabled
                            />
                        </Form.Group>
                        <Form.Group className="input_bottom" controlId="formBasicEmail">
                            <Form.Label>
                                Digitory POS Store Id
                            </Form.Label>
                            <Form.Control
                                value={restaurant?.digitory_store_id}
                                onChange={(e) => handleChange('digitory_store_id', e.target.value)}
                                type="text" placeholder="Name" className="rest_input"/>
                        </Form.Group>

                        <Form.Group className="input_bottom" controlId="formBasicEmail">
                            <Form.Label>
                                Digitory Pos Node Id

                            </Form.Label>
                            <Form.Control
                                value={restaurant?.digitory_node_id}
                                onChange={(e) => handleChange('digitory_node_id', e.target.value)}
                                type="text" placeholder="Name" className="rest_input"/>
                        </Form.Group>
                        <Form.Group className="input_bottom" controlId="formBasicEmail">
                            <Form.Label>
                                Digitory Pos User Id

                            </Form.Label>
                            <Form.Control
                                value={restaurant?.digitory_user_id}
                                onChange={(e) => handleChange('digitory_user_id', e.target.value)}
                                type="text" placeholder="Name" className="rest_input"/>
                        </Form.Group>

                        {/*<Form.Group className="input_bottom" controlId="formBasicEmail">*/}
                        {/*    <Form.Label>*/}
                        {/*        Order Delivery Prompt*/}

                        {/*    </Form.Label>*/}
                        {/*    <Form.Control type="text" placeholder="Name" className="rest_input"/>*/}
                        {/*</Form.Group>*/}

                        {/*<Form.Group className="input_bottom" controlId="formBasicEmail">*/}
                        {/*    <Form.Label>*/}
                        {/*        Order Delivery Options*/}

                        {/*    </Form.Label>*/}
                        {/*    <Form.Control type="text" placeholder="Name" className="rest_input"/>*/}
                        {/*</Form.Group>*/}

                        {/*<Form.Group className="input_bottom" controlId="formBasicEmail">*/}
                        {/*    <Form.Label>*/}
                        {/*        Maximum Filter Selection*/}

                        {/*    </Form.Label>*/}
                        {/*    <Form.Control type="text" placeholder="Name" className="rest_input"/>*/}
                        {/*</Form.Group>*/}
                        {/*<br/>*/}
                        {/*<Form.Group className="input_bottom position-relative" controlId="formBasicEmail">*/}
                        {/*    <div className="dish_switch">*/}
                        {/*        <label class="switch">*/}
                        {/*            <input type="checkbox"*/}
                        {/*            />*/}
                        {/*            <span class="slider round"></span>*/}
                        {/*        </label>*/}
                        {/*    </div>*/}
                        {/*    <Form.Control type="text" placeholder="Delivery" className="rest_input"/>*/}
                        {/*</Form.Group>*/}
                        {/*<br/>*/}
                        {/*<Form.Label>Item Info Image Position</Form.Label>*/}
                        {/*<Form.Select*/}
                        {/*    aria-label="Super Admin"*/}
                        {/*    className="select-event_form  menu-select input_bottom"*/}
                        {/*    width="100%"*/}
                        {/*>*/}
                        {/*    <option>None</option>*/}
                        {/*    <option value="SUPER ANALYST">Admin</option>*/}
                        {/*</Form.Select>*/}
                        {/*<br/>*/}
                        {/*<Form.Label>Item Info Image Size</Form.Label>*/}
                        {/*<Form.Select*/}
                        {/*    aria-label="Super Admin"*/}
                        {/*    className="select-event_form  menu-select input_bottom"*/}
                        {/*    width="100%"*/}
                        {/*>*/}
                        {/*    <option>None</option>*/}
                        {/*    <option value="SUPER ANALYST">Admin</option>*/}
                        {/*</Form.Select>*/}
                        {/*<br/>*/}
                        {/*<Form.Label>Combine Filters By</Form.Label>*/}
                        {/*<Form.Select*/}
                        {/*    aria-label="Super Admin"*/}
                        {/*    className="select-event_form  menu-select input_bottom"*/}
                        {/*    width="100%"*/}
                        {/*>*/}
                        {/*    <option>None</option>*/}
                        {/*    <option value="SUPER ANALYST">Admin</option>*/}
                        {/*</Form.Select>*/}

                        {/*<Form.Group className="input_bottom" controlId="formBasicEmail">*/}
                        {/*    <Form.Label>*/}
                        {/*        Minimum For Alert*/}

                        {/*    </Form.Label>*/}
                        {/*    <Form.Control type="text" placeholder="Name" className="rest_input"/>*/}
                        {/*</Form.Group>*/}

                        {/*<Form.Group className="input_bottom" controlId="formBasicEmail">*/}
                        {/*    <Form.Label>Assistance Categories</Form.Label>*/}
                        {/*    <Form.Control*/}
                        {/*        type="text"*/}
                        {/*        as="textarea"*/}
                        {/*        rows={3}*/}
                        {/*        placeholder="(Comma Separated List)"*/}
                        {/*        style={{height: "164px"}}*/}
                        {/*    />*/}
                        {/*</Form.Group>*/}
                    </Form>
                    <div className="d-flex justify-content-between">
                        <button onClick={() => navigate("/admin/restaurant")} className="cancel_but"
                                style={{width: "40%"}}>Cancel
                        </button>
                        <button className="save_but" style={{width: "40%"}} onClick={() => handleSave()}>Save
                        </button>
                    </div>
                </div>
            </div>
        </>
    );

}

export default Configuration;
