import React from "react";
import {successMsg, errorMsg} from "../../../util/toast";
import isArray from 'lodash/isArray';
import DishInformation from "./DishInformation";
import DishOption from "./DishOption";
import DishScreenDetails from "./DishScreenDetails";
import OperationTime from "./OperationTime";
import callApi from "../../../util/apiCaller";

export default class AddDish extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSegment: "Information",
            dishInfo: {},
            disableOptions: {
                dishInformation: false,
                screenDetails: true,
                options: true,
                operationTime: true
            },
            robotStationOptions: [],
            foodTypeOptions : [],
            stationOptions : []
        };
    }

    componentDidMount() {
        if (this.props.dishId) {
            callApi(`dish/${this.props.dishId}`, 'get').then(res => {
                if (res.status === "Success") {
                    let dish = res?.data?.dish;
                    if (dish.display_tags) {
                        dish.display_tags = dish.display_tags.join(",");
                    }
                    this.setState({dishInfo: dish});
                }
            });
        }
        this.fetchContentByCode('robot-stations', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                this.setState({
                    robotStationOptions: content?.params
                })
            }
        });
        this.fetchContentByCode('food-types', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                this.setState({
                    foodTypeOptions: content?.params
                })
            }
        });
        this.fetchContentByCode('station-options', (err, content) => {
            if (err) {
                console.log(err);
            } else {
                this.setState({
                    stationOptions: content?.params
                })
            }
        });
    }

    handleChangedish = (keyName, keyValue) => {
        let update = {...this.state.dishInfo}
        update[keyName] = keyValue;
        this.setState({dishInfo: update});
    }

    fetchContentByCode = (code, next) => {
        callApi(`content/code/${code}`, 'get').then(res => {
            if (res.status === "Success") {
               return next(null, res.data.content);
            }
        }).catch(err => {
            console.log(err)
            return next(err);
        })
    }

    setSegment = (selectedSegment = "") => {
        this.setState({
            selectedSegment: selectedSegment
        });
    };

    handleSaveMenu = (dishId, dish) => {
        if (dishId) {
            if (dish.display_tags && !isArray(dish.display_tags)) {
                dish.display_tags = dish.display_tags.split(",");
            }
            callApi(`dish/${dishId}/update`, 'post', {
                dish: dish
            }).then(res => {
                if (res.status === "Success") {
                    if (this.props.updateDishList) {
                        this.props.updateDishList(dishId, res.data.dish);
                    }
                    this.setState({dishInfo: res.data.dish});
                    successMsg("Dish updated Successfully...!");
                }
            }).catch(err => {
                console.log(err)
            });
        } else {
            dish["restaurant_id"] = this.props.restaurant?._id;
            if (dish.display_tags) {
                dish.display_tags = dish.display_tags.split(",");
            }
            callApi(`dish/new`, 'post', {
                dish: dish,
            }).then(res => {
                if (res.status === "Success") {
                    if (this.props.addDishList) {
                        this.props.addDishList(res?.data?.dish);
                    }
                    this.setState({dishInfo: res?.data?.dish});
                    this.props.setEditDishId(res?.data?.dish?._id)
                    successMsg("New Dish added Successfully...!");
                } else {
                    errorMsg(res?.data?.message);
                }
            }).catch(err => {
                console.log(err)
            });
        }
    }

    render() {

        return (
            <>
                <div style={{padding: "10px 60px 10px 60px"}}>
                    <ul className="d-flex information-modal dish-modal">
                        <li className="information col-3"
                            style={{fontWeight: this.state.selectedSegment === "Information" ? '600' : '400'}}
                            onClick={() => this.setSegment("Information")}>Information
                        </li>
                        
                        <li className="Option col-3"
                            style={{fontWeight: this.state.selectedSegment === "Screen details" ? '600' : '400',
                            color: !this.state.dishInfo?._id ? "#0009" : null
                            }}
                            onClick={this.state.dishInfo?._id? () => this.setSegment("Screen details") : null}>Screen details
                        </li>
                
                        <li className="Option col-3"
                            style={{fontWeight: this.state.selectedSegment === "Option" ? '600' : '400',
                            color: !this.state.dishInfo?._id ? "#0009" : null}}
                            onClick={this.state.dishInfo?._id ? () => this.setSegment("Option") : null}>Option
                        </li>
                
                        <li className="operation-time col-3"
                            style={{fontWeight: this.state.selectedSegment === "Operation Time" ? '600' : '400',
                            color: !this.state.dishInfo?._id ? "#0009" : null
                            }}
                            onClick={this.state.dishInfo?._id ? () => this.setSegment("Operation Time") : null}>Operation Time
                        </li>


                    </ul>
                </div>
                {this.state.selectedSegment === "Information" ?
                    <DishInformation
                        setSegment={this.setSegment}
                        closeDishInformationModal={this.props.closeDishInformationModal}
                        dishId={this.props.dishId}
                        handleSaveMenu={this.handleSaveMenu}
                        dishInfo={this.state.dishInfo}
                        handleChangedish={this.handleChangedish}
                        robotStationOptions={this.state.robotStationOptions}
                        foodTypeOptions={this.state.foodTypeOptions}
                        stationOptions={this.state.stationOptions}
                    />
                    :
                    null
                }
                <div className="row">
                    {this.state.selectedSegment === "Screen details" ?
                        <DishScreenDetails
                            setSegment={this.setSegment}
                            closeDishInformationModal={this.props.closeDishInformationModal}
                            dishId={this.props.dishId}
                            dishInfo={this.state.dishInfo}
                            handleChangedish={this.handleChangedish}
                            handleSaveMenu={this.handleSaveMenu}

                        />
                        :
                        null
                    }
                    {this.state.selectedSegment === "Option" ?
                        <DishOption
                            setSegment={this.setSegment}
                            dishId={this.props.dishId}
                            closeDishInformationModal={this.props.closeDishInformationModal}
                            dishInfo={this.state.dishInfo}
                            handleChangedish={this.handleChangedish}
                            handleSaveMenu={this.handleSaveMenu}
                        />
                        :
                        null
                    }
                    {this.state.selectedSegment === "Operation Time" ?
                        <OperationTime
                            setSegment={this.setSegment}
                            dishId={this.props.dishId}
                            closeDishInformationModal={this.props.closeDishInformationModal}
                            dishInfo={this.state.dishInfo}
                            availability_rule={this.state.dishInfo.availability_rule}
                            availability_daily={this.state.dishInfo.availability_daily}
                            availability_weekly={this.state.dishInfo.availability_weekly}
                            availability_monthly={this.state.dishInfo.availability_monthly}
                            handleChangedish={this.handleChangedish}
                            handleSaveMenu={this.handleSaveMenu}
                        />

                        :
                        null
                    }
                </div>
            </>
        )
    }
}
