import React, { useState } from 'react';
import Calendar from "react-calendar";
import CalendarIcon from "../../../assets/calender-icon.svg";
import TimePicker from "./TimePicker";
import 'react-calendar/dist/Calendar.css';

const DatePicker = ({
                        monthlySlots,
                        addMonthlyTimeSlot,
                        removeMonthlyTimeSlot,
                        handleMonthlySlotChange,
                        changeDayInMonth
                    }) => {
    console.log("monthly slots", monthlySlots)

    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedMonth, setSelectedMonth] = useState(null)


    const changeDate = (e) => {
        console.log(typeof(e), e.getDate())
        let date = Number(e.getDate())
        let month = e.toLocaleString('default', { month: 'short' })
        console.log(date)
        changeDayInMonth(monthlySlots[date-1])
        setSelectedDate(date)
        setSelectedMonth(month)
    }

    return (
        <>
            <div className='col-md-6 '>
                <div className='d-flex justify-content-between'>
                    <p className='date-pera'>Selected Date</p>
                    <p className='date-pera'>{selectedDate} {selectedMonth} <img src={CalendarIcon} alt="calender-icon"/></p>
                </div>
                <div className='calender'>
                    <Calendar onChange={(e) => changeDate(e)} />
                </div>
            </div>
            <div className='col-md-6' style={{paddingRight: "0px", paddingLeft: "0px"}}>
                {monthlySlots && monthlySlots.filter(item => item.is_checked).map(item => (
                    <>
                        <div className='daily-checkbox-bg'>
                            <p>Select Time For Day {item.day}</p>
                        </div>
                        <TimePicker
                            timeSlots={item.time_availability}
                            addTimeSlot={() => addMonthlyTimeSlot(item)}
                            deleteTimeSlot={(deleteItem) => removeMonthlyTimeSlot(item, deleteItem)}
                            getTimeValue={handleMonthlySlotChange}
                            day = {item.day}
                        />
                    </>
                ))}
            </div>
        </>
    );
};

export default DatePicker;
