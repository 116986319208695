import React, {Component, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {getSelectedRestaurant} from "../../redux/reducers/AppReducer";
import {fetchKitchenOrders, fetchKitchenTables} from "../../redux/actions/OrderActions";
import {getKitchenOrders} from "../../redux/reducers/OrderReducer";
import KDSTable from "./components/KDSTable";
import styled from "styled-components";

const KDSHeading = styled.div`

  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  background: #F5F1E8;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 10px 20px 0px 20px;

  p:nth-child(1) {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #323232;
  }

  p:nth-child(2) {
    display: flex;
    align-items: center;
  }
`

const KDSContainer = styled.div`
  padding: 20px 15px 10px 15px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media (max-width: 1250px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`

const KDSPage = () => {
    const dispatch = useDispatch();

    const restaraunt = useSelector(state => getSelectedRestaurant(state));
    const orderList = useSelector(state => getKitchenOrders(state));

    const getOrdersArray = () => {
      let tableOrders = Object.values(orderList)
      let ordersArray = []

      for(let ordersData of tableOrders){
        ordersArray = ordersArray.concat(ordersData?.orders)
      }

      return ordersArray
    }

    const orders = getOrdersArray()

    useEffect(() => {
        if (restaraunt._id) {
            dispatch(fetchKitchenOrders(restaraunt._id))
            dispatch(fetchKitchenTables(restaraunt._id))
        }
    }, [restaraunt]);
    return (
        <>
            <div className="employee-part add-events-section">
                <Helmet>
                    <title>KDS | Windmills</title>
                </Helmet>
                <KDSHeading>
                    <p>KDS</p>
                    <p>Total Order : <strong>{orders?.length ? orders?.length : "NA"}</strong></p>
                </KDSHeading>
                <KDSContainer>
                    {orders?.map(tableOrder => <KDSTable key={tableOrder._id} tableOrder={tableOrder}/>)}
                </KDSContainer>
            </div>
        </>
    )

}

export default KDSPage;
