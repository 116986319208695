import { AGENT, USER_ID } from "../../../constants/enums/appEnums";
import { Button, Modal, ModalBody } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { errorMsg, successMsg } from "../../../util/toast";

import AddStarters from "./AddStarters";
import DefaultModal from "../../../hoc/DefaultModal/DefaultModal";
import MenuLogo from "../../../assets/menu-logo.png";
import callApi from "../../../util/apiCaller";

const CategoryHeader = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [agent, setAgent] = useState({});

  useEffect(() => {
    if (localStorage) {
      setAgent(JSON.parse(localStorage.getItem(AGENT)));
    }
  }, []);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  return (
    <div>
      <div className="menu-header">
        <div>
          <img src={MenuLogo} />
        </div>
        <div>
          <button
            className="bluk-item-btn"
            onClick={openModal}
          >
            Add Category
          </button>
        </div>
      </div>

      {/* Menu Starters Information Modal */}
      <DefaultModal 
        show={isOpen}
        onHide={() => closeModal(false)}
        className="add_modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        size=""
        showFooter={false}
        
      >
        <div className="add-header">
          <h5>Starters</h5>
        </div>
          <AddStarters
            menuId={props.menuId}
            category_menu_id={props.category_menu_id}
            fetchCategoryList={props.fetchCategoryList}
            closeMenuInformationModal={() => closeModal(false)}
            setMenuCategroy={props.setMenuCategroy}
            listPosition={props.listPosition}
          />
        
      </DefaultModal>
    </div>
  );
}

export default CategoryHeader;
