import React, {useEffect, useState} from 'react'

import Pagination from "./Pagination";
import _ from 'lodash';
import styled from "styled-components"

const TabsHeading = styled.div`
  // background: rgba(196, 196, 196, 0.2);
  border-radius: 8px;
  margin-top: 25px;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;

  input {
    position: absolute;
    right: 11px;
    top: 5px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 348px;
    height: 41px;
    padding-left: 40px;
  }




`
const DivParent = styled.div`
  //  display:flex;
  //  justify-content: space-between;

`
const PageRow = styled.div`


  p {

    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }

  select {
    margin-left: 5px;
    border-radius: 8px;
    width: 59px;
    height: 26px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
`

const TableFooter = ({isCounterVisible = true, isPageVisible= true, pageSize, pageNum, count, pages, handlePageSizeChange, handlePageNumChange}) => {

    // console.log("TableFooter", pageSize, pageNum, count, pages);
   
    useEffect(() => {

    },[])


    const _localhandlePageSizeChange = (value) => {
      handlePageSizeChange(value);
    }

    const _localhandlePageNumChange = (value) => {
      
      if(parseInt(value) >= 0){
        handlePageNumChange(value)
      }
    }

    return (
        <div>
            <TabsHeading>
                <DivParent>
                    {isCounterVisible ?
                        <PageRow>
                            <p>Show Entries
                                <select
                                    value={pageSize}
                                    onChange={(e) => _localhandlePageSizeChange(e.target.value)}
                                    name="" id="">
                                    {Array.isArray(pages) && pages.map((page, index) => (
                                        <option key={index} value={page}>{page}</option>
                                    ))}
                                </select></p>
                        </PageRow>
                        :
                        null
                    }
                     {isPageVisible ?<p> Showing {count > 0 ? (pageNum - 1) * pageSize + 1 : 0} to {pageNum * pageSize < count ? pageNum * pageSize : count} out of {count}</p>
                      :
                      null
                  }
                </DivParent>
                {isPageVisible ? <Pagination
                    prev
                    next
                    first
                    last
                    size={'sm'}
                    maxButtons={5}
                    activePage={pageNum ? pageNum : 1}
                    pageNum={pageNum}
                    count={count}
                    pageSize={pageSize}
                    onSelect={(page) => _localhandlePageNumChange(page)}/>
                    :
                    null
                }
            </TabsHeading>
        </div>
    )
}

export default TableFooter;
