import "./restaurantlist.css";

import React, {useEffect, useState} from "react";

import {Helmet} from "react-helmet";
import MenuLogo from "../../assets/menu-logo.png";
import RestaurantHeader from "./Components/RestaurantHeader";
import RestaurantTable from "./Components/RestaurantTable";
//import callApi from "../../util/apiCaller";
import {getBusiness} from "../../redux/reducers/AppReducer";
import {useSelector} from 'react-redux';
import callApiV2 from "../../util/apiV2Caller";


const RestaurantListPage = () => {
    const [restaurantList, setrestaurantList] = useState([])
    const [restaurant_count, setrestaurantCount] = useState(0);
    const business = useSelector(state => getBusiness(state));

    const fetchRestaurantList = (business_id, inputFilters) => {
        let filters = {};
        filters.business_id = business_id;
        if (inputFilters && inputFilters.qtext) {
            filters.qtext = inputFilters.qtext;
        }
        callApiV2(`restaurant/list`, 'post', {
            filters,
            pageNum: inputFilters?.pageNum,
            pageSize: inputFilters?.pageSize,

        }).then(res => {
            if (res?.status === "Success") {
                setrestaurantList(res?.data?.list)
                setrestaurantCount(res?.data?.count);
            } else {
                console.log('err', res?.data?.message);
            }
        });
    };
    const handlePageSize = (pageSize) => {
        let searchFilters = {...filterData};
        searchFilters.pageSize = parseInt(pageSize);
        searchFilters.pageNum = 1;
        setFilterData(searchFilters);
        fetchRestaurantList(business._id, searchFilters);
    };
    const handlePageNum = (pageNum) => {
        let searchFilters = {...filterData};
        searchFilters.pageNum = parseInt(pageNum);
        setFilterData(searchFilters);
        fetchRestaurantList(business._id, searchFilters);
    };
    const [filterData, setFilterData] = useState({});
    useEffect(() => {
        setFilterData({
            qtext: '',
            pageNum: 1,
            pageSize: 50,
        })
        fetchRestaurantList(business._id, null);
    }, [business]);

    return (
        <>
            <div className="employee-header">
                <Helmet>
                    <title>Windmills | Restaurant</title>
                </Helmet>
                <div><img src={MenuLogo}/></div>
            </div>
            <div className="right_side_section">
                <RestaurantTable
                    restaurantList={restaurantList}
                    setRestaurantList={setrestaurantList}
                    restaurant_count={restaurant_count}
                    handlePageSizeChange={handlePageSize}
                    handlePageNumChange={handlePageNum}
                    pageSize={filterData.pageSize}
                    pageNum={filterData.pageNum}
                    pages={[10, 20, 50, 100]}
                />
            </div>
        </>
    )

}

export default RestaurantListPage;
