import React, { Components, useEffect, useState } from "react";
import {getAgent, getSelectedRestaurant} from "../../redux/reducers/AppReducer";
import {useLocation, useParams} from "react-router-dom";

import { Helmet } from "react-helmet";
import StarterBreadCrumbs from "./Components/StarterBreadCrumbs";
import StarterHeader from "./Components/StarterHeader";
import StarterTable from "./Components/StarterTable";
import callApi from "../../util/apiCaller";
import {useSelector} from "react-redux";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const StarterPage = (props) => {
    console.log(props,"starter Page");
    const restaurant = useSelector(state => getSelectedRestaurant(state));
    let location = useLocation();
    const agent = useSelector(state => getAgent(state));
    const query = useQuery();
    const { group_menu_id , category_menu_id} = useParams();
    const [dish_list, setDish] = useState([]);
    const [menuCategory, setMenuCategory] = useState({});
    const [groupMenu, setGroupMenu] = useState({});
    const [dish_count, setDishCount] = useState(0);

    useEffect(() => {
        getGroupMenu(group_menu_id)
    }, [group_menu_id]);

    const getGroupMenu = (id) => {
        callApi(`group_menu/${id}`, 'get').then(res => {
            if (res?.status === "Success") {
                setGroupMenu(res.data.group_menu);
            }
        });
    };

    useEffect(() => {
        getCategoryMenu(category_menu_id)
    }, [category_menu_id]);

    const getCategoryMenu = (id) => {
        callApi(`menu_category/${id}`, 'get').then(res => {
            if (res?.status === "Success") {
                setMenuCategory(res.data.menu_category);
            }
        });
    };

    // useEffect(() => {
    //     let filters = {};
    //     filters.menu_category_id = category_menu_id;
    //     console.log("filters", filters)
    //     callApi(`dish/selected-list`, 'post', {
    //         filters
    //     }).then(res => {
    //         console.log("res filters", filters);
    //         if (res.status === "Success") {
    //             console.log("res viko", res);
    //             setDish(res.data.list);
    //             setDishCount(res?.data?.count)
    //         }
    //     });

    //     callApi(`menu_category/${category_menu_id}`, 'get').then(res => {
    //         if (res.status === "Success") {
    //             console.log("res viko", res);
    //             setMenuCategoryName(res.data.menu_category.name);
    //         }
    //     });

    // }, [category_menu_id]);

    const fetchCategoryDishList = (restaurant_id, inputFilters) => {
        let filters = {};
        filters.menu_category_id = category_menu_id;
        if (inputFilters && inputFilters.qtext) {
            filters.qtext = inputFilters.qtext;
        }
        callApi(`dish/selected-list`, 'post', {
            filters,
            pageNum: inputFilters?.pageNum,
            pageSize: inputFilters?.pageSize,
        }).then(res => {
            if (res?.status === "Success") { 
                setDish(res.data.list);
                setDishCount(res?.data?.count)
            } else {
                console.log('err', res?.data?.message);
            }
        });
    };

    const handlePageSize = (pageSize) => {
        let searchFilters = {...filterData};
        searchFilters.pageSize = parseInt(pageSize);
        searchFilters.pageNum = 1;
        setFilterData(searchFilters);

    };

    const handlePageNum = (pageNum) => {
        let searchFilters = {...filterData};
        searchFilters.pageNum = parseInt(pageNum);
        setFilterData(searchFilters);

    };
    const [filterData, setFilterData] = useState({
        qtext: '',
        pageNum: 1,
        pageSize: 50,
    });
    useEffect(() => {
        setFilterData({
            qtext: '',
            pageNum: 1,
            pageSize: 50,
        })
        fetchCategoryDishList(category_menu_id.id, null)
    }, [category_menu_id]);

    const searchForCategoryDishes = (searchText) => {
        let searchFilters = { ...filterData };
        searchFilters.qtext = searchText;
        setFilterData(searchFilters);
        fetchCategoryDishList(restaurant?._id, searchFilters);
    };
    // console.log("dish lish console", dish_list);
    return (
        <div className="right_side_section">
            <Helmet>
                <title>Windmills | Menu | Category | Dishes</title>
            </Helmet>
            <StarterHeader
                group_menu_id={group_menu_id}
                category_menu_id={category_menu_id}
            />
            <StarterBreadCrumbs
                menuCategory={menuCategory}
                groupMenu={groupMenu}
                group_menu_id={group_menu_id}
                category_menu_id={category_menu_id}

                query={filterData.qtext}
                searchForCategoryDishes={searchForCategoryDishes}

            />
            <StarterTable
                {...props}
                dishlist={dish_list}
                setDish={setDish}
                dish_count={dish_count}
                category_menu_id={category_menu_id}
                menuCategory={menuCategory}
                groupMenu={groupMenu}
                handlePageSizeChange={handlePageSize}
                handlePageNumChange={handlePageNum}
                searchForCategoryDishes={searchForCategoryDishes}
                pageSize={filterData.pageSize}
                pageNum={filterData.pageNum}
                pages={[10, 20, 50, 100]}
            />
        </div>
    )
}

export default StarterPage;
