//import Action

import {SET_KITCHEN_ORDERS, SET_KITCHEN_TABLES, UPDATE_KITCHEN_ORDER} from "../actions/OrderActions";

const initialState = {
    orders: [],
    tables: [],
    tableMap: {},
    is_updating_order: false,
}

const AppReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_KITCHEN_ORDERS:
            return {
                ...state,
                orders: action.orders,
            }

        case SET_KITCHEN_TABLES:
            let objMap = {};
            if (action.tables) {
                action.tables.forEach(item => {
                    objMap[item._id] = item;
                })
            }
            return {
                ...state,
                tables: action.tables,
                tableMap: objMap
            }

        case UPDATE_KITCHEN_ORDER:
            return {
                ...state,
                is_updating_order: action.is_updating_order,
            }
        default:
            return state;
    }
}


/* Selectors */
//login
export const getKitchenOrders = state => state.order.orders;
export const getKitchenUpdateStatus = state => state.order.is_updating_order;
export const getKitchenTableMap = state => state.order.tableMap;

//Export Reducer
export default AppReducer;
