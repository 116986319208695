import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom'
import Brand from '../../assets/windmills-logo.svg'
import callApi from "../../util/apiCaller";
import { toast } from 'react-toastify';

const ForgotPasswordPage = () => {

    const [user, setUserData] = useState({
        email: '',
    });

    const notify = (data) => {
        toast(data)
    }

    const [errors, setErrors] = useState({});

    const handleChangeUser = (keyName, keyValue) => {
        let update = { ...user };
        update[keyName] = keyValue;
        setUserData(update);
    }

    const handleValidation = (user) => {
        let isValid = true;
        let newErrors = {};
        if (!user.email) {
            isValid = false;
            newErrors['email'] = 'Please enter email';
        }
        return {
            isValid,
            errors: newErrors
        }
    };

    const handleForgotPasswordAPI = (creds, next) => {
        console.log("creds", creds);
        callApi(`auth/agent/forgot-password`, 'post', {
            agent: creds
        }).then(res => {
            return next(res);
        })
    };

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        let check = handleValidation(user);
        console.log(check, user, "check");
        if (check.isValid) {
            handleForgotPasswordAPI(user, (result) => {
                console.log("test", result)
                if (result.status === "Success") {
                    console.log("forgort response", result);
                    notify(result.data.message);
                    handleChangeUser("email", '');
                    setTimeout(() => {
                        window.location = "/mail-send";
                    }, 1000)

                } else {
                    let errors = {};
                    errors.message = result.data.message;
                    setErrors(errors);
                    notify(result.data.message);
                }
            });
        } else {
            setErrors(check.errors);
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Forgot Page | SmartHelio</title>
            </Helmet>
            <section className='login-section'>
                <div className="content-box">
                    <div className='login-card'>
                        <div className="form-box">
                            <div>
                                <div className='logo'>
                                    <img src={Brand} className='brand-img' alt="" />
                                </div>

                                <form onSubmit={handleSubmit} className="form-pd">
                                    <p>Please enter your e-mail Id</p>
                                    <div className="input-box">
                                        <span>Email*</span>
                                        <input
                                            value={user.email}
                                            onChange={(e) => handleChangeUser("email", e.target.value)} type="text"
                                            placeholder='Enter Email id' />
                                        {errors && errors.email ? <label>{errors.email}</label> : null}
                                    </div>
                                    {errors && errors.message ? <label>{errors.message}</label> : null}
                                    <div className="">
                                        <input type="submit" value='Send' className='login-btn' />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ForgotPasswordPage;