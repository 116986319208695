import React, { Component } from "react";
import AddEmployeeImg from "../../../assets/add-employee-img.png";
import Form from 'react-bootstrap/Form';
import callApi from "../../../util/apiCaller";
import { toast } from 'react-toastify';
import { withRouter } from "../../../hoc/WithRouter";
import { setAgent, setBusiness, setRestaurantList, setSelectedRestaurant } from "../../../redux/actions/AppActions";
import { getBusiness, getRestaurantList } from "../../../redux/reducers/AppReducer";
import { connect } from "react-redux";
import shortid from "shortid";

const yes = "success";
const no = "error";


class AddEmployee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employee: {
                employee_id : shortid.generate(),
            },
            errors: {}
        }
    }

    notify = (data) => {
        if (data === "success") {
            toast("Welcome to WindMill..!")
        } else if (data === "error") {
            toast("error!")
        }

    }
    handleAddEmployee = (agent, next) => {
        console.log("api", agent);
        // agent["business_id"] = this.props.business._id
        this.handleChangeUser("business_id", this.props.business._id);
        callApi(`auth/agent/signup`, 'post',
            agent
        ).then(res => {
            return next(res);
        })
    };

    validatePhoneNumber(number) {
        let newErrors = {};
        let isValid = true
        var pattern = new RegExp(/^[0-9\b]+$/);
        if (!pattern.test(number)) {
            isValid = false;
            newErrors["phone_number"] = "Please enter only number values.";
        } 

        return {isValid, errors: newErrors}; 
    }

    handleChangeUser = (keyName, keyValue) => {
        let update = this.state.employee

        if(keyName == 'phone_number'){
            let check = this.validatePhoneNumber(keyValue)
            if(!check.isValid){
                this.setState({errors : check.errors})
            }
        }

        update[keyName] = keyValue;
        this.setState({
            employee: update
        })
    }

    handleValidation = (employee) => {
        let isValid = true;
        let newErrors = {};
        if (!employee.contact_name) {
            isValid = false;
            newErrors['contact_name'] = 'Please enter first name';
        }
        if (!employee.preferred_restaurant_id) {
            isValid = false;
            newErrors['preferred_restaurant_id'] = 'Please enter restaurant name';
        }
        if (!employee.location) {
            isValid = false;
            newErrors['location'] = 'Please enter restaurant location';
        }
        if (employee.role !== 'staff' && !employee.contact_email) {
            isValid = false;
            newErrors['contact_email'] = 'Please enter your email';
        }
        if (employee.role !== 'staff' && !employee.phone_number) {
            isValid = false;
            newErrors['phone_number'] = 'Please enter your number';
        }
        if (!employee.employee_id) {
            isValid = false;
            newErrors['employee_id'] = 'Please enter Employee Id';
        }
        if(employee?.role !== 'staff' && !employee?.phone_number) {
            isValid = false;
            newErrors["phone_number"] = "Please enter valid phone number.";
        }
        if (employee?.phone_number) {

            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(employee?.phone_number)) {
                isValid = false;
                newErrors["phone_number"] = "Please enter only number.";
            } else if (employee?.phone_number.length !== 10) {
                isValid = false;
                newErrors["phone_number"] = "Please enter valid phone number.";
            }
        } 
        
        if (!employee.role) {
            isValid = false;
            newErrors['role'] = 'Please select your role';
        }
        if (employee?.role !== 'staff' && !employee.local_password) {
            isValid = false;
            newErrors['local_password'] = 'Please enter password';
        }
        return {
            isValid,
            errors: newErrors
        }
    };
    handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        const check = this.handleValidation(this.state.employee);
        if (check.isValid) {
            let employee = {
                agent: this.state.employee
            }
            this.handleAddEmployee(employee, (result) => {
                if (result.status === "Success") {
                    if(result?.data?.agentExists){
                        toast(result?.data?.message)
                        this.setState({errors  : {}})
                    } else {
                        this.notify(yes);
                    this.setState({errors  : {}})
                    }
                } else {
                    this.notify(no)
                }
            });
        } else {
            this.setState({
                errors: check.errors
            })
        }
    }

    handleLinkChange = () => {
        this.props.navigate(`/admin/employees`);
    };

    render() {
        console.log("bussnesss", this.props.business,this.props.restaurantList);
        

        return (
            <>
                <div className="employee-part">
                    <div className="add-employee-bg">
                        <h1>AddEmployee</h1>
                    </div>
                    <div className="employee-form-section">
                        <p>User Details</p>
                        <div className="row">
                            <div className="col-md-6">
                                <Form className="form-mt employee-form">
                                    <div className="">
                                        <Form.Label className="events-mt">Assign Roles <i class="fa fa-asterisk" aria-hidden="true"></i></Form.Label>
                                        <Form.Select aria-label="Super Admin" className="select-event employee-role"
                                            width="100%"
                                            value={this.state?.employee?.role}
                                            onChange={(e) => this.handleChangeUser("role", e.target.value)}>
                                            <option>Select Your Role</option>
                                            <option value="owner">owner</option>
                                            <option value="manager">Manager</option>
                                            <option value="staff">Staff</option>
                                        </Form.Select>
                                        {this.state.errors.role ?
                                            <p className="add-employee-validation">{this.state.errors.role}</p> : null}
                                        <br />
                                        <Form.Group className="" controlId="formBasicEmail">
                                            <Form.Label>Employee ID<i class="fa fa-asterisk" aria-hidden="true"></i></Form.Label>
                                            <Form.Control type="text" placeholder="Enter Employee Id"
                                                value={this.state?.employee?.employee_id}
                                                onChange={(e) => this.handleChangeUser("employee_id", e.target.value)} />
                                            {this.state.errors.employee_id ?
                                                <p className="add-employee-validation">{this.state.errors.employee_id}</p> : null}
                                        </Form.Group>
                                        <br />
                                        <Form.Group className="" controlId="formBasicEmail">
                                            <Form.Label>Name <i class="fa fa-asterisk" aria-hidden="true"></i></Form.Label>
                                            <Form.Control type="text" placeholder="Enter Name"
                                                value={this.state?.employee?.contact_name}
                                                onChange={(e) => this.handleChangeUser("contact_name", e.target.value)} />
                                            {this.state.errors.contact_name ?
                                                <p className="add-employee-validation">{this.state.errors.contact_name}</p> : null}
                                        </Form.Group>

                                        {/* <Form.Group className="" controlId="formBasicPassword">
                                            <Form.Label>Last Name*</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Last Name"
                                                          value={this.state.employee.last_name}
                                                          onChange={(e) => this.handleChangeUser("last_name", e.target.value)}/>
                                            {this.state.errors.last_name ?
                                                <p className="add-employee-validation">{this.state.errors.last_name}</p> : null}
                                        </Form.Group> */}
                                    </div>
                                    {/* <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                                        <Form.Label>Restaurant</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Restaurant"
                                                      value={this.state.employee.restaurant}
                                                      onChange={(e) => this.handleChangeUser("restaurant", e.target.value)}/>
                                        {this.state.errors.restaurant ?
                                            <p className="add-employee-validation">{this.state.errors.restaurant}</p> : null}
                                    </Form.Group> */}

                                    <Form.Label className="events-mt">Restaurant <i class="fa fa-asterisk" aria-hidden="true"></i></Form.Label>
                                    <Form.Select aria-label="Super Admin" className="select-event employee-role"
                                        width="100%"
                                        value={this.state?.employee?.preferred_restaurant_id}
                                        onChange={(e) => this.handleChangeUser("preferred_restaurant_id", e.target.value)}>

                                        {this.props.restaurantList.map(restaurant => ( 
                                              <option value={restaurant._id}>{restaurant.name}</option>
                                        ))}
                                    </Form.Select>
                                    {this.state.errors.preferred_restaurant_id ?
                                        <p className="add-employee-validation">{this.state.errors.preferred_restaurant_id}</p> : null}

                                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                                        <Form.Label>Location <i class="fa fa-asterisk" aria-hidden="true"></i></Form.Label>
                                        <Form.Control type="text" placeholder="Enter Location"
                                            value={this.state?.employee?.location}
                                            onChange={(e) => this.handleChangeUser("location", e.target.value)}
                                        />
                                        {this.state.errors.location ?
                                            <p className="add-employee-validation">{this.state.errors.location}</p> : null}
                                    </Form.Group>

                                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                                        <Form.Label>Email address {this.state.employee.role == 'staff' ? null :  <i class="fa fa-asterisk" aria-hidden="true"></i>} </Form.Label>
                                        <Form.Control type="email" placeholder="Enter email Address"
                                            value={this.state?.employee?.contact_email}
                                            onChange={(e) => this.handleChangeUser("contact_email", e.target.value)} />
                                        {this.state.errors.contact_email ?
                                            <p className="add-employee-validation">{this.state.errors.contact_email}</p> : null}
                                    </Form.Group>
                                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                                        <Form.Label>Mobile Number {this.state.employee.role == 'staff' ? null :  <i class="fa fa-asterisk" aria-hidden="true"></i>}</Form.Label>
                                        <Form.Control type="text" placeholder="Mobile number"
                                            value={this.state?.employee?.phone_number}
                                            onChange={(e) => this.handleChangeUser("phone_number", e.target.value)} />
                                        {this.state.errors.phone_number ?
                                            <p className="add-employee-validation">{this.state.errors.phone_number}</p> : null}
                                    </Form.Group>
                                    <Form.Group className="mb-3 events-mt" controlId="formBasicEmail">
                                        <Form.Label>Password {this.state.employee.role == 'staff' ? null :  <i class="fa fa-asterisk" aria-hidden="true"></i>}</Form.Label>
                                        <Form.Control type="text" placeholder="Password"
                                            value={this.state?.employee?.password}
                                            onChange={(e) => this.handleChangeUser("local_password", e.target.value)} />
                                        {this.state.errors.local_password ?
                                            <p className="add-employee-validation">{this.state.errors.local_password}</p> : null}
                                    </Form.Group>
                                    
                                    {/* <PhoneInput
                                    defaultCountry="IN"
                                    value={value}
                                    placeholder="Mobile number"
                                    onChange={setValue} /> */}
                                </Form>
                            </div>
                            <div className="col-md-6">
                                <img src={AddEmployeeImg} width="100%" />
                            </div>

                        </div>
                        <div className="event-buttons">
                            <div className="cancel-btn">
                                <button onClick={() => this.handleLinkChange()}>Cancel</button>
                            </div>
                            <div className="save-btn">
                                <button onClick={(e) => this.handleSubmit(e)}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: getBusiness(state),
        restaurantList: getRestaurantList(state)
    };
}
export default connect(mapStateToProps)(withRouter(AddEmployee));
