import React from "react";

const DishSearchHeader = ({ query, searchForDish, setNewDishModalVisibility, openBulkUploadModal }) => {
    return (
        <div>
            <div className="menu-header">
                <div className="position-relative col-md-8">
                    <i className="fa fa-search fa_search" aria-hidden="true"></i>
                    <input
                        value={query} // controlled input
                        onChange={(e) => searchForDish(e.target.value)}
                        className="form-control input-search me-2"
                        placeholder="Search by Dishes, Rasoi name"
                        aria-label="Search"
                    />
                </div>
                <div>
                    <button className="add-menu-btn" onClick={openBulkUploadModal}>Upload Dish</button>
                    <button
                        className="add-menu-btn"
                        onClick={() => setNewDishModalVisibility(true)}
                    >
                        Add Dish
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DishSearchHeader;
