import React, {useState} from 'react';
import TimePicker from "./TimePicker";
import {showDayObject} from "../../../util/timeUtility";

const DaysPicker = ({
                        weeklySlots,
                        changeDayInWeek,
                        addWeeklyTimeSlot,
                        removeWeeklyTimeSlot,
                        handleWeeklySlotChange
                    }) => {

    const addTimeSlot = (day) => {
        addWeeklyTimeSlot(day)
    }
    console.log("weekly slots from picker", weeklySlots)
    return (
        <>
            <div className='row'>
                <div className='col-md-3 daily-checkbox-bg'>
                    {weeklySlots && weeklySlots.map(item => (
                        <div className="form-check menu-mt">
                            <input onChange={() => changeDayInWeek(item)} name="days"
                                   checked={item.is_checked}
                                   className="form-check-input" type="checkbox" value={item.day}
                                   id="flexCheckIndeterminate"/>
                            <label className="form-check-label" htmlFor="flexCheckIndeterminate">
                                {showDayObject[item.day]}
                            </label>
                        </div>
                    ))}
                </div>
                <div className='col-md-9' style={{paddingRight: "0px", paddingLeft: "0px"}}>
                {weeklySlots && weeklySlots.filter(item => item.is_checked).map(item => (
                    <>
                        <div className='daily-checkbox-bg'>
                            <p>Select Time for {showDayObject[item.day]}</p>
                        </div>
                        <TimePicker
                            timeSlots={item.time_availability}
                            addTimeSlot={() => addTimeSlot(item)}
                            deleteTimeSlot={(deleteItem) => removeWeeklyTimeSlot(item, deleteItem)}
                            getTimeValue={handleWeeklySlotChange}
                            day={item.day}
                        />
                   </>
                ))}
                 </div>
            </div>
        </>
    );
};

export default DaysPicker;
