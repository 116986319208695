import React, {useEffect, useState} from 'react'
import { errorMsg, successMsg } from '../../../util/toast';

import EnumContainer from './enumContainer'
import shortid from 'shortid';

function CodeForm({handleAddContent, closeModal, fetchContents, selectedItem, handleUpdateContent}) {

    const [code, setCode] = useState("")
    const [params, setParams] = useState([])

    useEffect(() => {
        setCode(selectedItem?.code)
        setParams(selectedItem?.params)
    }, [selectedItem])

    const afterAddOrUpdateMsg = (type) => {

        if(type == "add"){
            successMsg("Code added successfully...!!!")
            fetchContents()
        } else if(type =="update"){
            successMsg("Code updated successfully...!!!")
            fetchContents()
        }

    }

    const handleAdd = () => {
        //this map function removes ID that we added earlier for new param for sending to server
        let newParams = []
        if(params && params.length > 0){
            newParams = params.map(param => {
            return {
                label : param.label,
                value : param.value
            }
        })
        } 
        console.log("new", newParams)
        handleAddContent({code, params : newParams}, (res) => afterAddOrUpdateMsg("add"))
        closeModal()
    }

    const handleUpdate = (id) => {
        //this function removes ID that we added earlier for new param for sending to server
        let newParams = []
        if(params && params.length > 0){
            newParams = params.map(param => {
            return {
                label : param.label,
                value : param.value
            }
        })
        } 
        console.log("new", newParams)
        handleUpdateContent(id, {code, params : newParams}, (res) => afterAddOrUpdateMsg("update"))
        closeModal()
    }

    const addParam = () => {
        let newParams = []
        if(params && params.length > 0){
            newParams = [...params , {
                _id : shortid.generate(),
                label : "",
                value : ""
            }]
        } else {
            newParams = [{
                _id : shortid.generate(),
                label : "",
                value : ""
            }]
        }
        
        console.log("final params", newParams)
        setParams(newParams)
    }

    const handleParamChange = (paramToUpdate, label, value) => {
        let newParams = [...params]

        newParams = newParams.map(param => {
            if(param._id == paramToUpdate._id){
                param.label = label
                param.value = value
            }

            return param
        })

        setParams(newParams)
    }

    const removeParam = (id) => {
        let newParams = params.filter(param => param._id !== id)

        setParams(newParams)
    }

    console.log(selectedItem)
    if(!selectedItem?._id){
        return (   <div className='contents_form'>
            <label>Add Code Name</label>
            <br/>
            <input type="text" value={code} onChange={(e) => setCode(e.target.value)}/>
            <button onClick={() => addParam()} className="submit_but">Add Parameter</button>

            {
                 params && params.length > 0? 
                 
                 params.map(item => {
                    return <EnumContainer 
                            enumLabel={item.label} 
                            enumValue={item.value} 
                            getParamValue={(label, value) => handleParamChange(item, label, value)}
                            removeParam={() => removeParam(item._id)}
                            />
                 })
                 : 
                null
            }
            <br></br>
            <button onClick={() => handleAdd()} className="add_code">Add Code</button>

            </div>
        )
    } else {
        return (   <div className='contents_form'>
            <label>Edit Code Name</label><br/>
            <input type="text" value={code} onChange={(e) => setCode(e.target.value)}/>
            <button onClick={() => addParam()} className="submit_but">Add Parameter</button>


            {
                 params && params.length > 0? 
                 
                 params.map(item => {
                    return <EnumContainer 
                            enumLabel={item.label} 
                            enumValue={item.value} 
                            getParamValue={(label, value) => handleParamChange(item, label, value)}
                            removeParam={() => removeParam(item._id)}
                            />
                 })
                 : 
                null
            }
            <br></br>
            <button onClick={() => handleUpdate(selectedItem?._id)} className="add_code">Update Code</button>

            </div>
        )
    }
  
}

export default CodeForm