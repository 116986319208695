import 'react-toastify/dist/ReactToastify.css';

import {APP_TOKEN, SET_RESTAURANT_ID, USER_ID} from "./constants/enums/appEnums";
import {Navigate, Outlet, Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {setAgent, setBusiness, setRestaurantList, setSelectedRestaurant} from "./redux/actions/AppActions";

import AddEmployee from './pages/EmployeePage/Components/AddEmployee';
import AddEvent from './pages/Events/AddEvent';
import ChangeAppPassword from './pages/ChangeAppPassword/ChangeAppPassword';
import ChangePassword from './pages/ChangePassword/ChangePassword';
import ChangeProfile from './pages/ChangeProfile/ChangeProfile';
import Contents from './pages/Contents/Contents';
import DishListPage from './pages/DishListPage/DishListPage';
import Documents from './pages/Documents/Documents';
import EmployeePage from './pages/EmployeePage/EmployeePage';
import Events from './pages/Events/Events';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage'
import Layout from './hoc/Layout';
import LoginPage from './pages/LoginPage/LoginPage'
import MailSend from './pages/ForgotPasswordPage/MailSend';
import Menu from './pages/Menu/Menu';
import MenuCategory from './pages/MenuCategory/MenuCategory';
import NewDishPage from './pages/NewDishPage/NewDishPage';
import PosConfigration from './pages/PosConfigration/PosConfigration';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import RestaurantConfiguration from './pages/RestaurantConfiguration/RestaurantConfiguration';
import RestaurantListPage from './pages/RestaurantListPage/RestaurantListPage';
import RestaurantSettings from './pages/RestaurantSettings/RestaurantSettings';
import StarterPage from './pages/StarterPage/StarterPage';
import {ToastContainer} from "react-toastify";
import ViewEmployee from './pages/EmployeePage/Components/ViewEmployee';
import AddRestaurant from './pages/RestaurantListPage/Components/AddRestaurant';
import callApi from "./util/apiCaller";
import callApiV2 from "./util/apiV2Caller";
import {useDispatch} from 'react-redux';
import RestaurantTableTracker from './pages/RestaurantServices/RestaurantTableTracker';
import DigitoryMenuList from './pages/DigitoryMenuList/DigitoryMenuList';
import Connector from "./Connector";
import KDSPage from "./pages/KDSPage/KDSPage";
import Logs from './pages/Logs/Logs';
import RestaurantInformation from './pages/RestaurantInformation/RestaurantInformation';

// import DishListPage from "./pages/DishListPage/DishListPage";

const PrivateOutlet = ({isAuthenticated}) => {
    const pathname = window?.location?.pathname;
    const search = window?.location?.search;
    return isAuthenticated ? <Outlet/> : <Navigate to={`/login?ref_url=${encodeURIComponent(pathname + search)}`}/>;
}

function App() {
    const dispatch = useDispatch();

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [agentId, setAgentId] = useState(""); // todo move to redux

    useEffect(() => {
        const token = window.localStorage.getItem(APP_TOKEN);
        setIsAuthenticated(!!token);
        let id = localStorage.getItem(USER_ID);
        setAgentId(id);
        fetchAgent(id);
    }, []);

    const fetchAgent = (agentId) => {
        callApi(`auth/agent/${agentId}/profile`, 'get').then(res => {
            console.log("response", res);
            if (res.status === "Success") {
                if (res?.data?.userFound) {
                    setIsAuthenticated(true);
                    dispatch(setAgent(res?.data?.detail));
                    dispatch(setBusiness(res?.data?.business));
                    fetchRestaurantList(res?.data?.business._id, res?.data?.detail);
                }
            }
        });
    }

    const fetchRestaurantList = (business_id, agent) => {
        if (business_id) {
            callApiV2(`restaurant/list`, 'post', {
                filters: {
                    business_id: business_id
                }
            }).then(res => {
                if (res.status === "Success") {
                    dispatch(setRestaurantList(res?.data?.list));
                    let selectedRestaurantId = window.localStorage.getItem(SET_RESTAURANT_ID);
                    let selectedItem = [];
                    if (selectedRestaurantId) {
                        selectedItem = res?.data?.list.filter(item => item._id === selectedRestaurantId);
                    } else {
                        selectedItem = res?.data?.list.filter(item => item._id === agent.preferred_restaurant_id);
                    }
                    if (selectedItem && selectedItem[0]) {
                        dispatch(setSelectedRestaurant(selectedItem[0]));
                    }
                }
            });
        }
    };

    return (
        <>
            <Router>
                <div>
                    <Connector />
                    <Routes>
                        <Route
                            path={'/'}
                            element={<Layout
                                isAuthenticated={isAuthenticated}
                            ><Outlet/></Layout>}
                        >
                            <Route index element={<LoginPage
                                isAuthenticated={isAuthenticated}
                            />}></Route>
                            <Route path='login' element={<LoginPage
                                isAuthenticated={isAuthenticated}
                            />}></Route>
                            <Route path='forgot-password' element={<ForgotPasswordPage/>}></Route>
                            <Route path='mail-send' element={<MailSend/>}></Route>
                            <Route path='reset-password' element={<ResetPassword/>}></Route>
                            <Route path={'admin'} element={<PrivateOutlet isAuthenticated={isAuthenticated}/>}>
                                <Route path='restaurant' element={<RestaurantSettings/>}></Route>
                                <Route path='information' element={<RestaurantInformation/>}></Route>
                                <Route path='digitory-menu-list' element={<DigitoryMenuList/>}></Route>
                                <Route path='restaurant-list' element={<RestaurantListPage/>}></Route>
                                <Route path='settings' element={<RestaurantSettings/>}></Route>
                                <Route path='configuration' element={<RestaurantConfiguration/>}></Route>
                                <Route path='sections' element={<RestaurantTableTracker/>}></Route>
                                <Route path='employees' element={<EmployeePage/>}></Route>
                                <Route path='employees/new-employee' element={<AddEmployee/>}></Route>
                                <Route path='restaurant/new-restaurant' element={<AddRestaurant/>}></Route>
                                <Route path='employees/:employee_id/view' element={<ViewEmployee/>}></Route>
                                <Route path='events' element={<Events/>}></Route>
                                <Route path='documents' element={<Documents/>}></Route>
                                <Route path='contents' element={<Contents/>}></Route>
                                <Route path='logs' element={<Logs/>}></Route>
                                <Route path='events/add-events' element={<AddEvent/>}></Route>
                                <Route path='change-profile' element={<ChangeProfile/>}></Route>
                                <Route path='change-password' element={<ChangePassword/>}></Route>
                                <Route path='change-app-password' element={<ChangeAppPassword/>}></Route>
                                <Route path='pos-configration' element={<PosConfigration/>}></Route>
                                <Route path='menu' element={<Menu/>}></Route>
                                <Route path='menu/:group_menu_id' element={<MenuCategory/>}></Route>
                                <Route path='menu/:group_menu_id/category/:category_menu_id'
                                       element={<StarterPage/>}></Route>
                                <Route path='menu/:group_menu_id/category/:category_menu_id/new-dish'
                                       element={<NewDishPage/>}></Route>
                                <Route path='dishes' element={<DishListPage/>}></Route>
                                <Route path='kds' element={<KDSPage/>}></Route>
                            </Route>
                        </Route>
                    </Routes>
                </div>
            </Router>
            <ToastContainer/>
        </>
    );
}

export default App;
