// import MenuLogo from "../../assets/menu-logo.png";


// import { Button, Modal, ModalBody } from "react-bootstrap";
// import { Link, useLocation, useParams } from "react-router-dom";

import Breadcrumbs from "../../../hoc/Breadcrumbs";
import React from "react";

const CategoryBreadCrumbs = ({query, group_menu_id, searchForCategory, groupMenu}) => {


  const links = [
    {
      name: 'Menus',
      url: '/admin/menu'
    },
    {
      name: `${groupMenu?.name}`,
      url: `/admin/menu/${groupMenu._id}/`
    }
  ]

  return (
    <div className="breadcrumb_bg">
      <Breadcrumbs
        links={links}
      />
      <div className="position-relative col-md-6">
        <i className="fa fa-search fa_search" aria-hidden="true"></i>
        <input
          value={query}
          onChange={(e) => searchForCategory(e.target.value)}
          className="form-control input-search-box me-2"
          placeholder="Search by Dishes, Rasoi name"
          aria-label="Search"
        />
      </div>
    </div>
  )
}

export default CategoryBreadCrumbs;
