import React, { Component } from 'react';

import { Pagination } from 'react-bootstrap';
import _  from "lodash";

class NewPagination extends Component{
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            totalPages: 0,
            maxButtons: 3,
            prev: true,
            next: true,
            first: true,
            last: true,
            size: 'sm',
            buttonArray: [],
            startPage: 1,
            endPage: 1,
        
        };
    }

    componentDidMount() {
        let counter = 1;
        let buttonArray = [];
        let totalPages = this.getPages(this.props.count, this.props.pageSize);
        let maxButtons = totalPages < this.props.maxButtons ? totalPages : this.props.maxButtons;
        while(counter < maxButtons +1) {
            buttonArray.push(counter);
            counter = counter + 1;
        }

        this.setState({
            activePage: this.props.activePage,
            totalPages: totalPages,
            maxButtons: maxButtons,
            prev: this.props.prev,
            next: this.props.next,
            first: this.props.first,
            last: this.props.last,
            size: this.props.size,
            buttonArray: buttonArray,
            startPage: 1,
            endPage: totalPages > this.props.maxButtons ? this.props.maxButtons : totalPages,
        });
    }


    componentDidUpdate(prevProps) {
        if(!_.isEqual(this.props.count, prevProps.count)) {
            this.recalculatePages();
        }
        if(!_.isEqual(this.props.pageSize, prevProps.pageSize)) {
            this.recalculatePages();
        }
    }

    recalculatePages = () => {
        let counter = 1;
        let buttonArray = [];
        let totalPages = this.getPages(this.props.count, this.props.pageSize);
        let maxButtons = totalPages < this.props.maxButtons ? totalPages : this.props.maxButtons;
        while(counter < maxButtons +1) {
            buttonArray.push(counter);
            counter = counter + 1;
        }
        this.setState({
            activePage: this.props.activePage,
            totalPages: totalPages,
            maxButtons: maxButtons,
            prev: this.props.prev,
            next: this.props.next,
            first: this.props.first,
            last: this.props.last,
            size: this.props.size,
            buttonArray: buttonArray,
            startPage: 1,
            endPage: totalPages > this.props.maxButtons ? this.props.maxButtons : totalPages,
        });
    }

    getPages = (count, pagesize) => {
        if (count > 0) {
            return Math.ceil(count / pagesize);
        } else {
            return 0;
        }
    };

    goNext = (page) => {
        let start = this.state.startPage ;
        let end = this.state.endPage;


        if(page > end){
            let buttonArray = [];

            let endPage = page + this.props.maxButtons > this.state.totalPages ? this.state.totalPages : page + this.props.maxButtons

            for(let i = page ; i <= endPage; i++) {
                buttonArray.push(i);
            }
            this.setState({
                startPage: page,
                endPage: endPage,
                buttonArray: buttonArray,
            }, () => {
                this.props.onSelect(page);
            });
        } else {
            this.props.onSelect(page);
        }        
    };

    goPrev = (page) => {
        let start = this.state.startPage ;
        // if (page === 1) {
        //     start = 2;
        // }
        let end = this.state.endPage;

        
        if(page < start){
            let buttonArray = [];

            let startPage = page - this.props.maxButtons < 0 ? 1 : page - this.props.maxButtons + 1

            for(let i = startPage ; i <= page; i++) {
                buttonArray.push(i);
            }
            this.setState({
                startPage: startPage,
                endPage: page,
                buttonArray: buttonArray,
            }, () => {
                this.props.onSelect(page);
            });
        } else {
            this.props.onSelect(page);
        }
        // let buttonArray = [];
        // for(let i = start - 1; i < end ; i++) {
        //     buttonArray.push(i);
        // }
        // this.setState({
        //     startPage: start - 1,
        //     endPage: end - 1,
        //     // buttonArray: buttonArray,
        // }, () => {
        //     this.props.onSelect(page);
        // });
    };

    goStart = () => {
        let start = 1 ;
        // let buttonArray = [];
        // for(let i = start + 1; i < this.state.maxButtons + 1; i++) {
        //     buttonArray.push(i);
        // }
        this.setState({
            startPage: 1,
            endPage: this.state.totalPages > this.state.maxButtons ? this.state.maxButtons : this.state.totalPages,
            // buttonArray: buttonArray,
        }, () => {
            this.props.onSelect(1);
        });
    };

    goEnd = () => {
        let start = this.state.totalPages - this.state.maxButtons + 1 ;
        let end = this.state.totalPages;
        // let buttonArray = [];
        // for(let i = start; i < end + 1 ; i++) {
        //     buttonArray.push(i);
        // }
        this.setState({
            startPage: start,
            endPage: end,
            // buttonArray: buttonArray,
        }, () => {
            this.props.onSelect(this.state.totalPages);
        });
    };
   
     render() {
        return (
            <div>
                <Pagination>
                    {/* <Pagination.First disabled={this.props.activePage < this.state.maxButtons} onClick={() => this.goStart()}/> */}
                    <Pagination.Prev disabled={this.props.activePage < 2} onClick={() => this.goPrev(this.props.activePage - 1)}>
                        prev
                    </Pagination.Prev>
                    
                   {
                        this.state.buttonArray.map(item => (
                            <Pagination.Item onClick={() => this.props.onSelect(item)} active={this.props.activePage === item}>{item}</Pagination.Item>
                        ))
                    }  
                    <Pagination.Next disabled={this.props.activePage > this.state.totalPages - 1} onClick={() => this.goNext(this.props.activePage + 1)}>
                    {/* <Pagination.Last disabled={this.props.activePage > (this.state.totalPages - this.state.maxButtons + 1)}
                                     onClick={() => this.goEnd()}/> */}
                        Next
                  </Pagination.Next>
                </Pagination>
            </div>
        );
    }
}

export default NewPagination;
