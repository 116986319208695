import {Link} from "react-router-dom";
import React from 'react';

const Breadcrumbs = ({links}) => {
    return (
        <div>
            <ul className="breadcrumb">
                {links && links.map((item,index) => (
                    <li key={index}>
                        <Link to={item.url}>{item.name}</Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Breadcrumbs;
