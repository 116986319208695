import React, { useEffect, useState } from 'react'

function EnumContainer({enumLabel, enumValue, getParamValue, removeParam}) {

  const [label, setLabel] = useState(null)
  const [value, setValue] = useState(null)

  useEffect(() => {
    
    setLabel(enumLabel)
    setValue(enumValue)

  },[enumLabel, enumValue])

  const handleChange = (type, data) => {
    if(type == "label"){
        getParamValue(data, value)
        setLabel(data)
    } else if(type == "value"){
        getParamValue(label, data)
        setValue(data)
    }
  }

  return (
    <div className="d-flex">
        <input value={label} type="text" onChange={(e) => handleChange("label",e.target.value)}/>
        <input value={value} type="text" onChange={(e) => handleChange("value",e.target.value)}/>
        <button onClick={() => removeParam()}>remove</button>
    </div>
  )
}

export default EnumContainer