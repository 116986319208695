import React, { Component } from "react";
import TickIcon from '../../assets/white-tick.svg'
export default class MailSend extends React.Component {
    render() {
        return (
            <>
                <section className='login-section'>
                    <div className="content-box">
                        <div className='login-card'>
                            <div className="send-email">
                                <div>
                                    <div className='logo'>
                                        <img src={TickIcon} className='brand-img' alt="" style={{marginBottom: "20px"}}/>
                                    </div>
                                        <h6>Password Reset mail sent</h6>
                                        <p>https://www.login.com/03RKcp1Znp0dKNEx8OLmmI<br />/windmaills/passwordreset</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}