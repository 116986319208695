import callApi, {callUploadApi} from "./apiCaller";
import _ from "lodash";
import FormData from 'form-data';

export const convertImageBaseUrl = (image_url) => {
    if (image_url && image_url.includes("https://d2p7jswxgh09z9.cloudfront.net/")) {
        return image_url.replace("https://d2p7jswxgh09z9.cloudfront.net/", "https://d3eavn3p0apacm.cloudfront.net/")
    }
    return image_url;
}

const deleteFileUploadFileInput = () => {
    // native JS
    let fileInput = document.getElementById("PageUpload");
    document.getElementById('root').removeChild(fileInput)
};
const onFileUpload = (file, updateFunction) => {
    console.log("file", file);
    const formdata = new FormData();
    formdata.append("file", file);
    callUploadApi(`media-upload`, "post", formdata)
        .then(res => {
            console.log("resp", res);
            let updateEvent = {};
            let keyName = document.getElementById("PageUpload").dataset.keyName;
            updateFunction(keyName, res?.data.link)
        });
};

const onFileChange = (event, updateFunction) => {
    // Update the state
    onFileUpload(event.target.files[0], updateFunction);
};
export const handleFormUpload = (val, updateFunction) => {
    let input = document.createElement('input');
    input.type = "file";
    input.id = "PageUpload";
    input.style.display = 'none';
    input.dataset.keyName = val;
    input.onchange = (e) => onFileChange(e, updateFunction);
    document.getElementById('root').appendChild(input);
    setTimeout(function () {
        document.getElementById("PageUpload").click();
    }, 200);
};

