import "./documents.css"
import {Helmet} from "react-helmet";
import React, {useEffect, useState} from "react";
import startCase from 'lodash/startCase';
import DocumentTable from "./Components/DocumentTable";
import MenuLogo from "../../assets/menu-logo.png";
import callApi from "../../util/apiCaller";
import {getSelectedRestaurant} from "../../redux/reducers/AppReducer";
import {useSelector} from 'react-redux';
import {Modal} from "react-bootstrap";
import CodeForm from "./Components/codeForm";
import {getBottomNavigationUtilityClass} from "@mui/material";

const MODEL_NAME = 'content';

const Contents = () => {

    const [event_count, setEventCount] = useState(0);
    const [eventList, setEventList] = useState([]);
    const restaurant = useSelector(state => getSelectedRestaurant(state));
    const [isAddModalVisible, setModalVisiblity] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null)

    useEffect(() => {
        fetchContents();
    }, [])

    const fetchContents = () => {
        callApi(`${MODEL_NAME}/list`, 'post', {}).then(res => {
            if (res.status === "Success") {
                setEventList(res?.data?.list)
                setEventCount(res?.data?.count)
                console.log(res)
            }
        })
    }

    const handlePageSize = (pageSize) => {
        let searchFilters = {...filterData};
        searchFilters.pageNum = 1;
        searchFilters.pageSize = parseInt(pageSize);
        setFilterData(searchFilters);
    };

    const handlePageNum = (pageNum) => {
        let searchFilters = {...filterData};
        searchFilters.pageNum = parseInt(pageNum);
        setFilterData(searchFilters);
    };
    const [filterData, setFilterData] = useState({});
    useEffect(() => {
        setFilterData({
            qtext: '',
            pageNum: 1,
            pageSize: 50,
        })
    }, [restaurant]);


    const closeModal = () => {
        setModalVisiblity(false)
    }

    const handleAddContent = (document, next) => {
        callApi(`content/new`, 'post',
            {content: document}
        ).then(res => {
            return next(res);
        })
    }

    const handleUpdateContent = (documentId, document, next) => {
        callApi(`content/${documentId}/update`, 'post',
            {content: document}
        ).then(res => {
            return next(res);
        })
    };

    const handleDelete = (id, next) => {
        callApi(`content/${id}/remove`, "post", {})
            .then(res => {
                return next(res)
            })
    }

    const addNewCode = () => {
        setSelectedItem(getBottomNavigationUtilityClass)
        setModalVisiblity(true)
    }

    return (
        <>
            <div className="employee-header">
                <div><img src={MenuLogo}/></div>
            </div>
            <Helmet>
                <title>Contents | Windmills</title>
            </Helmet>
            <div className="employee-part">
                <div className="employee-section">
                    <p>{`${startCase(MODEL_NAME)}s`}</p>
                    <div className="d-flex">
                        <a onClick={() => addNewCode()}>
                            <button className="add-employee-btn">{`Add ${startCase(MODEL_NAME)}`}</button>
                        </a>
                    </div>
                </div>
                <div className="employee-list-bg">
                    <DocumentTable
                        eventList={eventList}
                        event_count={event_count}
                        handlePageSizeChange={handlePageSize}
                        handlePageNumChange={handlePageNum}
                        pageSize={filterData.pageSize}
                        pageNum={filterData.pageNum}
                        pages={[10, 20, 50, 100]}
                        fetchContents={fetchContents}
                        handleDelete={handleDelete}
                        setModalVisiblity={setModalVisiblity}
                        setSelectedItem={setSelectedItem}
                    />
                </div>
            </div>
            <div className="delete-modal">
                <Modal show={isAddModalVisible} onHide={closeModal}>
                    <Modal.Header closeButton className="border-none">
                    </Modal.Header>
                    <Modal.Body>
                        <CodeForm
                            handleAddContent={handleAddContent}
                            closeModal={closeModal}
                            setEventList={setEventList}
                            fetchContents={fetchContents}
                            selectedItem={selectedItem}
                            handleUpdateContent={handleUpdateContent}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )

}

export default Contents;
