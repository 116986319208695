import React from 'react';
import PlusIcon from "../../../assets/plus-icon.svg"

const AddTimeSlot = ({ addItem }) => {
    return (
        <div className='add-section'>
            <img src={PlusIcon} onClick={addItem}/>
        </div>
    );
}

export default AddTimeSlot;
