import React, { Component, useEffect, useState } from "react";
import { errorMsg, successMsg } from "../../../util/toast";
import {
  getAgent,
  getSelectedRestaurant,
} from "../../../redux/reducers/AppReducer";

import DefaultModal from "../../../hoc/DefaultModal/DefaultModal";
import callApi from "../../../util/apiCaller";
import tags_plus from "../../../assets/tags_plus.svg";
import { useSelector } from "react-redux";

// import Form from 'react-bootstrap/Form';

// export default class  extends React.Component {
const DishTags = (props) => {
  const restaurant = useSelector((state) => getSelectedRestaurant(state));
  // const [tags, setTags] = useState([]);
  const [isAddNewDishModalVisible, setisAddNewDishModalVisible] =
    useState(false);
  const [value, setvalue] = useState("");
  const [filterData, setFilterData] = useState({});
  const [tagList, setTagList] = useState([]);
  const [tagDelete, setTagDelete] = useState();

  // const TagDelete = () => setTagDelete(true);


  const setTag = (e) => {
    setvalue(e.target.value);
  };

  const addToTags = () => {

    let tags = [...props.dishInfo?.tags, value]
    props.handleChangedish('tags',tags)

    // callApi(`tags/new`, "post", {
    //   tags: {
    //     name: value,
    //     slug: "food",
    //   },
    // }).then((res) => {
    //   // console.log("responseq_at", res?.data?.tags);
    //   if (res.status === "Success") {
    //     // setTagList([...tagList,res?.data?.tags])
    //    if (props.handleChangedish){
        
    //       //  setTagList(tags)
    //    }
    //   } else {
    //     console.log("err", res?.data?.tags);
    //   }
    // });
  };
  useEffect(() => {
   console.log("dish_props**********",props.dishInfo.tags);
    
  }, []);
  
  useEffect(() => {
    setFilterData({
      name: "",
      slug: "",
    });
  }, [restaurant]);

  // const fetchTagList = (restaurant_id) => {
  //   let filters = {};
  //   filters.restaurant_id = restaurant_id;
  //   callApi(`tags/list`, "post").then((res) => {
  //     console.log("responseq_at", res?.data?.list);
  //     if (res.status === "Success") {
  //       setTagList(res?.data?.list);
  //     } else {
  //       console.log("err", res?.data?.message);
  //     }
  //   });
  // };

  const handleDeleteTagsById = (tag) => {
    
    let ind = props.dishInfo?.tags?.indexOf(tag)
    let update = props.dishInfo?.tags.filter((tag, index) => index !== ind)
    props.handleChangedish("tags", update)
       
  };
  console.log("new tag added",props.dishInfo.tags)

  return (
    <>
      <div className="tag_box">
        <DefaultModal
          show={isAddNewDishModalVisible}
          onHide={() => setisAddNewDishModalVisible(false)}
          className="tags_modal"
          style={{ paddingRight: "0px" }}
          centered={false}
          size=""
          title="Tags"
          showFooter={false}
        >
          <div className="position-relative col-md-12">
            <i className="fa fa-search fa_search" aria-hidden="true"></i>
            <input
              type="search"
              className="form-control input-search me-2"
              onChange={setTag}
            />
          </div>
          <div className="d-flex justify-content-between">
            <button className="cancel_but">Select</button>
            <button className="save_but" onClick={addToTags}>
              Create
            </button>
          </div>
        </DefaultModal>
        <div className="tag_header">
          <h4>Filter Tags</h4>
          <div onClick={() => setisAddNewDishModalVisible(true)}>
            <img src="https://d3eavn3p0apacm.cloudfront.net/windmill-images/tags.svg" />
          </div>
        </div>
        <div className="tag_box_bottom">
          {props.dishInfo?.tags?.length > 0 &&
              props.dishInfo?.tags?.map((tag) => (
              <div className="">
                <button className="food_button position-relative">
                  {tag}
                  <span
                    onClick={() => handleDeleteTagsById(tag)}
                    className="close_tag"
                  >
                    {" "}
                    <img src="https://d3eavn3p0apacm.cloudfront.net/windmill-images/close_ic.png" />
                  </span>
                </button>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
export default DishTags;
