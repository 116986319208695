import React, { useEffect, useState } from "react";

import RestaurantTab from "./Components/RestaurantTab";

const RestaurantConfiguration = () => {
   

    return (
        <>
      
         <div className="right_side_section container-fluid">
          
           
           <RestaurantTab />
 
            
         </div>
        
        </>
    )

}

export default RestaurantConfiguration;
