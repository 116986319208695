import React, {Component} from 'react';
import Pubnub from 'pubnub';
import {connect} from 'react-redux';
import callApi from "./util/apiCaller";
import {USER_ID} from "./constants/enums/appEnums";
import {fetchKitchenOrders} from "./redux/actions/OrderActions";
import {getSelectedRestaurant} from "./redux/reducers/AppReducer";

const WINDMILLS_COMMON_CHANNEL = "windmill-app";
const WINDMILLS_AGENT_CHANNEL = "windmill-agent";

const MENU_UPDATE = 'MENU_UPDATE';
const ORDER_UPDATE = 'ORDER_UPDATE';
const CLEAR_CACHE = 'CLEAR_CACHE';
const NEW_ORDER = 'NEW_ORDER';
const WEB_REQUEST_STAFF = 'WEB_REQUEST_STAFF';
const STATUS_CHANGE = 'STATUS_CHANGE';
const ORDER_COMPLETE = 'ORDER_COMPLETE';

class Connector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            credentials: {}
        }
    }

    componentDidMount() {
        console.log('Connector Here');
        callApi(`customer/config`, 'GET').then(res => {
            if (res && res.status === 'Success') {
                let id = localStorage.getItem(USER_ID);
                const credentials = {
                    publishKey: res.data.pubnub_publisher_key,
                    subscribeKey: res.data.pubnub_subscriber_key,
                    uuid: `agent-${this.props.id}`
                }
                this.setState({credentials}, () => {
                    const pubnub = new Pubnub(this.state.credentials);
                    pubnub.subscribe({
                        channels: [WINDMILLS_COMMON_CHANNEL, WINDMILLS_AGENT_CHANNEL]
                    });
                    pubnub.addListener({
                        message: (msg) => {
                            this.getMessages(msg.message)
                        },
                    })
                })
            }
        }).catch(err => {
            if (err) {
                console.log(err);
            }
        })
    }

    getMessages = msg => {
        switch (msg.event) {
            case NEW_ORDER:
                console.log(msg);
                this.props.dispatch(fetchKitchenOrders(this.props.restaurant._id));
                break;

            case ORDER_UPDATE:
                console.log(msg);
                this.props.dispatch(fetchKitchenOrders(this.props.restaurant._id));
                break;

            case STATUS_CHANGE:
                console.log(msg);
                this.props.dispatch(fetchKitchenOrders(this.props.restaurant._id));
                break;

            case ORDER_COMPLETE:
                console.log(msg);
                this.props.dispatch(fetchKitchenOrders(this.props.restaurant._id));
                break;
            default: {
                return null
            }
        }
    }

    render() {
        return (
            <div style={{color: "green"}}></div>
        );
    }
}

function mapStateToProps(state) {
    return {
        restaurant: getSelectedRestaurant(state)
    }
}

export default connect(mapStateToProps)(Connector);