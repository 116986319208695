import "./dishPage.css";

import React, { useEffect, useState } from "react";
import AddDish from "./components/AddDish";
import DefaultModal from "../../hoc/DefaultModal/DefaultModal";
import DishSearchHeader from "./components/DishSearchHeader";
import DishTable from "./components/DishTable";
import { Helmet } from "react-helmet";
import { FilePicker } from 'react-file-picker'
import Papa from 'papaparse'
import { Button, Modal, ModalBody } from "react-bootstrap";
import callApi from "../../util/apiCaller";
import { getSelectedRestaurant } from "../../redux/reducers/AppReducer";
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import _ from 'lodash'

const links = [
    {
        name: 'Home',
        url: '/'
    },
    {
        name: 'Dishes',
        url: '/admin/dishes'
    }
]

const DishListPage = () => {

    const restaurant = useSelector(state => getSelectedRestaurant(state));
    let location = useLocation();
    // add new dish code
    const [isAddNewDishModalVisible, setNewDishModalVisibility] = useState(false);
    const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);

    // editDish code
    const [editDishId, setEditDishId] = useState("");

    // table code
    const [dish_list, setDish] = useState([]);
    const [total_dish_count, setDishCount] = useState(0);

    //pagination code
    const [filterData, setFilterData] = useState({
        qtext: '',
        pageNum: 1,
        pageSize: 50,
    });

    const handlePageSize = (pageSize) => {
        let searchFilters = { ...filterData };
        searchFilters.pageSize = parseInt(pageSize);
        searchFilters.pageNum = 1;
        setFilterData(searchFilters);
        fetchDishList(restaurant._id, searchFilters);
    };

    const handlePageNum = (pageNum) => {
        let searchFilters = { ...filterData };
        searchFilters.pageNum = parseInt(pageNum);
        setFilterData(searchFilters);
        fetchDishList(restaurant._id, searchFilters);
    };

    useEffect(() => {
        setFilterData({
            qtext: '',
            pageNum: 1,
            pageSize: 50,
        })
        fetchDishList(restaurant._id, null);
    }, [restaurant]);


    useEffect(() => {
        console.log("location", location);
        const { search } = { ...location };
        const searchParams = new URLSearchParams(search);
        if (searchParams.has('showEdit')) {
            const dishID = searchParams.get('showEdit');
            console.log('yolo ', searchParams.get('showEdit'))
            openEditDishModal(dishID)
        }
    }, [])


    const fetchDishList = (restaurant_id, inputFilters) => {
        let filters = {};
        filters.restaurant_id = restaurant_id;
        if (inputFilters && inputFilters.qtext) {
            filters.qtext = inputFilters.qtext;
        }
        callApi(`dish/list`, 'post', {
            filters,
            pageNum: inputFilters?.pageNum,
            pageSize: inputFilters?.pageSize,
        }).then(res => {
            if (res?.status === "Success") {
                setDish(res?.data?.list);
                setDishCount(res?.data?.count);
            } else {
                console.log('err', res?.data?.message);
            }
        });
    };

    const searchForDish = (searchText) => {
        let searchFilters = { ...filterData };
        searchFilters.qtext = searchText;
        setFilterData(searchFilters);
        fetchDishList(restaurant?._id, searchFilters);
    };

    const closeAddNewDishModal = () => {
        setEditDishId("");
        setNewDishModalVisibility(false)
    }

    const updateDishList = (dishId, dish) => {
        let new_dish_list = dish_list.map(item => {
            if (item._id === dishId) {
                return dish;
            } else {
                return item;
            }
        })
        setDish(new_dish_list);
    }

    const addDishList = (dish) => {
        let new_dish_list = [...dish_list];
        new_dish_list.unshift(dish);
        setDish(new_dish_list);
    }

    const openEditDishModal = (dish_id) => {
        setEditDishId(dish_id);
        setNewDishModalVisibility(true);
    };

    const openBulkUploadModal = () => setBulkUploadModalOpen(true);
    const closeBulkUploadModal = () => setBulkUploadModalOpen(false);

    const uploadMenuData = async (data) => {
        try {
            console.log(data);
            const chunkedData = _.chunk(data, 30);
            for (let j = 0; j < chunkedData.length; j++) {
                const e = chunkedData[j];
                await Promise.allSettled(e.map(r => 
                    callApi(`dish/new`, 'post', {
                        dish: r,
                    })
                ));
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleBulkUpload = (file) => {
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                uploadMenuData(results.data);
            },
        });
    }

    return (
        <div className="right_side_section">
            <Helmet>
                <title>Windmills | Dishes</title>
            </Helmet>
            <DishSearchHeader
                openBulkUploadModal={openBulkUploadModal}
                query={filterData.qtext}
                searchForDish={searchForDish}
                setNewDishModalVisibility={setNewDishModalVisibility}
            />
            {/* <Breadcrumbs
                links={links}
            /> */}
            <div>
                    <div  style={{padding:"20px"}}><h4>Dishes</h4></div>
            </div>
            <DishTable
                dish_list={dish_list}
                setDish={setDish}
                total_dish_count={total_dish_count}
                openEditDishModal={openEditDishModal}
                handlePageSizeChange={handlePageSize}
                handlePageNumChange={handlePageNum}
                pageSize={filterData.pageSize}
                pageNum={filterData.pageNum}
                pages={[10, 20, 50, 100]}
            />
            <DefaultModal
                show={isAddNewDishModalVisible}
                onHide={() => closeAddNewDishModal(false)}
                className="add_modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                size=""
                showFooter={false}
            >
                <div className="add-header">
                    {editDishId ?
                        <h5>Edit Dish</h5>
                        :
                        <h5>Add New Dish</h5>
                    }
                </div>
                    <AddDish
                        restaurant={restaurant}
                        closeDishInformationModal={closeAddNewDishModal}
                        dishId={editDishId}
                        updateDishList={updateDishList}
                        addDishList={addDishList}
                        setEditDishId={setEditDishId}
                    />
            </DefaultModal>
                
            <div className="upload-modal">
                {/* Bulk-uplad-modal */}
                <Modal show={bulkUploadModalOpen} onHide={closeBulkUploadModal}>
                    <Modal.Header closeButton className="border-none">

                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center delete-modal">
                            <h6 className="upload-modal-heading">Bulk Upload</h6>
                        </div>
                        <h5>Upload a .CSV file to bulk upload</h5>
                        <ul className="bulk_modal_modal">
                            <li> Max 50 MB file allowed. (upto 500000 entries)</li>
                            <li> CSV should mandatorily contain dishes</li>
                            <li> If 2 (or more) items in CSV have the same name, they might not be added.</li>
                            <li>If a dish in the CSV already exists in your account, the existing dish's traits will be updated as per the traits given in the CSV. (matching of items is done based on dish)</li>

                        </ul>
                        <div className="upload-section">
                            <h3>Drag and drop your files here</h3>
                            <p>- OR -</p>
                            <div>
                                <FilePicker
                                    extensions={['csv']}
                                    onChange={handleBulkUpload}
                                    onError={errMsg => toast(errMsg, {type:'error', hideProgressBar: true})}
                                >
                                    <button>Upload File</button>
                                </FilePicker>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        </div>
    )

}

export default DishListPage;
