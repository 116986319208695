const options = [
  { value: 0, label: 'Select Tile position' },
  { value: '1', label: 'Top Left' },
  { value: '2', label: 'Top Middle' },
  { value: '3', label: 'Top Right' },
  { value: '4', label: 'Center Left' },
  { value: '5', label: 'Center Middle' },
  { value: '6', label: 'Center Right' },
  { value: '7', label: 'Bottom Left' },
  { value: '8', label: 'Bottom Middle' },
  { value: '9', label: 'Bottom Right' }
]

export default options;