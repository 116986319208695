import React, {useEffect, useState, useRef} from 'react';
import {convertSecondsToHHMM, daysInMonth} from "../../../util/timeUtility";
import DatePicker from "../../../common/TimeScheduler/components/DatePicker";
import DaysPicker from "../../../common/TimeScheduler/components/DaysPicker";
import TimePicker from "../../../common/TimeScheduler/components/TimePicker";
import shortid from 'shortid';
import {saveData, handleDailySlotChange, handleWeeklySlotChange, handleMonthlySlotChange} from "../../../common/operationTimeUtils/commonMethods.js"


const OperationTime = ({
                           availability_rule,
                           availability_daily = null,
                           availability_weekly = null,
                           availability_monthly = null,
                           ...props
                       }) => {

    // daily working

    let prevAvailabilityRule = useRef("")

    useEffect(() => {
        if (availability_daily && availability_daily.length > 0) {
            let timeSlots = availability_daily?.map(item => {
                return {
                    start_time: convertSecondsToHHMM(item.start_time),
                    end_time: convertSecondsToHHMM(item.end_time),
                    _id: item._id
                }
            });
            setDailySlots(timeSlots);
        } else {
            setDailySlots([{
                start_time: "00:00",
                end_time: "00:00",
                _id: shortid.generate()
            }])
        }
    }, [availability_daily])

    const [dailySlots, setDailySlots] = useState(availability_daily)

    const addDailyTimeSlot = () => {
        let newTimeSlots = [...dailySlots];
        newTimeSlots.push({
            start_time: "00:00",
            end_time: "00:00",
            _id: shortid.generate()
        })
        setDailySlots(newTimeSlots)
    }

    const removeDailyTimeSlot = (deleteItem) => {
        let newTimeSlots = [...dailySlots];
        newTimeSlots = newTimeSlots.filter(item => item._id.toString() !== deleteItem._id.toString());
        setDailySlots(newTimeSlots)
    }

    // // weekly logic
    const [weeklySlots, setWeeklySlots] = useState([])

    useEffect(() => {
        let days = [
            {
                day: 0,
                label: 'Monday',
            },
            {
                day: 1,
                label: 'Tuesday',
            },
            {
                day: 2,
                label: 'Wednesday',
            },
            {
                day: 3,
                label: 'Thursday',
            },
            {
                day: 4,
                label: 'Friday',
            },
            {
                day: 5,
                label: 'Saturday',
            },
            {
                day: 6,
                label: 'Sunday',
            }
        ];
        let timeSlots = days.map(item => {
            item.is_checked = false;
            availability_weekly?.forEach(availableItem => {
                if (availableItem.day.toString() === item.day.toString()) {
                    item.time_availability = availableItem.time_availability.map(timeSlot => {
                        return {
                            ...timeSlot,
                            start_time: convertSecondsToHHMM(timeSlot.start_time),
                            end_time: convertSecondsToHHMM(timeSlot.end_time),
                        }
                    })
                    item.is_checked = true;
                }
            });
            return item;
        });
        setWeeklySlots(timeSlots);
    }, [availability_weekly])


    const addWeeklyTimeSlot = (weeklyItem) => {
        let newWeeklySlots = [...weeklySlots];
        newWeeklySlots = newWeeklySlots.map(item => {
            if (item.day === weeklyItem.day) {
                if (item.time_availability) {
                    item.time_availability.push({
                        start_time: "00:00",
                        end_time: "00:00",
                        _id: shortid.generate()
                    })
                } else {
                    item.time_availability = [];
                    item.time_availability.push({
                        start_time: "00:00",
                        end_time: "00:00",
                        _id: shortid.generate()
                    })
                }
            }
            return item;
        })
        setWeeklySlots(newWeeklySlots)
    }

    const removeWeeklyTimeSlot = (weeklyItem, deleteItem) => {
        let newWeeklySlots = [...weeklySlots];
        newWeeklySlots = newWeeklySlots.map(item => {
            if (item.day === weeklyItem.day) {
                item.time_availability = item.time_availability.filter(timeSlotItem => timeSlotItem._id.toString() !== deleteItem._id.toString());
            }
            return item;
        })
        setWeeklySlots(newWeeklySlots)
    }

    // monthly logic
    const [monthlySlots, setMonthlySlots] = useState([])

    useEffect(() => {
        let timeSlots = [...daysInMonth].map(item => {
            item.is_checked = false;
            availability_monthly?.forEach(availableItem => {
                if (availableItem.day.toString() === item.day.toString()) {
                    item.time_availability = availableItem.time_availability.map(timeSlot => {
                        return {
                            ...timeSlot,
                            start_time: convertSecondsToHHMM(timeSlot.start_time),
                            end_time: convertSecondsToHHMM(timeSlot.end_time),
                        }
                    })
                    item.is_checked = true;
                }
            });
            return item;
        });
        setMonthlySlots(timeSlots);
    }, [availability_monthly])


    const addMonthlyTimeSlot = (MonthlyItem) => {
        let newMonthlySlots = [...monthlySlots];
        newMonthlySlots = newMonthlySlots.map(item => {
            if (item.day === MonthlyItem.day) {
                if (!item.time_availability) {
                    item.time_availability = [{
                        start_time: "00:00",
                        end_time: "00:00",
                        _id: shortid.generate()
                    }]
                } else {
                    item.time_availability.push({
                        start_time: "00:00",
                        end_time: "00:00",
                        _id: shortid.generate()
                    })
                }
            }
            return item;
        })
        setMonthlySlots(newMonthlySlots)
    }

    const removeMonthlyTimeSlot = (MonthlyItem, deleteItem) => {
        let newMonthlySlots = [...monthlySlots];
        newMonthlySlots = newMonthlySlots.map(item => {
            if (item.day === MonthlyItem.day) {
                item.time_availability = item.time_availability.filter(timeSlotItem => timeSlotItem._id.toString() !== deleteItem._id.toString());
            }
            return item;
        })
        setMonthlySlots(newMonthlySlots)
    }

    const changeDayInWeek = (updateItem) => {
        let newWeeklySlots = [...weeklySlots].map(item => {
            if (item.day === updateItem.day) {
                item.is_checked = !item.is_checked;
            }
            return item;
        });
        setWeeklySlots(newWeeklySlots)
    };

    const changeDayInMonth = (updateItem) => {
        let newMonthlySlots = [...monthlySlots].map(item => {
            if (item.day === updateItem.day) {
                item.is_checked = !item.is_checked;
            }
            return item;
        });
        setMonthlySlots(newMonthlySlots)
    };

    const handleAvailabilityChange = (rule) => {
        if(prevAvailabilityRule.current !== rule){
            props.handleChangeGroupMenu("availability_rule", rule)
            prevAvailabilityRule.current = rule
        } else {
            props.handleChangeGroupMenu("availability_rule", "")
            prevAvailabilityRule.current = ""
        }
    }

    return (
        <>
            <div className='operation-section'>
                <h1>Operation Time</h1>
                <div className="accordion accordion-flush" id="accordion-me">

                    <div className="accordion-item">
                        <div className="accordion-header d-flex justify-content-between header-button-css">
                            <div>
                                DAILY
                            </div>
                            <div className="accordion_switch" type="button" data-bs-toggle="collapse"
                                 data-bs-target="#item-1" aria-expanded="false" aria-controls="item-1">
                                <label className="switch">
                                    <input type="checkbox"
                                           checked={availability_rule === "daily"}
                                           onChange={() => handleAvailabilityChange("daily")}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>

                        </div>
                        <div id="item-1" className="accordion-collapse collapse" aria-labelledby="item-1"
                             data-bs-parent="#accordion-me">
                            <div className="accordion-body daily-accordian">
                                <div className='daily-checkbox-bg'>
                                    <p>Select Time</p>
                                </div>
                                <TimePicker
                                    timeSlots={dailySlots}
                                    addTimeSlot={addDailyTimeSlot}
                                    deleteTimeSlot={removeDailyTimeSlot}
                                    getTimeValue={(updateItem, start, end, day) => handleDailySlotChange(updateItem, start, end, day, dailySlots, setDailySlots)}
                                />
                            </div>
                        </div>
                    </div>
                    <hr className='border_bottom'/>

                    <div className="accordion-item">
                        <div className="accordion-header d-flex justify-content-between header-button-css">
                            <div>
                                WEEKLY
                            </div>
                            <div className="accordion_switch" type="button" data-bs-toggle="collapse"
                                 data-bs-target="#item-2" aria-expanded="false" aria-controls="item-2">

                                <label className="switch">

                                    <input type="checkbox"
                                           checked={availability_rule === "weekly"}
                                           onChange={() => handleAvailabilityChange("weekly")}/>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div id="item-2" className="accordion-collapse collapse" aria-labelledby="item-2"
                             data-bs-parent="#accordion-me">
                            <div className="accordion-body daily-accordian">
                                <DaysPicker
                                    weeklySlots={weeklySlots}
                                    changeDayInWeek={changeDayInWeek}
                                    addWeeklyTimeSlot={addWeeklyTimeSlot}
                                    handleWeeklySlotChange={(updateItem, start, end, day) => handleWeeklySlotChange(updateItem, start, end, day, weeklySlots, setWeeklySlots)}
                                    removeWeeklyTimeSlot={removeWeeklyTimeSlot}
                                />
                            </div>
                        </div>
                    </div>
                    <hr className='border_bottom'/>

                    <div className="accordion-item">
                        <div className="accordion-header d-flex justify-content-between header-button-css">
                            <div>
                                MONTHLY
                            </div>
                            <div className="accordion_switch" type="button" data-bs-toggle="collapse"
                                 data-bs-target="#item-3" aria-expanded="false" aria-controls="item-3">

                                <label className="switch">

                                    <input type="checkbox"
                                           checked={availability_rule === "monthly"}
                                           onChange={() => handleAvailabilityChange("monthly")}/>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div id="item-3" className="accordion-collapse collapse" aria-labelledby="item-3"
                             data-bs-parent="#accordion-me">
                            <div className="accordion-body d-flex">
                                <DatePicker
                                    monthlySlots={monthlySlots}
                                    addMonthlyTimeSlot={addMonthlyTimeSlot}
                                    removeMonthlyTimeSlot={removeMonthlyTimeSlot}
                                    handleMonthlySlotChange={(updateItem, start, end, day) => handleMonthlySlotChange(updateItem, start, end, day, monthlySlots, setMonthlySlots)}
                                    changeDayInMonth={changeDayInMonth}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-between'>
                <button onClick={() => props.setSegment("Screen details")} className='information-btn-cancel'>Back</button>
                <button className='information-btn-save' onClick={() => saveData(props.menuInfo, dailySlots, weeklySlots, monthlySlots, props.handleSaveMenu)}>Save</button>
            </div>
        </>
    )

}

export default OperationTime;