import React, { useEffect, useState } from "react";
import { errorMsg, successMsg } from "../../../util/toast";

import Carousel from "react-bootstrap/Carousel";
import DefaultModal from "../../../hoc/DefaultModal/DefaultModal";
import _ from "lodash";
import Select from "react-select";
import callApi, { callUploadApi } from "../../../util/apiCaller";
import { useSelector } from "react-redux";
import { getSelectedRestaurant } from "../../../redux/reducers/AppReducer";
import { convertImageBaseUrl } from "../../../util/imageUpload";
import FormData from "form-data";

function Banner({ eventInfo }) {
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState();
  const [imageContainer, setImageContainer] = useState([]);

  const restaurant = useSelector((state) => getSelectedRestaurant(state));

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const option = [
    {
      label: "Ios",
      value: "ios",
    },
    {
      label: "Android",
      value: "android",
    },
  ];
  const handleChange = (e) => {
    const inputValue = e.value;
    setSearch(inputValue ?? "");
  };

  const fetchImageList = (id, search) => {
    callApi("banner/list", "post", {
      filters: {
        restaurant_id: id,
        platform: search || option?.[0]?.value,
      },
    })
      .then((res) => {
        setImageContainer(res?.data?.list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (restaurant?._id || search) {
      fetchImageList(restaurant?._id, search);
    }
  }, [restaurant?._id, search]);

  const deleteFileUploadFileInput = () => {
    let fileInput = document.getElementById("bannerPageUpload");
    document.getElementById("root").removeChild(fileInput);
  };
  const handleFormUpload = (val) => {
    let input = document.createElement("input");
    input.type = "file";
    input.id = "bannerPageUpload";
    input.style.display = "none";
    input.dataset.keyName = val;
    input.onchange = onFileChange;
    document.getElementById("root").appendChild(input);
    setTimeout(function () {
      document.getElementById("bannerPageUpload").click();
    }, 200);
  };
  const onFileUpload = (file) => {
    console.log("file", file);
    const formdata = new FormData();
    formdata.append("file", file);
    callUploadApi(`media-upload`, "post", formdata).then((res) => {
      console.log("resp", res);
      addImage({
        restaurant_id: restaurant?._id,
        name: file.name,
        image_url: res?.data?.link,
        is_active: true,
      });
      deleteFileUploadFileInput();
    });
  };

  const onFileChange = (event) => {
    // Update the state
    console.log("event", event);
    onFileUpload(event.target.files[0]);
  };
  const addImage = (banner) => {
    callApi(`banner/new`, "post", {
      banner: { ...banner, platform: search },
    })
      .then((res) => {
        if (res.status == "Success") {
          fetchImageList(restaurant?._id);
          successMsg("Image uploaded Successfully...!");
        } else {
          errorMsg("something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeImage = (id) => {
    callApi(`banner/${id}/remove`, "post", {})
      .then((res) => {
        if (res.status == "Success") {
          fetchImageList(restaurant?._id);
          successMsg("Remove Successfully...!");
        } else {
          errorMsg("something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <button className="event_but" onClick={() => setIsOpen(true)}>
        Banner
      </button>
      <DefaultModal
        show={isOpen}
        onHide={() => setIsOpen(false)}
        centered={true}
        size=""
        showFooter={false}
        className="info-modal"
        title={`Banner`}
      >
        <p>Image</p>
        <div
          style={{
            margin: "10px 0",
          }}
        >
          <Select
            options={option}
            isSearchable={false}
            defaultValue={option[0]}
            onChange={(event) => handleChange(event)}
            id="12"
          />
        </div>
        <Carousel activeIndex={index} onSelect={handleSelect}>
          {imageContainer &&
            imageContainer.map((item, index) => (
              <Carousel.Item>
                <img
                  className="d-block w-100 banner-im"
                  src={convertImageBaseUrl(item.image_url)}
                />
              </Carousel.Item>
            ))}
        </Carousel>
        <div className="d-flex mt-4 ">
          {imageContainer &&
            imageContainer.map((img) => {
              console.log(img);
              return (
                <div className="position-relative image_size">
                  <img
                    src={convertImageBaseUrl(img.image_url)}
                    width={"114px"}
                    height={"114px"}
                  />
                  <div onClick={() => removeImage(img._id)}>
                    <img
                      src="https://d3eavn3p0apacm.cloudfront.net/windmill-images/close_ic.png"
                      className="img_position"
                    />
                  </div>
                </div>
              );
            })}
          <div
            style={{ cursor: "pointer" }}
            className="image_box"
            onClick={() => handleFormUpload("image_url")}
          >
            Add image
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <button className="cancel_but" onClick={() => setIsOpen(false)}>
            Cancel
          </button>
          {/*<button className="save_but" onClick={handleSave}>Save</button>*/}
        </div>
      </DefaultModal>
    </div>
  );
}

export default Banner;
