import "./NewDishPage.css"

import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import DishHeader from "./Components/DishHeader";
import DishMenu from "./Components/DishMenu";
import DishPageBreadCrumbs from "./Components/DishPageBreadCrumbs";
import callApi from "../../util/apiCaller";
import {getSelectedRestaurant} from "../../redux/reducers/AppReducer";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const NewDishPage = () => {

    const restaurant = useSelector(state => getSelectedRestaurant(state));

    const {group_menu_id, category_menu_id} = useParams();
    const [menuCategory, setMenuCategory] = useState({});
    const [groupMenu, setGroupMenu] = useState({});

    const [completeDishList, setCompleteList] = useState([]);
    const [selectedDishList, setSelectedDishList] = useState([]);
    const [selectedDishObjectMap, setSelectedDishObjectMap] = useState({});

    const [filterData, setFilterData] = useState({
        qtext: '',
        pageNum: 1,
        pageSize: 5000,
    });

    useEffect(() => {
        getGroupMenu(group_menu_id)
    }, [group_menu_id]);

    const getGroupMenu = (id) => {
        callApi(`group_menu/${id}`, 'get').then(res => {
            if (res?.status === "Success") {
                setGroupMenu(res.data.group_menu);
            }
        });
    };

    useEffect(() => {
        getCategoryMenu(category_menu_id)
    }, [category_menu_id]);

    const getCategoryMenu = (id) => {
        callApi(`menu_category/${id}`, 'get').then(res => {
            if (res?.status === "Success") {
                setMenuCategory(res.data.menu_category);
            }
        });
    };

    useEffect(() => {
        fetchDishList(restaurant?._id, {
            qtext: '',
            pageNum: 1,
            pageSize: 5000,
        })
        // fetchSelectedDishList(category_menu_id);
    }, [group_menu_id, category_menu_id, restaurant]);

    const fetchDishList = (restaurant_id, inputFilters) => {
        let filters = {};
        filters.restaurant_id = restaurant_id;
        if (inputFilters && inputFilters.qtext) {
            filters.qtext = inputFilters.qtext;
        }
        callApi(`dish/list`, 'post', {
            filters,
            pageNum: inputFilters?.pageNum,
            pageSize: inputFilters?.pageSize,
        }).then(res => {
            if (res?.status === "Success") {
                setCompleteList(res?.data?.list);
                fetchSelectedDishList(category_menu_id, res?.data?.list);
                // setDishCount(res?.data?.count);
            } else {
                console.log('err', res?.data?.message);
            }
        });
    };

    const fetchSelectedDishList = (category_menu_id, completeDishList) => {
        let filters = {};
        filters.menu_category_id = category_menu_id;
        callApi(`dish/selected-list`, 'post', {
            filters,
            pageNum: 1,
            pageSize: 5000,
        }).then(res => {
            if (res?.status === "Success") {
                let selected_list = [];
                let object = {};
                let selectedObject = {};
                completeDishList?.forEach(item => {
                    object[item._id] = item;
                });
                console.log("object", object, res.data?.list);
                res.data?.list.forEach(item => {
                    if (object[item?.dish_id?._id]) {
                        selected_list.push(object[item?.dish_id?._id]);
                        selectedObject[item?.dish_id?._id] = 1;
                    }
                });
                console.log(selected_list, "selected_list");
                let newObjectMap = {};
                for (let key in object) {
                    newObjectMap[key] = !!selectedObject[key] ? 1 : 0;
                }
                setSelectedDishList(selected_list);
                setSelectedDishObjectMap(newObjectMap);
            } else {
                console.log('err', res?.data?.message);
            }
        });
    };

    const handleSelection = (item) => {
        let newList = [...selectedDishList];
        if (!selectedDishObjectMap[item._id]) {
            addDishToMenu(category_menu_id, item);
            newList.unshift(item); // array method
            let newObject = {...selectedDishObjectMap};
            newObject[item._id] = 1;
            setSelectedDishObjectMap(newObject);
            setSelectedDishList(newList);
        }
    };

    const handleUnSelection = (selectedItem) => {
        removeDishFromMenu(category_menu_id, selectedItem)
        let newList = [...selectedDishList];
        let newObject = {...selectedDishObjectMap};
        newObject[selectedItem._id] = 0;
        setSelectedDishObjectMap(newObject);
        newList = newList.filter(item => item._id !== selectedItem._id);
        setSelectedDishList(newList);
    };

    const handleSave = () => {
        // todo blocked on API
    };

    const addDishToMenu = (menu_category_id, dish) => {
        callApi(`selected_dish/menu_category/${menu_category_id}/dish/${dish?._id}/add-dish`, 'post', {
            selected_dish: {
                dish_name: dish.name
            }
        }).then(res => {
            console.log(res);
        })
    }

    const removeDishFromMenu = (menu_category_id, dish) => {
        callApi(`selected_dish/menu_category/${menu_category_id}/dish/${dish?._id}/remove-dish`, 'post', {
            selected_dish: {
                dish_name: dish.name
            }
        }).then(res => {
            console.log(res);
        })
    }

    return (
        <div className="right_side_section">
            <Helmet>
                <title>Select Dish | Windmills</title>
            </Helmet>
            <DishHeader/>
            <DishPageBreadCrumbs
                menuCategory={menuCategory}
                groupMenu={groupMenu}
            />
            <DishMenu
                selectedDishObjectMap={selectedDishObjectMap}
                completeDishList={completeDishList}
                selectedDishList={selectedDishList}
                handleSelection={handleSelection}
                handleUnSelection={handleUnSelection}
            />
        </div>

    )
}

export default NewDishPage;


