import {Button, Modal, ModalBody} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {errorMsg, successMsg} from "../../../util/toast";
import {getRestaurantList, getSelectedRestaurantName} from "../../../redux/reducers/AppReducer";

import EditIcon from "../../../assets/edit-icon.svg";
import EmployeeList from '../../../content/EmployeeList.json';
import {Link} from "react-router-dom";
import Pencil from "../../../assets/pencil.svg";
import TableFooter from "../../../hoc/TableFooter";
import callApi from "../../../util/apiCaller";
import {useSelector} from 'react-redux';

const EmployeeTable = ({
                           pageSize,
                           pageNum,
                           employee_count,
                           pages,
                           handlePageSizeChange,
                           handlePageNumChange,
                           employeeList
                       }) => {


    //  const [employeeList, setEmployeeList] = useState([]);

    const restuarant_list = useSelector(state => getRestaurantList(state));



    return (
        <>
            <div className="">
                <TableFooter
                    isPageVisible={false}
                    pages={pages}
                    pageSize={pageSize}
                    count={employee_count}
                    pageNum={pageNum}
                    handlePageNumChange={handlePageNumChange}
                    handlePageSizeChange={handlePageSizeChange}
                >
                </TableFooter>
                <div style={{marginRight: '14px'}}>
                    <table class="table table-borderless employee-table">
                        <thead>
                        <tr className="table-header">
                            <th scope="col">Name</th>
                            <th scope="col">email</th>
                            <th scope="col">Restaurant</th>
                            <th scope="col">Role</th>
                            <th scope="col">Mobile Number</th>
                            <th scope="col">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {employeeList.map((item, index) => (
                            <tr className="table-border-bottom table-body">
                                <th scope="row">{item.contact_name}</th>
                                <td>{item.contact_email}</td>
                                <td>{getSelectedRestaurantName(restuarant_list, item.preferred_restaurant_id)}</td>
                                <td>{item.role}</td>
                                <td>{item.phone_number}</td>
                                <td>
                                    <Link to={`/admin/employees/${item._id}/view`}>
                                        <img src={EditIcon} style={{marginRight: "7px"}}/>Edit
                                    </Link>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <TableFooter
                    isCounterVisible={false}
                    pages={pages}
                    pageSize={pageSize}
                    count={employee_count}
                    pageNum={pageNum}
                    handlePageNumChange={handlePageNumChange}
                    handlePageSizeChange={handlePageSizeChange}
                >
                </TableFooter>


            </div>

        </>
    )
}

export default EmployeeTable;
