import React from "react";
import GlobalSidebar from "./GlobalSidebar";

const Layout = ({
                    children,
                    isAuthenticated
                }) => {

    return (
        <>
            {isAuthenticated ?
                <GlobalSidebar/> :
                null
            }
            {children}
        </>
    )
}

export default Layout;
